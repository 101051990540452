import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type AbandonedUsersState = {
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
  statusFilter?: api.UserAccountOnboardingStatus;
};

export const initialState: AbandonedUsersState = {
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.DESCENDING,
  statusFilter: undefined,
};

const abandonedUsers = createSlice({
  name: 'abandonedUsers',
  initialState,
  reducers: {
    updatePage(state: AbandonedUsersState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: AbandonedUsersState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: AbandonedUsersState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: AbandonedUsersState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: AbandonedUsersState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateStatusFilter(state: AbandonedUsersState, action: PayloadAction<api.UserAccountOnboardingStatus | undefined>) {
      state.statusFilter = action.payload;
    },
  },
});

export const {
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateStatusFilter,
} = abandonedUsers.actions;
export default abandonedUsers.reducer;
