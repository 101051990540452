import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type ExternalClientsState = {
  searchTerm?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  externalVendorsPage?: number;
  externalVendorsTotalPages?: number;
  externalVendorsLimit?: PaginationDropdownLimit;
  externalVendorsSortField?: string;
  externalVendorsSortDirection?: api.SortDirection;
};

const initialState: ExternalClientsState = {
  searchTerm: '',
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  externalVendorsPage: 1,
  externalVendorsTotalPages: undefined,
  externalVendorsLimit: { key: '25', value: 25 },
  externalVendorsSortField: 'totalInvoiceSpend',
  externalVendorsSortDirection: api.SortDirection.DESCENDING,
};

const externalClientsSlice = createSlice({
  name: 'externalClients',
  initialState,
  reducers: {
    updateSearchTerm(state: ExternalClientsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updatePage(state: ExternalClientsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: ExternalClientsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: ExternalClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: ExternalClientsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: ExternalClientsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateExternalVendorsPage(state: ExternalClientsState, action: PayloadAction<number>) {
      state.externalVendorsPage = action.payload;
    },
    updateExternalVendorsTotalPages(state: ExternalClientsState, action: PayloadAction<number>) {
      state.externalVendorsTotalPages = action.payload;
    },
    updateExternalVendorsLimit(state: ExternalClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.externalVendorsLimit = action.payload;
    },
    updateExternalVendorsSortField(state: ExternalClientsState, action: PayloadAction<string>) {
      state.externalVendorsSortField = action.payload;
    },
    updateExternalVendorsSortDirection(state: ExternalClientsState, action: PayloadAction<api.SortDirection>) {
      state.externalVendorsSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateExternalVendorsPage,
  updateExternalVendorsTotalPages,
  updateExternalVendorsLimit,
  updateExternalVendorsSortField,
  updateExternalVendorsSortDirection,
} = externalClientsSlice.actions;

export default externalClientsSlice.reducer;
