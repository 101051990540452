import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DateTime } from 'luxon';
import { toEndOfDayLocalTime, toStartOfDayLocalTime } from '../../utils';

export type AcceleratedPayoutState = {
  acceleratedPayoutMethod?: api.AcceleratedPayoutMethod,
  partner?: api.Partners;
  vendorLocale?: api.VendorLocale;
  periodization: api.TimeSeriesPeriodization;
  enforcePeriodization: boolean;
  includePreviousTotals?: boolean;
  beginningAt: api.ISODateString;
  endingAt: api.ISODateString;
};

export const initialState: AcceleratedPayoutState = {
  acceleratedPayoutMethod: undefined,
  partner: undefined,
  vendorLocale: undefined,
  periodization: api.TimeSeriesPeriodization.WEEK,
  enforcePeriodization: false,
  includePreviousTotals: true,
  beginningAt: toStartOfDayLocalTime(DateTime.now().startOf('year').toJSDate()).toISOString(),
  endingAt: toEndOfDayLocalTime(DateTime.now().endOf('year').toJSDate()).toISOString(),
};

const acceleratedPayout = createSlice({
  name: 'acceleratedPayout',
  initialState: initialState,
  reducers: {
    updatePartner(state: AcceleratedPayoutState, action: PayloadAction<api.Partners | undefined>) {
      state.partner = action.payload;
    },
    updateAcceleratedPayoutMethod(state: AcceleratedPayoutState, action: PayloadAction<api.AcceleratedPayoutMethod | undefined>) {
      state.acceleratedPayoutMethod = action.payload;
    },
    updateVendorLocale(state: AcceleratedPayoutState, action: PayloadAction<api.VendorLocale | undefined>) {
      state.vendorLocale = action.payload;
    },
    updatePeriodization(state: AcceleratedPayoutState, action: PayloadAction<api.TimeSeriesPeriodization>) {
      state.periodization = action.payload;
    },
    updateEnforcePeriodization(state: AcceleratedPayoutState, action: PayloadAction<boolean>) {
      state.enforcePeriodization = action.payload;
    },
    updateIncludePreviousTotals(state: AcceleratedPayoutState, action: PayloadAction<boolean>) {
      state.includePreviousTotals = action.payload;
    },
    updateBeginningAt(state: AcceleratedPayoutState, action: PayloadAction<api.ISODateString>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: AcceleratedPayoutState, action: PayloadAction<api.ISODateString>) {
      state.endingAt = action.payload;
    },
  },
});

export const {
  updatePartner,
  updateVendorLocale,
  updatePeriodization,
  updateEnforcePeriodization,
  updateIncludePreviousTotals,
  updateBeginningAt,
  updateEndingAt,
  updateAcceleratedPayoutMethod
} = acceleratedPayout.actions;
export default acceleratedPayout.reducer;
