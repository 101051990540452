import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { DateTime } from 'luxon';
import { toEndOfDayLocalTime, toStartOfDayLocalTime } from '../../utils';

export type StatisticsState = {
  partner?: api.Partners;
  vendorLocale?: api.VendorLocale;
  periodization?: api.TimeSeriesPeriodization;
  enforcePeriodization: boolean;
  includePreviousTotals?: boolean;
  beginningAt?: api.ISODateString;
  endingAt?: api.ISODateString;
  businessType?: api.InvoiceBusinessType;
  periodType?: api.InvoicePeriodType;
  periodState?: api.InvoicePeriodState;
  interestRateType?: api.InterestRateType;
  interestRate?: number;
  yieldPage?: number;
  yieldTotalPages?: number;
  yieldLimit?: PaginationDropdownLimit;
  yieldSortField?: string;
  yieldSortDirection?: api.SortDirection;
  byVendorPage?: number;
  byVendorTotalPages?: number;
  byVendorLimit?: PaginationDropdownLimit;
};

export const initialState: StatisticsState = {
  partner: undefined,
  vendorLocale: undefined,
  periodization: api.TimeSeriesPeriodization.WEEK,
  enforcePeriodization: false,
  includePreviousTotals: true,
  beginningAt: toStartOfDayLocalTime(DateTime.now().startOf('year').toJSDate()).toISOString(),
  endingAt: toEndOfDayLocalTime(DateTime.now().endOf('week').toJSDate()).toISOString(),
  businessType: api.InvoiceBusinessType.VENDOR,
  periodType: api.InvoicePeriodType.FLOAT,
  periodState: api.InvoicePeriodState.OPEN,
  interestRateType: api.InterestRateType.SIMPLE,
  interestRate: 0.0450,
  yieldPage: 1,
  yieldTotalPages: undefined,
  yieldLimit: DefaultPaginationDropdownLimits[0],
  yieldSortField: 'total_merchant_revenue',
  yieldSortDirection: api.SortDirection.DESCENDING,
  byVendorPage: 1,
  byVendorTotalPages: undefined,
  byVendorLimit: DefaultPaginationDropdownLimits[0],
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    updatePartner(state: StatisticsState, action: PayloadAction<api.Partners | undefined>) {
      state.partner = action.payload;
      state.yieldPage = 1;
    },
    updateVendorLocale(state: StatisticsState, action: PayloadAction<api.VendorLocale | undefined>) {
      state.vendorLocale = action.payload;
      state.yieldPage = 1;
    },
    updatePeriodization(state: StatisticsState, action: PayloadAction<api.TimeSeriesPeriodization>) {
      state.periodization = action.payload;
    },
    updateEnforcePeriodization(state: StatisticsState, action: PayloadAction<boolean>) {
      state.enforcePeriodization = action.payload;
    },
    updateIncludePreviousTotals(state: StatisticsState, action: PayloadAction<boolean>) {
      state.includePreviousTotals = action.payload;
    },
    updateBeginningAt(state: StatisticsState, action: PayloadAction<api.ISODateString>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: StatisticsState, action: PayloadAction<api.ISODateString>) {
      state.endingAt = action.payload;
    },
    updateBusinessType(state: StatisticsState, action: PayloadAction<api.InvoiceBusinessType>) {
      state.businessType = action.payload;
      state.yieldPage = 1;
    },
    updatePeriodType(state: StatisticsState, action: PayloadAction<api.InvoicePeriodType>) {
      state.periodType = action.payload;
      state.byVendorPage = 1;
    },
    updatePeriodState(state: StatisticsState, action: PayloadAction<api.InvoicePeriodState>) {
      state.periodState = action.payload;
      state.yieldPage = 1;
      state.byVendorPage = 1;
    },
    updateInterestRateType(state: StatisticsState, action: PayloadAction<api.InterestRateType>) {
      state.interestRateType = action.payload;
      state.yieldPage = 1;
    },
    updateInterestRate(state: StatisticsState, action: PayloadAction<number>) {
      state.interestRate = action.payload;
      state.yieldPage = 1;
    },
    updateYieldPage(state: StatisticsState, action: PayloadAction<number>) {
      state.yieldPage = action.payload;
    },
    updateYieldTotalPages(state: StatisticsState, action: PayloadAction<number>) {
      state.yieldTotalPages = action.payload;
    },
    updateYieldLimit(state: StatisticsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.yieldLimit = action.payload;
      state.yieldPage = 1;
    },
    updateYieldSortField(state: StatisticsState, action: PayloadAction<string>) {
      state.yieldSortField = action.payload;
    },
    updateYieldSortDirection(state: StatisticsState, action: PayloadAction<api.SortDirection>) {
      state.yieldSortDirection = action.payload;
    },
    updateByVendorPage(state: StatisticsState, action: PayloadAction<number>) {
      state.byVendorPage = action.payload;
    },
    updateByVendorTotalPages(state: StatisticsState, action: PayloadAction<number>) {
      state.byVendorTotalPages = action.payload;
    },
    updateByVendorLimit(state: StatisticsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.byVendorLimit = action.payload;
      state.byVendorPage = 1;
    },
  },
});

export const {
  updatePartner,
  updateVendorLocale,
  updatePeriodization,
  updateEnforcePeriodization,
  updateIncludePreviousTotals,
  updateBeginningAt,
  updateEndingAt,
  updateBusinessType,
  updatePeriodType,
  updatePeriodState,
  updateInterestRateType,
  updateInterestRate,
  updateYieldPage,
  updateYieldTotalPages,
  updateYieldLimit,
  updateYieldSortField,
  updateYieldSortDirection,
  updateByVendorPage,
  updateByVendorTotalPages,
  updateByVendorLimit,
} = statisticsSlice.actions;
export default statisticsSlice.reducer;
