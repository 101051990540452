import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AnomaliesState = {
  partnerId?: api.Partners;
  externalClientId?: string;
  externalVendorId?: string;
  anomalyType?: api.ClientVendorAnomalyType;
  createdBeginningAt?: api.ISODateString;
  createdEndingAt?: api.ISODateString;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
};

export const initialState: AnomaliesState = {
  partnerId: undefined,
  externalClientId: undefined,
  externalVendorId: undefined,
  anomalyType: undefined,
  createdBeginningAt: undefined,
  createdEndingAt: undefined,
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const anomaliesSlice = createSlice({
  name: 'anomalies',
  initialState,
  reducers: {
    updatePartnerId(state: AnomaliesState, action: PayloadAction<api.Partners | undefined>) {
      state.partnerId = action.payload;
    },
    updateExternalClientId(state: AnomaliesState, action: PayloadAction<string | undefined>) {
      state.externalClientId = action.payload;
    },
    updateExternalVendorId(state: AnomaliesState, action: PayloadAction<string | undefined>) {
      state.externalVendorId = action.payload;
    },
    updateAnomalyType(state: AnomaliesState, action: PayloadAction<api.ClientVendorAnomalyType | undefined>) {
      state.anomalyType = action.payload;
    },
    updateCreatedBeginningAt(state: AnomaliesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.createdBeginningAt = action.payload;
    },
    updateCreatedEndingAt(state: AnomaliesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.createdEndingAt = action.payload;
    },
    updatePage(state: AnomaliesState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: AnomaliesState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: AnomaliesState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: AnomaliesState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: AnomaliesState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updatePartnerId,
  updateExternalClientId,
  updateExternalVendorId,
  updateAnomalyType,
  updateCreatedBeginningAt,
  updateCreatedEndingAt,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
} = anomaliesSlice.actions;
export default anomaliesSlice.reducer;
