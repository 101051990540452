import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

import * as types from '../../types';

export type RevenueState = {
  beginningAt: api.ISODateString;
  endingAt: api.ISODateString;
  periodization: api.TimeSeriesPeriodization;
  enforcePeriodization?: boolean;
  revenueDateAnchoring: api.RevenueDateAnchoring;
  vendorSummaryTimeSeriesDisplay: types.SummaryTimeSeriesDisplay;
  vendorClient?: api.ClientResponse;
  vendorPage: number;
  vendorTotalPages?: number;
  vendorLimit: PaginationDropdownLimit;
  vendorSortField: string;
  vendorSortDirection: api.SortDirection;
  clientSummaryTimeSeriesDisplay: types.SummaryTimeSeriesDisplay;
  clientPage: number;
  clientTotalPages?: number;
  clientLimit: PaginationDropdownLimit;
  clientSortField: string;
  clientSortDirection: api.SortDirection;
  runRateTrailingDays: number;
};

export const initialState: RevenueState = {
  beginningAt: DateTime.now().minus({ month: 6 }).startOf('month').toISO(),
  endingAt: DateTime.now().plus({ month: 1 }).endOf('month').toISO(),
  periodization: api.TimeSeriesPeriodization.MONTH,
  enforcePeriodization: false,
  revenueDateAnchoring: api.RevenueDateAnchoring.FUNDED_DATE,
  vendorSummaryTimeSeriesDisplay: types.SummaryTimeSeriesDisplay.Actual,
  vendorClient: undefined,
  vendorPage: 1,
  vendorTotalPages: undefined,
  vendorLimit: DefaultPaginationDropdownLimits[0],
  vendorSortField: 'totalRevenue',
  vendorSortDirection: api.SortDirection.DESCENDING,
  clientSummaryTimeSeriesDisplay: types.SummaryTimeSeriesDisplay.Actual,
  clientPage: 1,
  clientTotalPages: undefined,
  clientLimit: DefaultPaginationDropdownLimits[0],
  clientSortField: 'totalRevenue',
  clientSortDirection: api.SortDirection.DESCENDING,
  runRateTrailingDays: 30
};

const revenueSlice = createSlice({
  name: 'revenue',
  initialState,
  reducers: {
    updateBeginningAt(state: RevenueState, action: PayloadAction<api.ISODateString>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: RevenueState, action: PayloadAction<api.ISODateString>) {
      state.endingAt = action.payload;
    },
    updatePeriodization(state: RevenueState, action: PayloadAction<api.TimeSeriesPeriodization>) {
      state.periodization = action.payload;
    },
    updateEnforcePeriodization(state: RevenueState, action: PayloadAction<boolean>) {
      state.enforcePeriodization = action.payload;
    },
    updateRevenueDateAnchoring(state: RevenueState, action: PayloadAction<api.RevenueDateAnchoring>) {
      state.revenueDateAnchoring = action.payload;
    },
    updateVendorSummaryTimeSeriesDisplay(state: RevenueState, action: PayloadAction<types.SummaryTimeSeriesDisplay>) {
      state.vendorSummaryTimeSeriesDisplay = action.payload;
    },
    updateVendorClient(state: RevenueState, action: PayloadAction<api.ClientResponse | undefined>) {
      state.vendorClient = action.payload;
    },
    updateVendorPage(state: RevenueState, action: PayloadAction<number>) {
      state.vendorPage = action.payload;
    },
    updateVendorTotalPages(state: RevenueState, action: PayloadAction<number>) {
      state.vendorTotalPages = action.payload;
    },
    updateVendorLimit(state: RevenueState, action: PayloadAction<PaginationDropdownLimit>) {
      state.vendorLimit = action.payload;
    },
    updateVendorSortField(state: RevenueState, action: PayloadAction<string>) {
      state.vendorSortField = action.payload;
    },
    updateVendorSortDirection(state: RevenueState, action: PayloadAction<api.SortDirection>) {
      state.vendorSortDirection = action.payload;
    },
    updateClientSummaryTimeSeriesDisplay(state: RevenueState, action: PayloadAction<types.SummaryTimeSeriesDisplay>) {
      state.clientSummaryTimeSeriesDisplay = action.payload;
    },
    updateClientPage(state: RevenueState, action: PayloadAction<number>) {
      state.clientPage = action.payload;
    },
    updateClientTotalPages(state: RevenueState, action: PayloadAction<number>) {
      state.clientTotalPages = action.payload;
    },
    updateClientLimit(state: RevenueState, action: PayloadAction<PaginationDropdownLimit>) {
      state.clientLimit = action.payload;
    },
    updateClientSortField(state: RevenueState, action: PayloadAction<string>) {
      state.clientSortField = action.payload;
    },
    updateClientSortDirection(state: RevenueState, action: PayloadAction<api.SortDirection>) {
      state.clientSortDirection = action.payload;
    },
    updateRunRateTrailingDays(state: RevenueState, action: PayloadAction<number>) {
      state.runRateTrailingDays = action.payload;
    },
  },
});

export const {
  updateBeginningAt,
  updateEndingAt,
  updatePeriodization,
  updateEnforcePeriodization,
  updateRevenueDateAnchoring,
  updateVendorSummaryTimeSeriesDisplay,
  updateVendorClient,
  updateVendorPage,
  updateVendorTotalPages,
  updateVendorLimit,
  updateVendorSortField,
  updateVendorSortDirection,
  updateClientSummaryTimeSeriesDisplay,
  updateClientPage,
  updateClientTotalPages,
  updateClientLimit,
  updateClientSortField,
  updateClientSortDirection,
  updateRunRateTrailingDays
} = revenueSlice.actions;
export default revenueSlice.reducer;
