import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Base64 } from 'js-base64';
import { types as api, VendorApi } from '@mesa-labs/mesa-api';

import cognitoService from '../../cognito';
import { useApi } from './api';

export const serviceAgreementsApi = createApi({
  reducerPath: 'serviceAgreementsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.vendorUrl,
    prepareHeaders: async (headers) => {
      try {
        const idToken = await cognitoService.getIdToken();

        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      } catch (err) {
        // no token available, don't add Authorization header
      }

      headers.set('mode', 'cors');
      headers.set('cache', 'no-cache');
      headers.set('credentials', 'same-origin');
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  tagTypes: ['ServiceAgreements', 'ActiveServiceAgreement'],
  endpoints: (build) => ({
    getAllServiceAgreements: build.query<readonly api.ServiceAgreementResponse[], void>({
      async queryFn() {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllServiceAgreements<api.ServiceAgreementResponse>();
        return { data };
      },
      providesTags: ['ServiceAgreements'],
    }),
    getActiveServiceAgreement: build.query<api.ServiceAgreementResponse | undefined, void>({
      async queryFn() {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllServiceAgreements<api.ServiceAgreementResponse>();
        return { data: data.find((sa) => sa.active) };
      },
      providesTags: ['ActiveServiceAgreement'],
    }),
    createServiceAgreement: build.mutation<api.ServiceAgreementResponse, api.ServiceAgreementRequest>({
      async queryFn({ htmlTemplate, ...rest }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createServiceAgreement<api.ServiceAgreementResponse>({
          ...rest,
          htmlTemplate: Base64.encode(htmlTemplate),
        });
        return { data };
      },
      invalidatesTags: ['ServiceAgreements'],
    }),
    updateServiceAgreement: build.mutation<api.ServiceAgreementResponse, api.ServiceAgreementRequest & { id?: number }>({
      async queryFn({ id, htmlTemplate, ...rest }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.updateServiceAgreement<api.ServiceAgreementResponse>(id!, {
          ...rest,
          htmlTemplate: Base64.encode(htmlTemplate),
        });
        return { data };
      },
      invalidatesTags: ['ServiceAgreements'],
    }),
    activateServiceAgreement: build.mutation<api.ServiceAgreementResponse | undefined, { version: number }>({
      async queryFn({ version }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.activateServiceAgreement<api.ServiceAgreementResponse>(version);
        return { data };
      },
      invalidatesTags: ['ServiceAgreements', 'ActiveServiceAgreement'],
    }),
  }),
});

export const {
  useGetAllServiceAgreementsQuery,
  useGetActiveServiceAgreementQuery,
  useCreateServiceAgreementMutation,
  useUpdateServiceAgreementMutation,
  useActivateServiceAgreementMutation,
} = serviceAgreementsApi;
