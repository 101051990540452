import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../slices';
import { paymentsApi } from '../api/payments';
import { invoicesApi } from '../api/invoices';
import { vendorsApi } from '../api/vendors';
import { serviceAgreementsApi } from '../api/serviceAgreements';
import { partnerServiceAgreementsApi } from '../api/partnerServiceAgreements';
import { externalVendorsApi } from '../api/externalVendors';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'acceleratedPayout',
    'adjustments',
    'anomalies',
    'auth',
    'batchSweeps',
    'cohorts',
    'collections',
    'eligibleInvoices',
    'enablement',
    'invoices',
    'invoiceLineItems',
    'customerBatchTransfers',
    'onboardings',
    'reconciliation',
    'statistics',
    'vendors',
    'clients',
    'externalVendors',
    'facilitators',
    'kpis',
    'externalClients',
    'externalClientVendors',
    'refunds',
    'revenue',
    'repaymentItems',
    'tasks',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    paymentsApi.middleware,
    invoicesApi.middleware,
    vendorsApi.middleware,
    serviceAgreementsApi.middleware,
    partnerServiceAgreementsApi.middleware,
    externalVendorsApi.middleware,
  ],
});

/** @TODO migration for new limit values */
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
