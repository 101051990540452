import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { CognitoAccessToken, CognitoUser } from 'amazon-cognito-identity-js';

import { Api } from '@mesa-labs/mesa-api';
import cognitoService from '../../cognito';
import * as types from './types';

export enum AuthenticationRole {
  ROLE_OPERATOR = 'ROLE_OPERATOR',
  ROLE_SERVICE = 'ROLE_SERVICE',
  ROLE_FACILITATOR = 'ROLE_FACILITATOR',
  ROLE_FINANCE = 'ROLE_FINANCE',
  ROLE_VENDOR = 'ROLE_VENDOR',
}

export enum AuthenticationGroup {
  OPERATORS = 'Operators',
  FINANCIERS = 'Financiers',
}

type AuthResult = {
  readonly username: string;
  readonly mfaOptions: string[];
  readonly isLoggedIn: boolean;
  readonly isReadOnly: boolean;
  readonly canViewBasicEntities: boolean;
  readonly canViewBusinessInternals: boolean;
  readonly canPerformFinancialOperations: boolean;
  readonly partnerId?: number;
  readonly roles: AuthenticationGroup[];
  readonly attributes?: Record<string, any>;
};

export interface AuthState {
  authEnvironment?: string,
  username?: string,
  mfaOptions: string[],
  error?: Error;
  loading: boolean;
  isLoggedIn: boolean;
  isReadOnly: boolean;
  canViewBasicEntities: boolean;
  canViewBusinessInternals: boolean;
  canPerformFinancialOperations: boolean;
  partnerId?: number;
  roles: AuthenticationGroup[];
  attributes?: Record<string, any>;
}

type ChangePasswordArgs = {
  readonly oldPassword: string;
  readonly newPassword: string;
};

const initialState: AuthState = {
  authEnvironment: undefined,
  username: undefined,
  mfaOptions: [],
  error: undefined,
  loading: false,
  isLoggedIn: false,
  isReadOnly: false,
  canViewBasicEntities: false,
  canViewBusinessInternals: false,
  canPerformFinancialOperations: false,
  partnerId: undefined,
  roles: [],
  attributes: undefined,
};

const InternalGroups = new Set<string>(['Operators', 'Financiers']);

export const getMfaOptions = (user: CognitoUser): Promise<string[]> => new Promise((resolve, reject) => {
  user.getUserData((err, data) => {
    if (err) {
      reject(err);
    } else {
      resolve(data?.UserMFASettingList || []);
    }
  });
});

export const getRBAC = async (user: CognitoUser, accessToken: CognitoAccessToken): Promise<AuthResult> => {
  const groups = (accessToken.payload['cognito:groups'] as AuthenticationGroup[]) || [];
  const intersection = new Set([...groups].filter((x) => InternalGroups.has(x)));
  if (intersection.size === 0) {
    await cognitoService.signOut();
    throw new Error('User does not have legal RBAC role.');
  }
  const properties = await cognitoService.getUserAttributes(user);
  const isReadOnly = properties['custom:readOnly'] === 'true';
  const partnerId = parseInt(properties['custom:partnerId'], 10) || undefined;

  const isOperator = intersection.has(AuthenticationGroup.OPERATORS);
  const isFinancier = intersection.has(AuthenticationGroup.FINANCIERS);

  const canViewBasicEntities = isOperator || isFinancier;

  const canViewBusinessInternals = isOperator || isFinancier;

  const mfaOptions: string[] = await getMfaOptions(user);
  const attributes = await cognitoService.getUserAttributes(user);

  return {
    username: attributes.email,
    mfaOptions,
    isLoggedIn: true,
    isReadOnly,
    canViewBasicEntities,
    canViewBusinessInternals,
    canPerformFinancialOperations: isFinancier,
    partnerId,
    roles: [...intersection],
    attributes,
  };
};

export const getUserAttributes = createAsyncThunk(
  'auth/getUserAttributes',
  async (): Promise<Record<string, any>> => {
    // clear any prior session first
    const user = cognitoService.getCurrentUser();
    if (!user) {
      throw new Error('No user found after signIn');
    }
    return await cognitoService.getUserAttributes(user);
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (args: { email: string, password: string, roleHint: AuthenticationRole }): Promise<void> => {
    // clear any prior session first
    await cognitoService.signOut();

    await cognitoService.signUp(
      args.email,
      args.password,
      args.roleHint,
    );
  },
);

export const confirmSignUp = createAsyncThunk(
  'auth/confirmAccount',
  async (args: { email: string, code: string }): Promise<any> => await cognitoService.confirmAccount(args.email, args.code),
);

export const resendConfirmationCode = createAsyncThunk(
  'auth/resendConfirmationCode',
  async (args: { email: string }): Promise<any> => await cognitoService.resendConfirmationCode(args.email),
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (args: {
    email: string,
    password: string,
  }): Promise<AuthResult> => {
    const { email, password } = args;
    await cognitoService.signIn(email, password, null);

    const user = cognitoService.getCurrentUser();
    if (!user) {
      throw new Error('No user found after signIn');
    }

    const session = await cognitoService.getCurrentSession();
    if (!session) {
      throw new Error('No session found after signIn');
    }

    return await getRBAC(user, session.getAccessToken());
  },
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (): Promise<void> => {
    await cognitoService.signOut();
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (args: ChangePasswordArgs): Promise<void> => {
    await cognitoService.changePassword(args.oldPassword, args.newPassword);
  },
);

export const setupCognito = createAsyncThunk(
  'auth/setupCognito',
  async (args: { forceRefresh: boolean }): Promise<AuthResult> => {
    const user = cognitoService.getCurrentUser();
    if (!user) {
      throw new Error('No user found during setupCognito');
    }

    let session = await cognitoService.getCurrentSession();
    if (!session) {
      throw new Error('No session found during setupCognito');
    }

    const forceRefresh = args.forceRefresh;
    const token = session.getIdToken().getJwtToken();
    const expiresAt = session.getIdToken().getExpiration();
    if (forceRefresh || !session.isValid() || Api.hasTokenExpired({ token, expiresAt })) {
      session = await cognitoService.refreshCurrentSession();
      if (!session) {
        throw new Error('Failed to refresh session found during setupCognito');
      }
    }

    return await getRBAC(user, session.getAccessToken());
  },
);

export const getImpersonatedVendorId = (attributes?: Record<string, any>): string | undefined => {
  return (attributes || {})['custom:vendorId'];
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError(state: AuthState) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupCognito.fulfilled, (state: AuthState, action) => {
        state.authEnvironment = process.env.NODE_ENV;
        state.username = action.payload.username;
        state.mfaOptions = action.payload.mfaOptions;
        state.isLoggedIn = action.payload.isLoggedIn;
        state.isReadOnly = action.payload.isReadOnly;
        state.canViewBasicEntities = action.payload.canViewBasicEntities;
        state.canViewBusinessInternals = action.payload.canViewBusinessInternals;
        state.canPerformFinancialOperations = action.payload.canPerformFinancialOperations;
        state.partnerId = action.payload.partnerId;
        state.roles = action.payload.roles;
        state.attributes = action.payload.attributes;
      })
      .addCase(setupCognito.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(signIn.fulfilled, (state: AuthState, action) => {
        state.authEnvironment = process.env.NODE_ENV;
        state.username = action.payload.username;
        state.mfaOptions = action.payload.mfaOptions;
        state.isLoggedIn = action.payload.isLoggedIn;
        state.isReadOnly = action.payload.isReadOnly;
        state.canViewBasicEntities = action.payload.canViewBasicEntities;
        state.canViewBusinessInternals = action.payload.canViewBusinessInternals;
        state.canPerformFinancialOperations = action.payload.canPerformFinancialOperations;
        state.partnerId = action.payload.partnerId;
        state.roles = action.payload.roles;
        state.attributes = action.payload.attributes;
      })
      .addCase(signIn.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(signOut.fulfilled, (state: AuthState) => {
        state.isLoggedIn = false;
        state.username = undefined;
        state.mfaOptions = [];
        state.isReadOnly = true;
        state.canViewBasicEntities = false;
        state.canViewBusinessInternals = false;
        state.canPerformFinancialOperations = false;
        state.partnerId = undefined;
        state.roles = [];
        state.attributes = {};
      })
      .addCase(getUserAttributes.fulfilled, (state: AuthState, action) => {
        state.attributes = action.payload;
      })
      .addMatcher((action) => types.isPendingAction(action, 'auth'), (state: AuthState) => {
        state.loading = true;
      })
      .addMatcher((action) => types.isRejectedAction(action, 'auth'), (state: AuthState, action) => {
        state.loading = false;
        state.error = <Error>action.error;
      })
      .addMatcher((action) => types.isFulfilledAction(action, 'auth'), (state: AuthState) => {
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const {
  clearError,
} = authSlice.actions;

export default authSlice.reducer;
