import { AnyAction, AsyncThunk } from '@reduxjs/toolkit';

export type GenericAsyncThunk = AsyncThunk<any, any, any>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export function isPendingAction(
  action: AnyAction,
  reducerName?: string,
): action is PendingAction {
  if (reducerName && !action.type.startsWith(reducerName)) {
    return false;
  }

  return action.type.endsWith('/pending');
}

export function isRejectedAction(
  action: AnyAction,
  reducerName?: string,
): action is RejectedAction {
  if (reducerName && !action.type.startsWith(reducerName)) {
    return false;
  }

  return action.type.endsWith('/rejected');
}

export function isFulfilledAction(
  action: AnyAction,
  reducerName?: string,
): action is FulfilledAction {
  if (reducerName && !action.type.startsWith(reducerName)) {
    return false;
  }

  return action.type.endsWith('/fulfilled');
}
