import { PaginationDropdownLimit, DefaultPaginationDropdownLimits } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';

import { TaskService } from '../../types';

const DefaultPaginationDropdownLimit = DefaultPaginationDropdownLimits[0];

export type TasksState = {
  selectedService?: TaskService;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
};

export const initialState: TasksState = {
  selectedService: TaskService.InvoiceService,
  page: 1,
  totalPages: undefined,
  limit: DefaultPaginationDropdownLimit,
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    updateSelectedService(state: TasksState, action: PayloadAction<TaskService>) {
      state.selectedService = action.payload;
      state.page = 1;
      state.totalPages = undefined;
    },
    updatePage(state: TasksState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: TasksState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: TasksState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: TasksState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: TasksState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updateSelectedService,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
} = tasksSlice.actions;
export default tasksSlice.reducer;
