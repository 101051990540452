import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';

export type RepaymentItemsState = {
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
};

export const initialState: RepaymentItemsState = {
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const repaymentItemsSlice = createSlice({
  name: 'repaymentItems',
  initialState,
  reducers: {
    updatePage(state: RepaymentItemsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: RepaymentItemsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: RepaymentItemsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: RepaymentItemsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: RepaymentItemsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortDirection,
  updateSortField,
} = repaymentItemsSlice.actions;
export default repaymentItemsSlice.reducer;
