import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OnboardingsState = {
  searchTerm?: string;
  searchNotExpiredOnly: boolean;
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
};

const initialState: OnboardingsState = {
  searchTerm: '',
  searchNotExpiredOnly: false,
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
};

const onboardingsSlice = createSlice({
  name: 'onboardings',
  initialState,
  reducers: {
    updateSearchTerm(state: OnboardingsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updateSearchNotExpiredOnly(state: OnboardingsState, action: PayloadAction<boolean>) {
      state.searchNotExpiredOnly = action.payload;
    },
    updatePage(state: OnboardingsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: OnboardingsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: OnboardingsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updateSearchNotExpiredOnly,
  updatePage,
  updateTotalPages,
  updateLimit,
} = onboardingsSlice.actions;
export default onboardingsSlice.reducer;
