import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Checkbox, Typography } from '@mesa-labs/mesa-ui';

export const FilterSectionContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.White};
  border: 1px solid #EBEAED;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 0 0px 24px 0px;
  padding: 16px;
`;

const FiltersContainer = styled.div`
  z-index: 29
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FilterHeader = styled.div`
  color: ${(props) => props.theme.colors.Black90};
  font-size: 20px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
`;

const AnimationContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

export const FilterTitle = styled.div`
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px; 
`;

export const FilterRowContainer = styled.div<{ columnCount: number; }>`
  align-items: center;
  display: grid;
  gap: 8px 12px;
  grid-auto-flow: column;
  margin-bottom: 16px;
  grid-template-columns: ${(props) => `repeat(${props.columnCount}, 1fr)`};

  &:first-of-type {
    margin-top: 8px;
  }
`;

export const FilterCell = styled.div<{ alignItems?: string; }>`
  display: flex;
  flex-direction: column;
  ${(props) => props.alignItems ? `align-items: ${props.alignItems}` : ''};
`;

export const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`;

export const FilterControlsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 12px;
  z-index: 31;
`;

export const FilterSpan = styled.div<{ columns: number; }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  gap: 24px;
`;

const ApplyButtonContainer = styled.div`
  align-self: flex-end;
  width: fit-content;
`;

const AutoApplyLabel = styled(Typography.BodySmall)`
  color: ${(props) => props.theme.colors.Gray500};
`;

type FilterSectionProps = {
  children?: ReactNode
  filterHeader?: string;
  filterControl?: ReactNode;
  isInitiallyOpen?: boolean;
  isAlwaysOpen?: boolean;
  appliedFilters?: number;
  onApply?: () => void;
  isApplying?: boolean;
};

export function FilterSection({
  children,
  filterHeader,
  filterControl,
  isInitiallyOpen,
  isAlwaysOpen,
  appliedFilters,
  onApply = undefined,
  isApplying = undefined,
}: FilterSectionProps) {
  const [open, setOpen] = useState(isInitiallyOpen || isAlwaysOpen || !!onApply || false);

  return (
    <FilterSectionContainer
      onKeyPress={(evt) => {
        evt.stopPropagation();

        if (evt.key === 'Enter' && onApply) {
          onApply();
        }
      }}
    >
      <HeaderContainer>
        <>
          {filterHeader && <FilterHeader>{filterHeader}</FilterHeader>}
          {filterControl && (
            filterControl
          )}

          {children && !isAlwaysOpen && (
            <ButtonContainer>
              <Button
                text={
                  open
                    ? `Hide Filters${appliedFilters ? ` (${appliedFilters})` : ''}`
                    : `Show Filters${appliedFilters ? ` (${appliedFilters})` : ''}`
                }
                iconSrc="/assets/filter-icon.svg"
                onClick={() => setOpen(!open)}
              />
            </ButtonContainer>
          )}
        </>
      </HeaderContainer>

      <AnimatePresence>
        {open && (
          <AnimationContainer
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.5,
                  type: 'spring',
                },
              },
            }}
            animate="show"
            initial="hidden"
            exit="hidden"
          >
            <FiltersContainer>
              {children}
            </FiltersContainer>


            <ApplyButtonContainer>
              {onApply ? (
                <Button
                  color="primary"
                  onClick={onApply}
                  text="Apply Filters"
                  isLoading={isApplying}
                />
              ) : (
                <AutoApplyLabel>Changes are automatically applied</AutoApplyLabel>
              )}
            </ApplyButtonContainer>

          </AnimationContainer>
        )}
      </AnimatePresence>
    </FilterSectionContainer>
  );
}

FilterSection.defaultProps = {
  children: undefined,
  filterHeader: undefined,
  filterControl: undefined,
  isInitiallyOpen: false,
  isAlwaysOpen: false,
  appliedFilters: undefined,
  onApply: undefined,
  isApplying: undefined,
};

type FilterRowProps = {
  children: ReactNode | readonly ReactNode[];
  columns?: number;
};

export function FilterRow({ children, columns }: FilterRowProps) {
  return (
    <FilterRowContainer columnCount={columns ? columns : Array.isArray(children) ? children.length : 1}>
      {children}
    </FilterRowContainer>
  );
}

FilterRow.defaultProps = {
  columns: undefined
};

type FilterCheckboxProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
};

const FilterCheckboxContainer = styled.div`
  div div {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  margin-top: 12px;
  margin-bottom: 12px;
`;

export function FilterCheckbox({ label, checked, onChange }: FilterCheckboxProps) {
  return (
    <FilterCheckboxContainer>
      <FilterTitle>&nbsp;</FilterTitle>
      <Checkbox
        label={label}
        checked={checked}
        onChange={onChange}
      />
    </FilterCheckboxContainer>
  )
}