import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AdjustmentsState = {
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  includeVendorsWithZeroBalance: boolean;
};

const initialState: AdjustmentsState = {
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  includeVendorsWithZeroBalance: true,
};

const adjustmentsSlice = createSlice({
  name: 'adjustments',
  initialState,
  reducers: {
    updatePage(state: AdjustmentsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: AdjustmentsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: AdjustmentsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateIncludeVendorsWithZeroBalance(state: AdjustmentsState, action: PayloadAction<boolean>) {
      state.includeVendorsWithZeroBalance = action.payload;
    },
  },
});

export const {
  updatePage,
  updateTotalPages,
  updateLimit,
  updateIncludeVendorsWithZeroBalance,
} = adjustmentsSlice.actions;
export default adjustmentsSlice.reducer;
