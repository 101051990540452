import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { toEndOfDayLocalTime, toStartOfDayLocalTime } from '../../utils';

export type KPIsState = {
  partner?: api.Partners;
  vendorLocale?: api.VendorLocale;
  programCode?: api.ProgramCodes;
  clientPaymentTermsMin?: number;
  clientPaymentTermsMax?: number;
  currencyCode?: api.CurrencyCode;
  beginningAt?: string;
  endingAt?: string;
  periodization?: api.TimeSeriesPeriodization;
  enforcePeriodization?: boolean;
  includePreviousSum?: boolean;
  periodicGrowthRate?: number;
  trailingDays?: number;
};

export const initialState: KPIsState = {
  partner: undefined,
  vendorLocale: undefined,
  programCode: undefined,
  clientPaymentTermsMin: undefined,
  clientPaymentTermsMax: undefined,
  currencyCode: api.CurrencyCode.USD,
  beginningAt: toStartOfDayLocalTime(DateTime.now().startOf('year').toJSDate()).toISOString(),
  endingAt: toEndOfDayLocalTime(DateTime.now().endOf('year').toJSDate()).toISOString(),
  periodization: api.TimeSeriesPeriodization.WEEK,
  enforcePeriodization: false,
  includePreviousSum: false,
  periodicGrowthRate: 0,
  trailingDays: 60,
};

const kpisSlice = createSlice({
  name: 'kpis',
  initialState,
  reducers: {
    updatePartner(state: KPIsState, action: PayloadAction<api.Partners | undefined>) {
      state.partner = action.payload;
    },
    updateVendorLocale(state: KPIsState, action: PayloadAction<api.VendorLocale | undefined>) {
      state.vendorLocale = action.payload;
    },
    updateProgramCode(state: KPIsState, action: PayloadAction<api.ProgramCodes | undefined>) {
      state.programCode = action.payload;
    },
    updateClientPaymentTermsMin(state: KPIsState, action: PayloadAction<number | undefined>) {
      state.clientPaymentTermsMin = action.payload;
    },
    updateClientPaymentTermsMax(state: KPIsState, action: PayloadAction<number | undefined>) {
      state.clientPaymentTermsMax = action.payload;
    },
    updateCurrencyCode(state: KPIsState, action: PayloadAction<api.CurrencyCode | undefined>) {
      state.currencyCode = action.payload;
    },
    updateBeginningAt(state: KPIsState, action: PayloadAction<string>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: KPIsState, action: PayloadAction<string>) {
      state.endingAt = action.payload;
    },
    updatePeriodization(state: KPIsState, action: PayloadAction<api.TimeSeriesPeriodization>) {
      state.periodization = action.payload;
    },
    updateEnforcePeriodization(state: KPIsState, action: PayloadAction<boolean>) {
      state.enforcePeriodization = action.payload;
    },
    updateIncludePreviousSum(state: KPIsState, action: PayloadAction<boolean>) {
      state.includePreviousSum = action.payload;
    },
    updatePeriodicGrowthRate(state: KPIsState, action: PayloadAction<number>) {
      state.periodicGrowthRate = action.payload;
    },
    updateTrailingDays(state: KPIsState, action: PayloadAction<number>) {
      state.trailingDays = Math.max(action.payload, 1);
    },
  },
});

export const {
  updatePartner,
  updateVendorLocale,
  updateProgramCode,
  updateClientPaymentTermsMin,
  updateClientPaymentTermsMax,
  updateCurrencyCode,
  updateBeginningAt,
  updateEndingAt,
  updatePeriodization,
  updateEnforcePeriodization,
  updateIncludePreviousSum,
  updatePeriodicGrowthRate,
  updateTrailingDays,
} = kpisSlice.actions;
export default kpisSlice.reducer;
