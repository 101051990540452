import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type EnablementState = {
  filter?: api.EnablementFilter;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
};

const initialState: EnablementState = {
  filter: api.EnablementFilter.ALL_IN_ENABLEMENT,
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const enablementSlice = createSlice({
  name: 'enablement',
  initialState,
  reducers: {
    updateFilter(state: EnablementState, action: PayloadAction<api.EnablementFilter>) {
      state.filter = action.payload;
    },
    updatePage(state: EnablementState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: EnablementState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: EnablementState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: EnablementState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: EnablementState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updateFilter,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
} = enablementSlice.actions;
export default enablementSlice.reducer;
