import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Base64 } from 'js-base64';
import { types as api, VendorApi } from '@mesa-labs/mesa-api';

import cognitoService from '../../cognito';
import { useApi } from './api';

export const partnerServiceAgreementsApi = createApi({
  reducerPath: 'partnerServiceAgreementsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.vendorUrl,
    prepareHeaders: async (headers) => {
      try {
        const idToken = await cognitoService.getIdToken();

        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      } catch (err) {
        // no token available, don't add Authorization header
      }

      headers.set('mode', 'cors');
      headers.set('cache', 'no-cache');
      headers.set('credentials', 'same-origin');
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  tagTypes: ['PartnerServiceAgreements', 'ActivePartnerServiceAgreement'],
  endpoints: (build) => ({
    getAllPartnerServiceAgreements: build.query<readonly api.PartnerServiceAgreementResponse[], { partnerId?: api.Partners }>({
      async queryFn({ partnerId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllPartnerServiceAgreements<api.PartnerServiceAgreementResponse>(partnerId);
        return { data };
      },
      providesTags: ['PartnerServiceAgreements'],
    }),
    getActivePartnerServiceAgreement: build.query<api.PartnerServiceAgreementResponse | undefined, void>({
      async queryFn() {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllPartnerServiceAgreements<api.PartnerServiceAgreementResponse>();
        return { data: data.find((sa) => sa.active) };
      },
      providesTags: ['ActivePartnerServiceAgreement'],
    }),
    createPartnerServiceAgreement: build.mutation<api.PartnerServiceAgreementResponse, api.PartnerServiceAgreementRequest>({
      async queryFn({ htmlTemplate, ...rest }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createPartnerServiceAgreement<api.PartnerServiceAgreementResponse>({
          ...rest,
          htmlTemplate: Base64.encode(htmlTemplate),
        });
        return { data };
      },
      invalidatesTags: ['PartnerServiceAgreements'],
    }),
    updatePartnerServiceAgreement: build.mutation<api.PartnerServiceAgreementResponse, api.PartnerServiceAgreementRequest & { partnerId: api.Partners, id?: number }>({
      async queryFn({ id, htmlTemplate, ...rest }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.updatePartnerServiceAgreement<api.PartnerServiceAgreementResponse>(rest.partnerId, id!, {
          ...rest,
          htmlTemplate: Base64.encode(htmlTemplate),
        });
        return { data };
      },
      invalidatesTags: ['PartnerServiceAgreements'],
    }),
    activatePartnerServiceAgreement: build.mutation<api.PartnerServiceAgreementResponse | undefined, { partnerId: api.Partners, version: number }>({
      async queryFn({ partnerId, version }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.activatePartnerServiceAgreement<api.PartnerServiceAgreementResponse>(partnerId, version);
        return { data };
      },
      invalidatesTags: ['PartnerServiceAgreements', 'ActivePartnerServiceAgreement'],
    }),
  }),
});

export const {
  useGetAllPartnerServiceAgreementsQuery,
  useGetActivePartnerServiceAgreementQuery,
  useCreatePartnerServiceAgreementMutation,
  useUpdatePartnerServiceAgreementMutation,
  useActivatePartnerServiceAgreementMutation,
} = partnerServiceAgreementsApi;
