import React, { useEffect, useState } from 'react';
import { types as api } from '@mesa-labs/mesa-api';

import { enumEntryToSelection, Selection } from '../../utils';
import Select from './Select';

type VendorLocaleSelections = ({ label: string; value: api.VendorLocale | undefined })[];

const ValidVendorLocaleSelections: VendorLocaleSelections = Object.entries(api.VendorLocale).map((x) => enumEntryToSelection<api.VendorLocale>(x[0], x[1]));
const AllVendorLocaleSelections: VendorLocaleSelections = [{ label: 'All', value: undefined }, ...ValidVendorLocaleSelections];
const NoVendorLocaleSelections: VendorLocaleSelections = [{ label: '(No Selection)', value: undefined }, ...ValidVendorLocaleSelections];

type VendorLocaleSelectProps = {
  selectedVendorLocale: api.VendorLocale | undefined;
  onSelectedVendorLocaleChange: (vendorLocale: api.VendorLocale | undefined) => void;
  allowAllVendorLocales?: boolean;
  allowNoVendorLocale?: boolean;
};

function VendorLocaleSelect({
  selectedVendorLocale,
  onSelectedVendorLocaleChange,
  allowAllVendorLocales,
  allowNoVendorLocale,
}: VendorLocaleSelectProps): React.ReactElement {
  const [vendorLocaleSelections, setVendorLocaleSelections] = useState(ValidVendorLocaleSelections);
  const [vendorLocaleSelection, setVendorLocaleSelection] = useState(ValidVendorLocaleSelections[0]);

  useEffect(() => {
    const newVendorLocaleSelections = allowAllVendorLocales
      ? AllVendorLocaleSelections
      : allowNoVendorLocale
        ? NoVendorLocaleSelections
        : ValidVendorLocaleSelections;
    setVendorLocaleSelections(newVendorLocaleSelections);
    setVendorLocaleSelection(newVendorLocaleSelections.find((s) => s?.value === selectedVendorLocale) || newVendorLocaleSelections[0]);
  }, [selectedVendorLocale, allowAllVendorLocales]);

  const handleOnChange = (selection: Selection<api.VendorLocale | undefined>) => {
    setVendorLocaleSelection(selection!);
    onSelectedVendorLocaleChange(selection!.value);
  };

  return (
    <Select
      value={vendorLocaleSelection}
      onChange={handleOnChange}
      options={vendorLocaleSelections}
    />
  );
}

VendorLocaleSelect.defaultProps = {
  allowAllVendorLocales: undefined,
  allowNoVendorLocale: undefined,
};

export default VendorLocaleSelect;
