import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type FacilitatorsState = {
  searchTerm?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
};

const initialState: FacilitatorsState = {
  searchTerm: '',
  page: 1,
  totalPages: undefined,
  limit: DefaultPaginationDropdownLimits[0],
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const facilitatorsSlice = createSlice({
  name: 'facilitators',
  initialState,
  reducers: {
    updateSearchTerm(state: FacilitatorsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updatePage(state: FacilitatorsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: FacilitatorsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: FacilitatorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: FacilitatorsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: FacilitatorsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
} = facilitatorsSlice.actions;
export default facilitatorsSlice.reducer;
