import cognitoService from '../../cognito';
import { InvoiceApi, types as api } from '@mesa-labs/mesa-api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CsvFormat, InvoiceFilterParams } from '@mesa-labs/mesa-api/dist/types';

import { getIdToken, prepareHeaders, StatisticsMutex, useApi, } from './api';
import { appendFilterParams } from '../../utils';

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.invoiceUrl,
    prepareHeaders: async (original) => {
      const headers = await prepareHeaders(original);
      headers.delete('Content-Type'); // cannot be supplied with uploadRemittanceAdvice
      return headers;
    },
  }),
  tagTypes: [
    'Invoice', 'InvoiceAudit', 'Invoices', 'InvoiceLineItem', 'InvoiceLineItems', 'InvoiceAdjustments', 'AdjustmentGroups', 'Collections',
    'CollectionInvoices', 'ReconciliationPayloads', 'ReconciliationPayload', 'Clients', 'Client', 'ClientMappings', 'ClientMapping', 'DebtFacilities', 'TaskSchedules', 'TaskExecutions', 'BatchSweeps', 'BatchSweep', 'RepaymentItemsForBatchSweep',
    'ConfigurationSettings', 'InvoicesNeedingApproval', 'ClientVendorAnomalies',
  ],
  endpoints: (build) => ({
    getAllInvoices: build.query<api.IPagedResults<api.InvoiceResponse>, api.InvoiceFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllInvoices<api.InvoiceResponse>(params);
        return { data };
      },
      providesTags: ['Invoices'],
    }),
    getInvoice: build.query<api.InvoiceAggregateResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getInvoice<api.InvoiceAggregateResponse>(id);
        return { data };
      },
      providesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }]
        : ['Invoice']),
    }),
    getAllReconciliationPayloads: build.query<api.IPagedResults<api.ReconciliationPayloadResponse>, api.ReconciliationPayloadFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllReconciliationPayloads<api.ReconciliationPayloadResponse>(params);
        return { data };
      },
      providesTags: ['ReconciliationPayloads'],
    }),
    getAllBatchSweeps: build.query<api.IPagedResults<api.BatchSweepResponse>, api.GetBatchSweepsFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getBatchSweeps(params);
        return { data };
      },
      providesTags: ['BatchSweeps'],
    }),
    getBatchSweep: build.query<api.BatchSweepResponse, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getBatchSweep(id);
        return { data };
      },
      providesTags: (result, error, id) => (result
        ? [{ type: 'BatchSweep', ...id, ...result }]
        : ['BatchSweep']),
    }),
    getRepaymentItemsForBatchSweep: build.query<api.GetPrincipalRepaymentItemsForBatchSweepResponse, { id: api.UUID } & api.GetPrincipalRepaymentItemsForBatchSweepFilterParams>({
      async queryFn({ id, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getRepaymentItemsForBatchSweep(id, params);
        return { data };
      },
      providesTags: (result, error, id) => (result
        ? [{ type: 'RepaymentItemsForBatchSweep', ...id, ...result }]
        : ['RepaymentItemsForBatchSweep']),
    }),
    getReconciliationPayloadAggregate: build.query<api.ReconciliationPayloadAggregateResponse, { partnerId: number, payloadIdentifier: string }>({
      async queryFn({ partnerId, payloadIdentifier }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getReconciliationPayloadAggregate<api.ReconciliationPayloadAggregateResponse>(partnerId, payloadIdentifier);
        return { data };
      },
      providesTags: ['ReconciliationPayload'],
    }),
    combineReconcilationPayloads: build.mutation<api.ReconciliationPayloadResponse, api.CombineReconciliationPayloadsRequest>({
      async queryFn(body) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.combineReconciliationPayloads(body);
        return { data };
      },
      invalidatesTags: ['ReconciliationPayloads'],
    }),
    deleteReconciliationPayload: build.mutation<api.ReconciliationPayloadResponse | undefined, { partnerId: number, payloadIdentifier: string }>({
      async queryFn({ partnerId, payloadIdentifier }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.deleteReconciliationPayload(partnerId, payloadIdentifier);
        return { data };
      },
      invalidatesTags: ['ReconciliationPayloads'],
    }),
    uploadReconciliationPayload: build.mutation<readonly api.ReconciliationPayloadAggregateResponse[], { body: FormData, partnerId: number }>({
      async queryFn({ body, partnerId }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.uploadReconciliationPayload(partnerId, body);
        return { data };
      },
      invalidatesTags: ['ReconciliationPayloads'],
    }),
    associateReconciliationPayloadWithCollection: build.mutation<readonly api.InvoiceCollectionAllocationResponse[], { partnerId: number, payloadIdentifier: string, collectionId: string }>({
      async queryFn({
        partnerId,
        payloadIdentifier,
        collectionId,
      }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi
          .associateReconciliationPayloadWithCollection(partnerId, payloadIdentifier, { collectionId });
        return { data };
      },
      invalidatesTags: ['Collections', 'ReconciliationPayloads', 'Invoices', 'Invoice', 'InvoiceAudit'],
    }),
    approveInvoice: build.mutation<api.InvoiceAggregateResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.approveInvoice<api.InvoiceAggregateResponse>(id);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit', 'InvoicesNeedingApproval']
        : ['Invoice', 'Invoices', 'InvoiceAudit', 'InvoicesNeedingApproval']),
    }),
    approveInvoiceLineItem: build.mutation<api.InvoiceLineItemResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.approveInvoiceLineItem<api.InvoiceLineItemResponse>(id);
        return { data };
      },
      invalidatesTags: ['InvoiceLineItem', 'InvoiceLineItems', 'InvoiceAudit', 'InvoicesNeedingApproval'],
    }),
    unapproveInvoice: build.mutation<api.InvoiceAggregateResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.unapproveInvoice<api.InvoiceAggregateResponse>(id);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit', 'InvoicesNeedingApproval']
        : ['Invoice', 'Invoices', 'InvoiceAudit', 'InvoicesNeedingApproval']),
    }),
    takeSuggestedDueDate: build.mutation<api.InvoiceAggregateResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.takeSuggestedDueDate<api.InvoiceAggregateResponse>(id, {});
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit']
        : ['Invoice', 'Invoices', 'InvoiceAudit']),
    }),
    cancelInvoice: build.mutation<api.InvoiceAggregateResponse | undefined, api.CancelInvoiceRequest & { id: string; }>({
      async queryFn({ id, ...body }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.cancelInvoice<api.InvoiceAggregateResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit']
        : ['Invoice', 'Invoices', 'InvoiceAudit']),
    }),
    requestInvoiceRedirect: build.mutation<api.InvoiceAggregateResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.requestInvoiceRedirect<api.InvoiceAggregateResponse>(id);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit']
        : ['Invoice', 'Invoices', 'InvoiceAudit']),
    }),
    getInvoiceAggregatePeriodStatistics: build.query<api.InvoicePeriodAggregateStatisticsResponse, api.InvoicePeriodAggregateStatisticsFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getInvoicePeriodAggregateStatistics<api.InvoicePeriodAggregateStatisticsResponse>(params);
          return { data };
        });
      },
    }),
    getInvoicePeriodStatisticsByVendor: build.query<api.IPagedResults<api.InvoicePeriodStatisticsByVendorResponse>, api.InvoicePeriodStatisticsByVendorFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getInvoicePeriodStatisticsByVendor<api.InvoicePeriodStatisticsByVendorResponse>(params);
          return { data };
        });
      },
    }),
    getInvoicePeriodRawData: build.query<api.IPagedResults<api.InvoicePeriodStatisticsRawDataResponse>, api.InvoicePeriodStatisticsRawDataFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getInvoicePeriodStatisticsRawData<api.InvoicePeriodStatisticsRawDataResponse>(params);
          return { data };
        });
      },
    }),
    getOriginationStatistics:
      build.query<api.RunningStatistic, api.VendorOriginationStatisticsFilterParams>({
        async queryFn(params) {
          return await StatisticsMutex.runExclusive(async () => {
            const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
            const data = await externalInvoiceApi.getVendorOriginationStatistics<api.RunningStatistic>(params);
            return { data };
          });
        },
      }),
    getGrowthStatistics:
      build.query<api.GrowthStatisticsResponse, api.GrowthStatisticsFilterParams>({
        async queryFn(params) {
          return await StatisticsMutex.runExclusive(async () => {
            const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
            const data = await externalInvoiceApi.getGrowthStatistics<api.GrowthStatisticsResponse>(params);
            return { data };
          });
        },
      }),
    getGrossProductVolumeRunRate:
      build.query<api.GrossProductVolumeRunRateResponse | undefined, api.GrossProductVolumeRunRateParams>({
        async queryFn(params) {
          return await StatisticsMutex.runExclusive(async () => {
            const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
            const data = await externalInvoiceApi.getGrossProductVolumeRunRate<api.GrossProductVolumeRunRateResponse>(params);
            return { data };
          });
        },
      }),
    getStateSummaryStatistics: build.query<api.InvoiceStateSummaryResponse, api.InvoiceStateSummaryFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getInvoiceStateSummary<api.InvoiceStateSummaryResponse>(params);
          return { data };
        });
      },
    }),
    getFloatPeriodYieldStatistics: build.query<api.IPagedResults<api.InvoiceFloatPeriodYieldStatisticsResponse>, api.InvoiceFloatPeriodYieldStatisticsFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getInvoiceFloatPeriodYieldStatistics<api.InvoiceFloatPeriodYieldStatisticsResponse>(params);
          return { data };
        });
      },
    }),
    getInvoicePricingStatistics: build.query<api.InvoicePricingStatisticsResponse, api.InvoicePricingStatisticsParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getInvoicePricingStatistics(params);
          return { data };
        });
      },
    }),
    // Invoice Line Items
    getAllInvoiceLineItems: build.query<api.IPagedResults<api.InvoiceLineItemResponse>, api.InvoiceLineItemFilterParams & api.GetInvoiceLineItemsBody>({
      async queryFn({ batchTransferIds, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllInvoiceLineItems<api.InvoiceLineItemResponse>({ batchTransferIds }, params);
        return { data };
      },
    }),
    getInvoiceLineItems: build.query<readonly api.InvoiceLineItemResponse[], { ids: readonly string[] }>({
      async queryFn({ ids }, _queryApi, _extraOptions, fetchWithBQ) {
        const responses = await Promise.all(ids.map((id) => fetchWithBQ({
          url: `invoice-line-items/${id}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })));
        return {
          data: responses.map((r) => r.data as api.InvoiceLineItemResponse),
        };
      },
    }),
    amendInvoiceDueDate: build.mutation<api.InvoiceAggregateResponse | undefined, { id: string, newDueDate: string }>({
      async queryFn({ id, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.amendInvoiceDueDate<api.InvoiceAggregateResponse>(id, params);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit']
        : ['Invoice', 'Invoices']),
    }),
    amendInvoiceDate: build.mutation<api.InvoiceAggregateResponse | undefined, { id: string, newInvoiceDate: string }>({
      async queryFn({ id, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.amendInvoiceDate<api.InvoiceAggregateResponse>(id, params);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit']
        : ['Invoice', 'Invoices']),
    }),
    amendEligibilityDate: build.mutation<api.InvoiceAggregateResponse | undefined, { id: string, newEligibilityDate: string }>({
      async queryFn({ id, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.amendEligibilityDate<api.InvoiceAggregateResponse>(id, params);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'Invoice', ...id, ...result }, 'Invoices', 'InvoiceAudit']
        : ['Invoice', 'Invoices']),
    }),
    uploadAmendEligibilityDate: build.mutation<void, { body: FormData }>({
      async queryFn({ body }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await (invoiceApi as any).post(`/invoices/amendEligibilityDate/upload`, body, {
          validateStatus: function (status: any) {
            return status >= 200 && status < 300; // default
          },
        });  // don't treat 404 as a success w/ undefined
        return { data };
      },
      invalidatesTags: ['Invoices', 'InvoiceLineItems'],
    }),
    amendLineItemDueDate: build.mutation<api.InvoiceLineItemResponse, { id: string, newDueDate: string }>({
      query(args) {
        return {
          url: `/invoice-line-items/${args.id}/amendDueDate`,
          method: 'POST',
          body: {
            newDueDate: args.newDueDate,
          },
        };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ ...id, ...result, type: 'InvoiceLineItem' }, 'Invoices', 'InvoiceLineItems', 'InvoiceAudit']
        : ['InvoiceLineItem', 'Invoices', 'InvoiceLineItems', 'InvoiceAudit']),
    }),
    cancelLineItem: build.mutation<api.InvoiceLineItemResponse | undefined, api.CancelInvoiceRequest & { id: string }>({
      async queryFn({ id, ...body }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.cancelInvoiceLineItem<api.InvoiceLineItemResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ ...id, ...result, type: 'InvoiceLineItem' }, 'Invoices', 'Invoice', 'InvoiceLineItems', 'InvoiceAudit']
        : ['InvoiceLineItem', 'Invoices', 'Invoice', 'InvoiceLineItems', 'InvoiceAudit']),
    }),
    amendLineItemEligibilityDate: build.mutation<api.MaybeRedactedInvoiceLineItemResponse | undefined, { id: string, newEligibilityDate: string }>({
      async queryFn({ id, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.amendLineItemEligibilityDate<api.MaybeRedactedInvoiceLineItemResponse>(id, params);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ ...id, ...result, type: 'InvoiceLineItem' }, 'Invoices', 'InvoiceLineItems', 'InvoiceAudit']
        : ['InvoiceLineItem', 'Invoices', 'InvoiceLineItems', 'InvoiceAudit']),
    }),
    getInvoiceLineItem: build.query<api.InvoiceLineItemResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getInvoiceLineItem<api.InvoiceLineItemResponse>(id);
        return { data };
      },
      providesTags: (result, error, id) => (result
        ? [{ ...id, ...result, type: 'InvoiceLineItem' }, 'Invoices', 'InvoiceLineItems', 'InvoiceAudit']
        : ['InvoiceLineItem', 'Invoices', 'InvoiceLineItems', 'InvoiceAudit']),
    }),
    // Invoice Collections
    getInvoiceCollections: build.query<readonly api.InvoiceCollectionAllocationResponse[], api.InvoiceCollectionFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllInvoiceCollectionAllocations(params);
        return { data };
      },
    }),
    getInvoicesForCollection: build.query<api.IPagedResults<api.InvoiceAggregateResponse>, { collectionId: api.UUID } & api.InvoicesForCollectionFilterParams>({
      async queryFn({ collectionId, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getInvoicesForCollection<api.InvoiceAggregateResponse>(collectionId, params);
        return { data };
      },
      providesTags: (result, error, { collectionId }) => (result
        ? [{ collectionId, ...result, type: 'CollectionInvoices' }]
        : ['CollectionInvoices']),
    }),
    // Adjustments
    createAdjustmentForVendor: build.mutation<api.InvoiceAdjustmentResponse, { vendorId: string } & api.CreateVendorAdjustmentRequest>({
      async queryFn({ vendorId, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.createAdjustmentForVendor(vendorId, request);
        return { data };
      },
      invalidatesTags: ['InvoiceAdjustments', 'InvoiceLineItem', 'InvoiceLineItems', 'Invoice', 'Invoices'],
    }),
    getInvoiceAdjustment:
      build.query<api.InvoiceAdjustmentResponse, { id: api.UUID }>({
        async queryFn({ id }) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getAdjustment(id);
          return { data };
        },
        providesTags: ['InvoiceAdjustments'],
      }),
    patchAdjustment: build.mutation<api.InvoiceAdjustmentResponse, { id: string } & api.UpdateAdjustmentLineItemRequest>({
      async queryFn({ id, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.updateAdjustmentTargetLineItem(id, request);
        return { data };
      },
      invalidatesTags: ['InvoiceAdjustments', 'InvoiceLineItem', 'InvoiceLineItems', 'Invoice', 'Invoices'],
    }),
    getInvoiceAdjustments:
      build.query<readonly api.InvoiceAdjustmentResponse[], { id: api.UUID }>({
        async queryFn({ id }) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getAdjustmentsForInvoice<api.InvoiceAdjustmentResponse>(id);
          return { data };
        },
        providesTags: ['InvoiceAdjustments'],
      }),
    getVendorAdjustmentBalance:
      build.query<api.InvoiceAdjustmentBalanceForVendorResponse, { vendorId: string } & api.IPageFilterParams>({
        async queryFn({ vendorId }) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getAdjustmentBalanceForVendor<api.InvoiceAdjustmentBalanceForVendorResponse>(vendorId);
          return { data };
        },
        providesTags: (result, error, id) => (result
          ? [{ type: 'InvoiceAdjustments', ...id, ...result }]
          : ['InvoiceAdjustments']),
      }),
    getAllAdjustmentGroups:
      build.query<api.IPagedResults<api.InvoiceAdjustmentGroupResponse>, api.InvoiceAdjustmentGroupFilterParams>({
        async queryFn(params) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getAllAdjustmentGroups(params);
          return { data };
        },
        providesTags: ['AdjustmentGroups'],
      }),
    getAdjustmentGroup:
      build.query<api.InvoiceAdjustmentGroupWithAdjustmentsResponse | undefined, { id: string }>({
        async queryFn({ id }) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getAdjustmentGroup(id);
          return { data };
        },
        providesTags: (result, error, params) => (result
          ? [{ type: 'AdjustmentGroups', ...params, ...result }]
          : ['AdjustmentGroups']),
      }),
    approveAdjustmentGroup:
      build.mutation<api.InvoiceAdjustmentGroupWithAdjustmentsResponse | undefined, { groupId: string }>({
        async queryFn({ groupId }) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.approveAdjustmentGroup(groupId);
          return { data };
        },
        invalidatesTags: (result, error, params) => (result
          ? [{ ...params, ...result, type: 'AdjustmentGroups' }]
          : ['AdjustmentGroups']),
      }),
    getVendorsAdjustmentBalances:
      build.query<api.IPagedResults<api.InvoiceAdjustmentNetBalanceResponse>, api.InvoiceAdjustmentsFilterParams>({
        async queryFn(params) {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getAdjustmentBalanceByVendor<api.InvoiceAdjustmentNetBalanceResponse>(params);
          return { data };
        },
      }),
    getCashflowProjections:
      build.query<api.CashFlowProjectionsResponse, api.GetCashFlowProjectionsFilterParams>({
        query({
          beginningAt,
          endingAt,
          periodization,
          startingBalance,
          includePendingApproval,
        }) {
          return {
            url: '/invoices/statistics/cashflowProjections',
            method: 'GET',
            params: {
              beginningAt,
              endingAt,
              periodization,
              startingBalance,
              includePendingApproval,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
      }),
    getTaskSchedules: build.query<Record<string, string>, any>({
      async queryFn() {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getTaskSchedules<Record<string, string>>();
        return { data };
      },
      providesTags: ['TaskSchedules'],
    }),
    getAllTaskExecutions: build.query<api.IPagedResults<api.InvoiceOperationsTaskExecutionResponse>, api.InvoiceOperationsTaskExecutionFilterParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getAllOperationsTaskExecutions(params);
        return { data };
      },
      providesTags: ['TaskExecutions'],
    }),
    createTaskExecution: build.mutation<api.InvoiceOperationsTaskExecutionResponse, api.InvoiceOperationsTaskExecutionRequest>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.createOperationsTaskExecution(params);
        return { data };
      },
      invalidatesTags: ['TaskExecutions'],
    }),
    uploadInvoices: build.mutation<void, { body: FormData }>({
      async queryFn({ body }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.uploadInvoices(body);
        return { data };
      },
      invalidatesTags: ['Invoices', 'InvoiceLineItems'],
    }),
    uploadInvoiceLineItemsReceivableOwner: build.mutation<void, { body: FormData }>({
      async queryFn({ body }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.updateInvoiceLineItemsReceivableOwner(body);
        return { data };
      },
      invalidatesTags: ['Invoices', 'InvoiceLineItems'],
    }),
    createDebtFacility: build.mutation<api.DebtFacilityResponse, api.DebtFacilityRequest>({
      async queryFn({ ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.createDebtFacility(request);
        return { data };
      },
      invalidatesTags: ['DebtFacilities'],
    }),
    deleteDebtFacility: build.mutation<void, { id: string }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.deleteDebtFacility(id);
        return { data };
      },
      invalidatesTags: ['DebtFacilities'],
    }),
    getDebtFacilities: build.query<readonly api.DebtFacilityResponse[], api.DebtFacilityFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllDebtFacilities(params);
        return { data };
      },
      providesTags: ['DebtFacilities'],
    }),
    getDebtFacilityCalendar: build.query<api.DebtFacilityCalendarResponse, api.DebtFacilityCalendarFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getDebtFacilityCalendar(params);
        return { data };
      },
      providesTags: ['DebtFacilities'],
    }),
    getInvoiceAudit: build.query<api.IPagedResults<api.InvoicesAuditResponse>, api.InvoiceAuditFilterParams & { id: string; }>({
      async queryFn({ id, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getInvoiceAudit(id, params);
        return { data };
      },
      providesTags: ['InvoiceAudit'],
    }),
    getCreditTierPortfolio: build.query<api.CreditTierPortfolioResponse, api.CreditTierPortfolioRequest>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
          const data = await externalInvoiceApi.getCreditTierPortfolio(params);
          return { data };
        });
      },
    }),
    // Clients
    createClient: build.mutation<api.ClientResponse, api.ClientRequest>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.createClient(params);
        return { data };
      },
      invalidatesTags: ['Clients'],
    }),
    getClient: build.query<api.ClientResponse | undefined, { id: string }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getClient(id);
        return { data };
      },
      providesTags: (result, error, id) => (result
        ? [{ type: 'Client', ...id, ...result }]
        : ['Client']),
    }),
    getAllClients: build.query<api.IPagedResults<api.ClientListResponse>, api.ClientFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllClients(params) as unknown as api.IPagedResults<api.ClientListResponse>;
        return { data };
      },
      providesTags: ['Clients'],
    }),
    updateClient: build.mutation<api.ClientResponse | undefined, api.UpdateClientRequest & { id: api.UUID }>({
      async queryFn({ id, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.updateClient(id, request);
        return { data };
      },
      invalidatesTags: (result, error, { id }) => (result
        ? [{ id, type: 'Client' }, 'Clients']
        : []),
    }),
    deleteClient: build.mutation<api.ClientResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.deleteClient(id);
        return { data };
      },
      invalidatesTags: (result, error, { id }) => (result
        ? [{ id, type: 'Client' }, 'Clients']
        : []),
    }),
    // Client Mappings
    uploadClientMappings: build.mutation<void, { body: FormData, partnerId: number }>({
      async queryFn({ body, partnerId }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.uploadExternalClients(partnerId, body);
        return { data };
      },
      invalidatesTags: ['ClientMappings', 'ClientMapping'],
    }),
    createClientMapping: build.mutation<api.ExternalClientResponse, api.ExternalClientRequest & { partnerId: number, externalClientId: string }>({
      async queryFn({ partnerId, externalClientId, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.createExternalClient(partnerId, externalClientId, request);
        return { data };
      },
      invalidatesTags: (result, error, { clientId }) => (clientId ? [{ type: 'Client', id: clientId }, 'ClientMappings'] : ['ClientMappings']),
    }),
    getClientMapping: build.query<api.ExternalClientResponse | undefined, { partnerId: number, externalClientId: string }>({
      async queryFn({ partnerId, externalClientId }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getExternalClient(partnerId, externalClientId);
        return { data };
      },
      providesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    getAllClientMappings: build.query<api.IPagedResults<api.ExternalClientResponse>, api.ExternalClientFilterParams>({
      async queryFn(params) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.getAllExternalClients(params);
        return { data };
      },
      providesTags: ['ClientMappings'],
    }),
    updateClientMapping: build.mutation<api.ExternalClientResponse | undefined, api.UpdateExternalClientRequest & { partnerId: number, externalClientId: string }>({
      async queryFn({ partnerId, externalClientId, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.updateExternalClient(partnerId, externalClientId, request);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => (result?.clientId ? [{ type: 'Client', id: result.clientId }, { type: 'ClientMapping', partnerId, externalClientId }] : [{ type: 'ClientMapping', partnerId, externalClientId }]),
    }),
    linkClientMapping: build.mutation<api.ExternalClientResponse | undefined, api.LinkExternalClientRequest & { partnerId: number, externalClientId: string }>({
      async queryFn({ partnerId, externalClientId, ...params }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.linkExternalClient(partnerId, externalClientId, params);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => (result
        ? [{ partnerId, externalClientId, type: 'ClientMapping' }, 'ClientMappings']
        : []),
    }),
    approveClientMapping: build.mutation<api.ExternalClientResponse | undefined, api.ApproveExternalClientRequest & { partnerId: number, externalClientId: string }>({
      async queryFn({ partnerId, externalClientId, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.approveExternalClient(partnerId, externalClientId, request);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    rejectClientMapping: build.mutation<api.ExternalClientResponse | undefined, api.RejectExternalClientRequest & { partnerId: number, externalClientId: string }>({
      async queryFn({ partnerId, externalClientId, ...request }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.rejectExternalClient(partnerId, externalClientId, request);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    createClientMappingNote: build.mutation<api.ExternalClientNoteResponse | undefined, api.ExternalClientNoteRequest & { partnerId: number, externalClientId: string }>({
      async queryFn({
        partnerId, externalClientId, ...request
      }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.createExternalClientNote(partnerId, externalClientId, request);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    deleteClientMappingNote: build.mutation<api.ExternalClientNoteResponse | undefined, { partnerId: number, externalClientId: string, noteId: string }>({
      async queryFn({ partnerId, externalClientId, noteId }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.deleteExternalClientNote(partnerId, externalClientId, noteId);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    uploadClientMappingOverrides: build.mutation<void, { body: FormData; partnerId: number; externalClientId: string }>({
      async queryFn({ body, partnerId, externalClientId }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.uploadExternalClientOverrides(partnerId, externalClientId, body);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    createClientMappingOverride: build.mutation<api.ExternalClientOverrideResponse | undefined, api.ExternalClientOverrideRequest & { partnerId: number, externalClientId: string, externalVendorId: string }>({
      async queryFn({
        partnerId, externalClientId, externalVendorId, ...request
      }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.createExternalClientOverride(partnerId, externalClientId, externalVendorId, request);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    deleteClientMappingOverride: build.mutation<api.ExternalClientOverrideResponse | undefined, { partnerId: number, externalClientId: string, externalVendorId: string }>({
      async queryFn({ partnerId, externalClientId, externalVendorId }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.deleteExternalClientOverride(partnerId, externalClientId, externalVendorId);
        return { data };
      },
      invalidatesTags: (result, error, { partnerId, externalClientId }) => [{ type: 'ClientMapping', partnerId, externalClientId }],
    }),
    //
    markBatchSweepPaid: build.mutation<api.BatchSweepResponse | undefined, api.MarkBatchSweepPaidRequest & { id: string }>({
      async queryFn({ id }) {
        const externalInvoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await externalInvoiceApi.markBatchSweepPaid(id);
        return { data };
      },
      invalidatesTags: (result, error, id) => (result
        ? [{ type: 'BatchSweep', ...id, ...result }]
        : ['BatchSweep']),
    }),
    getAllInvoiceDocuments: build.query<api.IPagedResults<api.InvoiceDocumentResponse>, { invoiceId: api.UUID } & api.IPageFilterParams>({
      async queryFn({ invoiceId, ...params }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getAllInvoiceDocuments<api.InvoiceDocumentResponse>(invoiceId, params);
        return { data };
      },
    }),
    getInvoiceDocument: build.query<api.InvoiceDocumentResponse | undefined, { invoiceId: api.UUID; documentId: api.UUID }>({
      async queryFn({ invoiceId, documentId }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceDocument<api.InvoiceDocumentResponse>(invoiceId, documentId);
        return { data };
      },
    }),
    markInvoiceDocumentVerified: build.mutation<api.InvoiceDocumentResponse | undefined, { invoiceId: api.UUID; documentId: api.UUID } & api.MarkInvoiceDocumentVerifiedRequest>({
      async queryFn({ invoiceId, documentId, ...request }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.markInvoiceDocumentVerified<api.InvoiceDocumentResponse>(invoiceId, documentId, request);
        return { data };
      },
    }),
    getAllConfigurationSettings: build.query<readonly api.InvoiceConfigurationSettingResponse[], void>({
      async queryFn() {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getAllConfigurationSettings();
        return { data };
      },
      providesTags: ['ConfigurationSettings'],
    }),
    updateConfigurationSetting: build.mutation<api.InvoiceConfigurationSettingResponse | undefined, { name: string; } & api.InvoiceConfigurationSettingRequest>({
      async queryFn({ name, ...request }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.updateConfigurationSetting(name, request);
        return { data };
      },
      invalidatesTags: ['ConfigurationSettings'],
    }),
    requestSqlQueryAnswer: build.mutation<void, api.SqlQuestionRequest>({
      async queryFn(request) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.requestSqlQueryAnswer(request);
        return { data };
      },
    }),
    recalculateInvoiceFees: build.mutation<void, api.RecalculateInvoiceFeesRequest>({
      async queryFn(request) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.recalculateInvoiceFees(request);
        return { data };
      },
    }),
    getAcceleratedPayoutStatistics: build.query<api.AcceleratedPayoutStatisticsResponse, api.AcceleratedPayoutStatisticsParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getAcceleratedPayoutStatistics(params);
        return { data };
      },
    }),
    getAcceleratedPayoutLookaheadBuckets: build.query<api.AcceleratedPayoutLookaheadBucketsResponse, any>({
      async queryFn() {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getAcceleratedPayoutLookaheadBuckets();
        return { data };
      },
    }),
    getEligibleInvoiceTotalsByVendor: build.query<api.IPagedResults<api.VendorEligibleInvoiceTotalsResponse>, api.EligibleInvoiceTotalsFilterParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getEligibleInvoiceTotalsByVendor(params);
        return { data };
      },
    }),
    getInvoiceApprovalReasonSummary: build.query<api.InvoiceApprovalReasonSummaryResponse, api.InvoiceApprovalReasonSummaryFilterParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceApprovalReasonSummary(params);
        return { data };
      },
      providesTags: ['InvoicesNeedingApproval'],
    }),
    getAllClientVendorAnomalies: build.query<api.IPagedResults<api.ClientVendorAnomalyResponse>, api.ClientVendorAnomalyFilterParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getAllClientVendorAnomalies(params);
        return { data };
      },
      providesTags: ['ClientVendorAnomalies'],
    }),
    getClientVendorAnomaly: build.query<api.ClientVendorAnomalyAggregateResponse, { id: api.UUID } & api.IPageFilterParams>({
      async queryFn({ id, ...params }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getClientVendorAnomaly(id, params);
        return { data };
      },
      providesTags: (result, error, { id }) => [{ type: 'ClientVendorAnomalies', id }],
    }),
    getInvoiceRevenueSeries: build.query<api.InvoiceRevenueSeriesResponse, api.InvoiceRevenueSeriesParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceRevenueSeries(params);
        return { data };
      }
    }),
    getInvoiceRevenueByVendor: build.query<api.IPagedResults<api.InvoiceRevenueByBusinessEntity>, api.InvoiceRevenueByVendorParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceRevenueByVendor(params);
        return { data };
      }
    }),
    getInvoiceRevenueByClient: build.query<api.IPagedResults<api.InvoiceRevenueByBusinessEntity>, api.InvoiceRevenueByClientParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceRevenueByClient(params);
        return { data };
      }
    }),
    getInvoiceCohortsSeries: build.query<api.InvoiceCohortsSeriesResponse, api.InvoiceCohortsSeriesParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceCohortsSeries(params);
        return { data };
      }
    }),
    getInvoiceCohortVendorsSeries: build.query<api.IPagedResults<api.InvoiceCohortVendorSeriesResponse>, { cohort: api.ISODateString } & api.InvoiceCohortVendorsSeriesParams>({
      async queryFn({ cohort, ...params }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getInvoiceCohortVendorsSeries(cohort, params);
        return { data };
      }
    }),
    uploadVendorClientRestrictions: build.mutation<void, { body: FormData, partnerId: api.Partners }>({
      async queryFn({ body, partnerId, }) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.uploadVendorClientRestrictions(partnerId, body);
        return { data };
      },
    }),
    getPartnerIntegrationMetadata: build.query<api.PartnerIntegrationMetadataResponse, api.PartnerIntegrationMetadataDataParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getPartnerIntegrationMetadata(params);
        return { data };
      },
    }),
    getPartnerIntraPeriodInvoiceSeries: build.query<api.PartnerPeriodicInvoiceImportSummaryResponse, api.PartnerPeriodicInvoiceImportSummaryParams>({
      async queryFn(params) {
        const invoiceApi = useApi((options) => new InvoiceApi(CONFIG.api.invoiceUrl, options));
        const data = await invoiceApi.getPartnerIntraPeriodInvoiceSeries(params);
        return { data };
      },
    })
  }),
});

export const {
  useGetInvoiceQuery,
  useGetAllInvoicesQuery,
  useLazyGetAllInvoicesQuery,
  useUploadReconciliationPayloadMutation,
  useGetAllReconciliationPayloadsQuery,
  useGetReconciliationPayloadAggregateQuery,
  useAssociateReconciliationPayloadWithCollectionMutation,
  useApproveInvoiceMutation,
  useApproveInvoiceLineItemMutation,
  useUnapproveInvoiceMutation,
  useCancelInvoiceMutation,
  useRequestInvoiceRedirectMutation,
  useGetAllInvoiceLineItemsQuery,
  useGetInvoiceLineItemQuery,
  useGetInvoiceAggregatePeriodStatisticsQuery,
  useLazyGetInvoiceAggregatePeriodStatisticsQuery,
  useGetInvoicePeriodStatisticsByVendorQuery,
  useLazyGetInvoicePeriodStatisticsByVendorQuery,
  useGetStateSummaryStatisticsQuery,
  useLazyGetStateSummaryStatisticsQuery,
  useGetFloatPeriodYieldStatisticsQuery,
  useLazyGetFloatPeriodYieldStatisticsQuery,
  useGetInvoiceCollectionsQuery,
  useGetInvoicesForCollectionQuery,
  useCreateAdjustmentForVendorMutation,
  useGetInvoiceAdjustmentQuery,
  useGetInvoiceAdjustmentsQuery,
  usePatchAdjustmentMutation,
  useGetVendorsAdjustmentBalancesQuery,
  useGetCashflowProjectionsQuery,
  useLazyGetCashflowProjectionsQuery,
  useGetTaskSchedulesQuery,
  useGetAllTaskExecutionsQuery,
  useCreateTaskExecutionMutation,
  useAmendInvoiceDueDateMutation,
  useAmendInvoiceDateMutation,
  useAmendLineItemDueDateMutation,
  useGetOriginationStatisticsQuery,
  useLazyGetOriginationStatisticsQuery,
  useGetGrowthStatisticsQuery,
  useLazyGetGrowthStatisticsQuery,
  useGetInvoicePricingStatisticsQuery,
  useLazyGetInvoicePricingStatisticsQuery,
  useGetGrossProductVolumeRunRateQuery,
  useLazyGetGrossProductVolumeRunRateQuery,
  useUploadInvoicesMutation,
  useUploadInvoiceLineItemsReceivableOwnerMutation,
  useCreateDebtFacilityMutation,
  useDeleteDebtFacilityMutation,
  useGetDebtFacilitiesQuery,
  useLazyGetDebtFacilitiesQuery,
  useLazyGetDebtFacilityCalendarQuery,
  useGetDebtFacilityCalendarQuery,
  useGetInvoiceAuditQuery,
  useTakeSuggestedDueDateMutation,
  useGetCreditTierPortfolioQuery,
  useLazyGetCreditTierPortfolioQuery,
  useGetAllAdjustmentGroupsQuery,
  useGetAdjustmentGroupQuery,
  useApproveAdjustmentGroupMutation,
  useCreateClientMutation,
  useGetClientQuery,
  useGetAllClientsQuery,
  useLazyGetAllClientsQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useUploadClientMappingsMutation,
  useCreateClientMappingNoteMutation,
  useDeleteClientMappingNoteMutation,
  useCreateClientMappingMutation,
  useGetClientMappingQuery,
  useGetAllClientMappingsQuery,
  useUpdateClientMappingMutation,
  useLinkClientMappingMutation,
  useApproveClientMappingMutation,
  useRejectClientMappingMutation,
  useUploadClientMappingOverridesMutation,
  useCreateClientMappingOverrideMutation,
  useDeleteClientMappingOverrideMutation,
  useGetAllBatchSweepsQuery,
  useGetBatchSweepQuery,
  useGetRepaymentItemsForBatchSweepQuery,
  useCombineReconcilationPayloadsMutation,
  useMarkBatchSweepPaidMutation,
  useAmendEligibilityDateMutation,
  useUploadAmendEligibilityDateMutation,
  useAmendLineItemEligibilityDateMutation,
  useGetAllInvoiceDocumentsQuery,
  useGetInvoiceDocumentQuery,
  useMarkInvoiceDocumentVerifiedMutation,
  useGetAllConfigurationSettingsQuery,
  useUpdateConfigurationSettingMutation,
  useCancelLineItemMutation,
  useRequestSqlQueryAnswerMutation,
  useRecalculateInvoiceFeesMutation,
  useGetAcceleratedPayoutStatisticsQuery,
  useGetEligibleInvoiceTotalsByVendorQuery,
  useGetInvoiceApprovalReasonSummaryQuery,
  useGetAllClientVendorAnomaliesQuery,
  useGetClientVendorAnomalyQuery,
  useGetInvoiceRevenueSeriesQuery,
  useGetInvoiceRevenueByVendorQuery,
  useGetInvoiceRevenueByClientQuery,
  useGetInvoiceCohortsSeriesQuery,
  useLazyGetInvoiceCohortsSeriesQuery,
  useGetInvoiceCohortVendorsSeriesQuery,
  useLazyGetInvoiceCohortVendorsSeriesQuery,
  useGetAcceleratedPayoutLookaheadBucketsQuery,
  useUploadVendorClientRestrictionsMutation,
  useDeleteReconciliationPayloadMutation,
  useGetPartnerIntegrationMetadataQuery,
  useGetPartnerIntraPeriodInvoiceSeriesQuery,
} = invoicesApi;

export const exportAllInvoices = async (filterParams?: InvoiceFilterParams, excludeStates?: string[], csvFormat = CsvFormat.MESA_CSV_FORMAT, exportFormat: 'vendor' | 'operator' = 'operator'): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.invoiceUrl}/invoices/csv/export`);
  appendFilterParams(url, filterParams);

  (excludeStates || []).forEach((state) => {
    url.searchParams.append('excludeStates', state);
  });

  url.searchParams.append('csvFormat', csvFormat);

  url.searchParams.append('exportFormat', exportFormat);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const exportFromInvoiceService = async (path: string, method: string, filterParams?: Record<string, any>, exportFormat: 'vendor' | 'operator' | undefined = 'operator'): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.invoiceUrl}${path}`);
  appendFilterParams(url, filterParams);

  if (exportFormat) {
    url.searchParams.append('exportFormat', exportFormat);
  }

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    method,
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const getClientMappingsExportCsv = async (params: api.ExternalClientFilterParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/external-clients/csv/export`, params);

export const downloadInvoiceDocument = async (invoiceId: api.UUID, documentId: api.UUID): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.invoiceUrl}/invoices/${invoiceId}/documents/${documentId}/download`);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  if (response.status !== 200) {
    const error = await response.json();
    throw new Error(error?.message || response.statusText);
  }

  return await response.blob();
};

export const exportAllAdjustmentsOriginatingBySubType = async (adjustmentSubType: api.AdjustmentCredit | api.AdjustmentDebit, createdBeginningAt?: api.ISODateString, createdEndingAt?: api.ISODateString): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.invoiceUrl}/invoice-adjustments/originating-by-subtype/csv/export`);

  url.searchParams.append('adjustmentSubType', adjustmentSubType);
  if (createdBeginningAt) {
    url.searchParams.append('createdBeginningAt', createdBeginningAt);
  }
  if (createdEndingAt) {
    url.searchParams.append('createdEndingAt', createdEndingAt);
  }

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const exportAllAdjustmentsBySubType = async (adjustmentSubType: api.AdjustmentCredit | api.AdjustmentDebit): Promise<BlobPart> => {

  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.invoiceUrl}/invoice-adjustments/export/csv`);

  url.searchParams.append('adjustmentSubType', adjustmentSubType);
  url.searchParams.append('page', '1');
  url.searchParams.append('limit', Number.MAX_SAFE_INTEGER.toString());

  const response = await fetch(url.href, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const getCsv = async (
  endpointUrl: string,
  requestParams: any,
): Promise<BlobPart> => {
  const idToken = await cognitoService.getIdToken();
  const url = new URL(endpointUrl);
  appendFilterParams(url, requestParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const getInvoiceRevenueByVendorExportCsv = async (params: api.InvoiceRevenueByVendorParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/revenue/by-vendor/export/csv`, params);
export const getInvoiceRevenueByClientExportCsv = async (params: api.InvoiceRevenueByClientParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/revenue/by-client/export/csv`, params);

export const getInvoiceCohortsSeriesExportCsv = async (params: api.InvoiceCohortsSeriesParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/cohorts/export/csv`, params);
export const getInvoiceCohortVendorsSeriesExportCsv = async (cohort: api.ISODateString, params: api.InvoiceCohortVendorsSeriesParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/cohorts/${cohort}/vendors/export/csv`, params);
export const getPeriodStatisticsByVendorCsvExport = async (params: api.InvoicePeriodStatisticsByVendorFilterParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/period/byVendor/export/csv`, params);
export const getPeriodStatisticsRawDataCsvExport = async (params: api.InvoicePeriodStatisticsRawDataFilterParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/period/rawData/export/csv`, params);
export const getPeriodYieldStatisticsExportCsv = async (params: api.InvoiceFloatPeriodYieldStatisticsFilterParams): Promise<BlobPart> => await getCsv(`${CONFIG.api.invoiceUrl}/invoices/statistics/yield/export/csv`, params);

