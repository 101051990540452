import CognitoBrowserService from '@mesa-labs/mesa-ui/dist/utils/cognito-browser';

export default new CognitoBrowserService({
  UserPoolId: CONFIG.cognito.userPoolId,
  ClientId: CONFIG.cognito.userPoolWebClientId,
  AppWebDomain: CONFIG.cognito.oauth.domain,
  RedirectUriSignIn: CONFIG.cognito.oauth.redirectSignIn,
  RedirectUriSignOut: CONFIG.cognito.oauth.redirectSignOut,
  IdentityProvider: CONFIG.cognito.oauth.identityProvider,
});
