import { types as api } from '@mesa-labs/mesa-api';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  CardContainer, ResourceCard, ResourceSection, Table,
} from '@mesa-labs/mesa-ui';
import { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';
import { ColumnProp, ColumnType } from '@mesa-labs/mesa-ui/dist/components/Table';

import {
  useGetClientVendorAnomalyQuery,
} from '../../redux/api/invoices';
import { getAnomalyDescription } from './Anomalies';
import { kebabCaseToProperCase } from '../../utils';

const AnomalyPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const anomalyItems: (string | ResourceItemProp)[] = [
  { key: 'partnerName', label: 'Partner' },
  { key: 'clientName', label: 'Client', type: 'link' },
  { key: 'vendorName', label: 'Vendor', type: 'link' },
  { key: 'anomalyType', label: 'type' },

  { key: 'createdAt', label: 'Detected At', type: 'datetime' },
  { key: 'invoiceNumber', label: 'Invoice Number', type: 'link' },
  { key: 'invoiceAmount', label: 'Invoice Amount', type: 'currency', width: '50%' },

  { key: 'description', label: 'Description', width: '100%' },
];

const getAnomalyValueColumnLabel = (anomaly: api.ClientVendorAnomalyResponse | undefined): string => {
  switch (anomaly?.type) {
    case api.ClientVendorAnomalyType.LedgerLateness:
      return 'Ledger Lateness';
    case api.ClientVendorAnomalyType.ImportLateness:
      return 'Import Lateness';
    case api.ClientVendorAnomalyType.DaysSinceLastInvoice:
      return 'Days Since Last Invoice';
    default:
      return 'Invoice Amount';
  }
}

const getAnomalyValueColumnType = (anomaly: api.ClientVendorAnomalyResponse | undefined): ColumnType => {
  switch (anomaly?.type) {
    case api.ClientVendorAnomalyType.LedgerLateness:
    case api.ClientVendorAnomalyType.ImportLateness:
    case api.ClientVendorAnomalyType.DaysSinceLastInvoice:
      return 'text';
    default:
      return 'currency';
  }
}

function AnomalyPage(): React.ReactElement | null {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: anomaly,
  } = useGetClientVendorAnomalyQuery({
    id: id!,
    page: 1,
    limit: 25
  });

  const columns: ColumnProp[] = useMemo(() => {
    return [
      {
        key: 'invoiceNumber',
        label: 'Invoice Number',
      },
      {
        key: 'invoiceDate',
        label: 'Invoice Date',
        type: 'date'
      },
      {
        key: 'generalLedgerDate',
        label: 'General Ledger Date Date',
        type: 'date'
      },
      {
        key: 'createdAt',
        label: 'Import Date',
        type: 'date'
      },
      {
        key: 'totalOpenAmount',
        label: 'Invoice Amount',
        type: 'currency',
      },
      ...(anomaly?.type !== api.ClientVendorAnomalyType.TotalOpenAmount ?
        [{
          key: 'value',
          label: getAnomalyValueColumnLabel(anomaly),
          type: getAnomalyValueColumnType(anomaly),
        }] : []),
      {
        key: 'zscoreValue',
        label: `SDs for ${getAnomalyValueColumnLabel(anomaly)}`
      },
    ];
  }, [anomaly]);

  const rows = useMemo(() => (anomaly?.invoices.data || []).map((invoice) => ({
    ...invoice
  })), [anomaly]);

  const vendorId = useMemo(() => {
    return anomaly?.vendorId || (anomaly?.invoices.data || []).find(i => !!i.vendorId)?.vendorId;
  }, [anomaly]);

  return (
    <AnomalyPageContainer>
      <CardContainer>
        <ResourceCard
          resource={anomaly ? {
            ...anomaly,
            partnerName: api.getPartnerName(anomaly.partnerId),
            clientName: anomaly.clientName || anomaly.externalClientId,
            clientNameLocation: `client-mappings/partners/${anomaly.partnerId}/external-clients/${anomaly.externalClientId}`,
            vendorName: anomaly.vendorName || anomaly.externalVendorId,
            vendorNameLocation: vendorId ? `vendors/${vendorId}` : undefined,
            anomalyType: kebabCaseToProperCase(anomaly.type),
            invoiceNumberLocation: anomaly.invoiceId ? `invoices/${anomaly.invoiceId}` : undefined,
            description: getAnomalyDescription(anomaly)
          } : {}}
          title={anomaly?.id || ''}
          items={anomalyItems}
          columns={4}
        />
      </CardContainer>

      <ResourceSection
        title="Related Invoices"
      >
        <Table
          columns={columns}
          rows={rows}
          onRowClick={(row: Record<string, unknown>) => navigate(`/invoices/${row.id}`)}
        />
      </ResourceSection>
    </AnomalyPageContainer>
  );
}

export default AnomalyPage;
