import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  ButtonDropdown,
  DateRangeSelector,
  PaginationFooter, Table,
  formatCurrency,
} from '@mesa-labs/mesa-ui';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';
import { DateTime } from 'luxon';
import { ClientVendorAnomalyResponse, ClientVendorAnomalyType, CurrencyCode, SortDirection, getPartnerName } from '@mesa-labs/mesa-api/dist/types';

import { useGetAllClientVendorAnomaliesQuery } from '../../redux/api/invoices';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateAnomalyType, updateCreatedBeginningAt, updateCreatedEndingAt, updateLimit, updatePage, updateTotalPages, initialState as AnomaliesInitialState, updateSortField, updateSortDirection,
  updatePartnerId
} from '../../redux/slices/anomalies';
import { DefaultPaginationLimits } from '../common/PaginationLimits';
import { FilterCell, FilterRow, FilterSection, FilterTitle } from '../common/Filters';
import Select from '../common/Select';
import { kebabCaseToProperCase } from '../../utils';
import PartnerSelect from '../common/PartnerSelect';
import { useNavigate } from 'react-router-dom';
import useSyncQueryStringParamsToRedux from '../../hooks/useSyncQueryStringParamsToRedux';

const AnomalyTypeOptions = [
  {
    label: '(No Selection)',
    value: undefined
  },
  ...(Object.values(ClientVendorAnomalyType).map((v) => ({
    label: kebabCaseToProperCase(v),
    value: v
  })))
];

const AnomalyColumns: ColumnProp[] = [
  {
    key: 'partnerName',
    label: 'Partner',
    sortField: 'partnerId',
    width: '8%',
  },
  {
    key: 'clientName',
    label: 'Client',
    sortField: 'clientName',
    width: '10%',
  },
  {
    key: 'vendorName',
    label: 'Vendor',
    sortField: 'vendorName',
    width: '10%'
  },
  {
    key: 'anomalyType',
    label: 'Anomaly Type',
    sortField: 'type',
    width: '10%',
  },
  {
    key: 'createdAt',
    label: 'Detected At',
    sortField: 'createdAt',
    width: '10%',
    type: 'datetime'
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice Number',
    sortField: 'invoiceNumber',
    width: '8%',
  },
  {
    key: 'invoiceAmount',
    label: 'Invoice Amount',
    sortField: 'invoiceAmount',
    width: '8%',
    type: 'currency',
  },
  {
    key: 'description',
    label: 'Description',
    width: '36%',
  },
];

const AnomaliesPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateRangeSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  gap: 8px;
`;

export const getAnomalyDescription = (anomaly: ClientVendorAnomalyResponse): string => {
  switch (anomaly.type) {
    case ClientVendorAnomalyType.TotalOpenAmount:
      return `Total open amount of ${formatCurrency(anomaly.value, anomaly.currencyCode || CurrencyCode.USD)} is ${anomaly.zscoreValue.toFixed(1)} SDs (${formatCurrency(anomaly.stddevValue, anomaly.currencyCode || CurrencyCode.USD)}) ${anomaly.zscoreValue < 0 ? 'below' : 'above'} the mean value of ${formatCurrency(anomaly.meanValue, anomaly.currencyCode || CurrencyCode.USD)}`;
    case ClientVendorAnomalyType.LedgerLateness:
      return `Ledger lateness of ${anomaly.value.toFixed(1)} is ${anomaly.zscoreValue.toFixed(1)} SDs (${anomaly.stddevValue.toFixed(1)}) ${anomaly.zscoreValue < 0 ? 'below' : 'above'} the mean value of ${anomaly.meanValue.toFixed(1)}`;
    case ClientVendorAnomalyType.ImportLateness:
      return `Import lateness of ${anomaly.value.toFixed(1)} is ${anomaly.zscoreValue.toFixed(1)} SDs (${anomaly.stddevValue.toFixed(1)}) ${anomaly.zscoreValue < 0 ? 'below' : 'above'} the mean value of ${anomaly.meanValue.toFixed(1)}`;
    case ClientVendorAnomalyType.DaysSinceLastInvoice:
      return `Days since last invoice of ${anomaly.value.toFixed(1)} is ${anomaly.zscoreValue.toFixed(1)} SDs (${anomaly.stddevValue.toFixed(1)}) ${anomaly.zscoreValue < 0 ? 'below' : 'above'} the mean value of ${anomaly.meanValue.toFixed(1)}`;
  }
}

function AnomaliesPage(): React.ReactElement | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anomalyType = useSelector((state) => state.anomalies.anomalyType);
  const partnerId = useSelector((state) => state.anomalies.partnerId);
  const createdBeginningAt = useSelector((state) => state.anomalies.createdBeginningAt);
  const createdEndingAt = useSelector((state) => state.anomalies.createdEndingAt);
  const page = useSelector((state) => state.anomalies.page) || AnomaliesInitialState.page!;
  const totalPages = useSelector((state) => state.anomalies.totalPages);
  const limit = useSelector((state) => state.anomalies.limit) || AnomaliesInitialState.limit!;
  const sortField = useSelector((state) => state.anomalies.sortField);
  const sortDirection = useSelector((state) => state.anomalies.sortDirection);

  useSyncQueryStringParamsToRedux({ sliceName: 'anomalies' });

  const appliedFilters = useMemo(() => [anomalyType, partnerId, (createdBeginningAt || createdEndingAt)].reduce((acc: number, curr) => acc + (curr !== undefined ? 1 : 0), 0), [anomalyType, partnerId, createdBeginningAt, createdEndingAt]);

  const createdDateRangeLabel = useMemo(() => {
    if (!!createdBeginningAt && !!createdEndingAt) {
      const start = new Date(createdBeginningAt);
      const end = new Date(createdEndingAt);

      return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
    }

    return 'Select Detection Date Range';
  }, [createdBeginningAt, createdEndingAt]);

  const {
    data: {
      data: anomalies = [],
      total: totalAnomalies,
    } = {},
  } = useGetAllClientVendorAnomaliesQuery({
    partnerId,
    type: anomalyType,
    createdBeginningAt,
    createdEndingAt,
    sortField,
    sortDirection,
    page,
    limit: limit.value,
  });

  useEffect(() => {
    if (totalAnomalies !== undefined) {
      dispatch(updateTotalPages(Math.ceil(totalAnomalies / limit.value)));
    }
  }, [limit, totalAnomalies]);

  useEffect(() => {
    if (totalPages === undefined && page !== 1) {
      dispatch(updatePage(1));
    }
  }, []);

  const rows = useMemo(() => {
    return anomalies.map((a) => ({
      ...a,
      clientName: a.clientName || a.externalClientId,
      vendorName: a.vendorName || a.externalVendorId,
      partnerName: getPartnerName(a.partnerId),
      anomalyType: kebabCaseToProperCase(a.type),
      description: getAnomalyDescription(a)
    }))
  }, [anomalies]);

  return (
    <AnomaliesPageContainer>
      <FilterSection
        filterHeader="&nbsp;"
        appliedFilters={appliedFilters}
      >
        <FilterRow>
          <FilterCell>
            <FilterTitle>Partner</FilterTitle>
            <PartnerSelect
              selectedPartner={partnerId}
              onSelectedPartnerChange={(p) => dispatch(updatePartnerId(p))}
              allowNoPartner
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Anomaly Type</FilterTitle>
            <Select
              options={AnomalyTypeOptions}
              value={AnomalyTypeOptions.find(o => o.value === anomalyType) || AnomalyTypeOptions[0]}
              onChange={(o) => dispatch(updateAnomalyType(o.value))}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Detection Date Range</FilterTitle>
            <DateRangeSelectorContainer>
              <DateRangeSelector
                customKey="client-vendor-anomalies-created-date-range-selector"
                label={createdDateRangeLabel}
                iconSrc="/assets/calendar-icon.svg"
                direction="left"
                startDate={createdBeginningAt ? new Date(createdBeginningAt) : undefined}
                endDate={createdEndingAt ? new Date(createdEndingAt) : undefined}
                onChange={(update) => {
                  dispatch(updateCreatedBeginningAt(DateTime.fromJSDate(update.startDate!).isValid ? update.startDate?.toISOString() : undefined));
                  dispatch(updateCreatedEndingAt(DateTime.fromJSDate(update.endDate!).isValid ? update.endDate?.toISOString() : undefined));
                }}
                showClear={!!createdBeginningAt || !!createdEndingAt}
                onClear={() => {
                  dispatch(updateCreatedBeginningAt(undefined));
                  dispatch(updateCreatedEndingAt(undefined));
                }}
              />
              <ButtonDropdown
                buttonLabel='Relative Dates'
                items={[
                  {
                    label: 'Today', onClick: () => {
                      dispatch(updateCreatedBeginningAt(DateTime.now().startOf('day').toISO()));
                      dispatch(updateCreatedEndingAt(DateTime.now().endOf('day').toISO()));
                    }
                  },
                  {
                    label: 'Last 3 Days', onClick: () => {
                      dispatch(updateCreatedBeginningAt(DateTime.now().startOf('day').minus({ days: 2 }).toISO()));
                      dispatch(updateCreatedEndingAt(DateTime.now().endOf('day').toISO()));
                    }
                  },
                  {
                    label: 'Last 7 Days', onClick: () => {
                      dispatch(updateCreatedBeginningAt(DateTime.now().startOf('day').minus({ days: 6 }).toISO()));
                      dispatch(updateCreatedEndingAt(DateTime.now().endOf('day').toISO()));
                    }
                  }
                ]}
              />

            </DateRangeSelectorContainer>
          </FilterCell>
        </FilterRow>
      </FilterSection>

      <Table
        columns={AnomalyColumns}
        rows={rows}
        currentSortColumn={sortField}
        currentSortDirection={sortDirection}
        setSortField={(field: string) => dispatch(updateSortField(field))}
        setSortDirection={(direction: SortDirection) => dispatch(updateSortDirection(direction))}
        onRowClick={(row: Record<string, unknown>) => navigate(`/anomalies/${row.id}`)}
        paginationComponent={(
          <PaginationFooter
            limits={DefaultPaginationLimits}
            selectedLimit={limit}
            onChange={(item) => {
              dispatch(updateLimit(item));
              dispatch(updatePage(1));
            }}
            currentPage={page}
            totalPages={totalPages || 1}
            onPrev={() => dispatch(updatePage(page - 1))}
            onNext={() => dispatch(updatePage(page + 1))}
          />
        )}
      />
    </AnomaliesPageContainer>
  );
}

export default AnomaliesPage;
