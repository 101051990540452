import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EligibleInvoicesState = {
  eligibleInvoiceType?: api.EligibleInvoiceType;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
};

export const initialState: EligibleInvoicesState = {
  eligibleInvoiceType: undefined,
  page: 1,
  totalPages: undefined,
  limit: { key: '100', value: 100 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const eligibleInvoicesSlice = createSlice({
  name: 'eligibleInvoices',
  initialState,
  reducers: {
    updateEligibleInvoiceType(state: EligibleInvoicesState, action: PayloadAction<api.EligibleInvoiceType | undefined>) {
      state.eligibleInvoiceType = action.payload;
    },
    updatePage(state: EligibleInvoicesState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: EligibleInvoicesState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: EligibleInvoicesState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: EligibleInvoicesState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: EligibleInvoicesState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updateEligibleInvoiceType,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
} = eligibleInvoicesSlice.actions;
export default eligibleInvoicesSlice.reducer;
