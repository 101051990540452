import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type ExternalClientVendorsState = {
  searchTerm?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  externalVendorsPage?: number;
  externalVendorsTotalPages?: number;
  externalVendorsLimit?: PaginationDropdownLimit;
  externalVendorsSortField?: string;
  externalVendorsSortDirection?: api.SortDirection;
};

const initialState: ExternalClientVendorsState = {
  searchTerm: '',
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  externalVendorsPage: 1,
  externalVendorsTotalPages: undefined,
  externalVendorsLimit: { key: '25', value: 25 },
  externalVendorsSortField: 'totalInvoiceSpend',
  externalVendorsSortDirection: api.SortDirection.DESCENDING,
};

const externalVendorClientsSlice = createSlice({
  name: 'externalVendorClients',
  initialState,
  reducers: {
    updateSearchTerm(state: ExternalClientVendorsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updateExternalClientVendorsPage(state: ExternalClientVendorsState, action: PayloadAction<number>) {
      state.externalVendorsPage = action.payload;
    },
    updateExternalClientVendorsTotalPages(state: ExternalClientVendorsState, action: PayloadAction<number>) {
      state.externalVendorsTotalPages = action.payload;
    },
    updateExternalClientVendorsLimit(state: ExternalClientVendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.externalVendorsLimit = action.payload;
    },
    updateExternalClientVendorsSortField(state: ExternalClientVendorsState, action: PayloadAction<string>) {
      state.externalVendorsSortField = action.payload;
    },
    updateExternalClientVendorsSortDirection(state: ExternalClientVendorsState, action: PayloadAction<api.SortDirection>) {
      state.externalVendorsSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updateExternalClientVendorsPage,
  updateExternalClientVendorsTotalPages,
  updateExternalClientVendorsLimit,
  updateExternalClientVendorsSortField,
  updateExternalClientVendorsSortDirection,
} = externalVendorClientsSlice.actions;

export default externalVendorClientsSlice.reducer;
