import { types as api, types, VendorApi } from '@mesa-labs/mesa-api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  getIdToken,
  prepareHeaders,
  StatisticsMutex,
  useApi,
} from './api';
import { ProgramCodes } from '@mesa-labs/mesa-api/dist/types';
import { appendFilterParams } from '../../utils';

export const vendorsApi = createApi({
  reducerPath: 'vendorsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.vendorUrl,
    prepareHeaders,
  }),
  tagTypes: ['Vendors', 'Vendor', 'Vendors In Enablement', 'Vendor Documents', 'Vendor Notes', 'Vendor Audit',
    'Program', 'Programs', 'User Accounts', 'Onboardings', 'Onboarding', 'Third Party Business Verifications',
    'Facilitators', 'FacilitatorTotals', 'Vendor Service Agreements', 'Vendor Partner Service Agreements',
    'TaskSchedules', 'TaskExecutions', 'Vendor Feedback'
  ],
  endpoints: (build) => ({
    getAllVendors:
      build.query<api.IPagedResults<api.VendorResponse>, api.VendorFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getAllVendors<api.VendorResponse>(params);
          return { data };
        },
        providesTags: ['Vendors'],
      }),
    getVendor: build.query<api.VendorAggregateResponse | undefined, { id: string }>({
      async queryFn({ id }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendor<api.VendorAggregateResponse>(id);
        return { data };
      },
      providesTags: (result, error, id) => (result
        ? [{ type: 'Vendor', ...id, ...result }]
        : ['Vendor']),
    }),
    getAllVendorsInEnablement:
      build.query<api.IPagedResults<api.VendorResponse>, api.VendorFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getAllVendors<api.VendorResponse>(params);
          return { data };
        },
        providesTags: ['Vendors In Enablement'],
      }),
    impersonateVendor: build.mutation<void, { id: string }>({
      async queryFn({ id }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.impersonateVendor(id);
        return { data };
      },
    }),
    endVendorImpersonation: build.mutation<void, { id: string }>({
      async queryFn({ id }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.endVendorImpersonation(id);
        return { data };
      },
    }),
    updateVendor: build.mutation<api.VendorResponse | undefined, api.UpdateVendorRequest & { id: string }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.updateVendor<api.VendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit', 'Vendors In Enablement'],
    }),
    patchVendor: build.mutation<api.VendorResponse | undefined, api.PatchVendorRequest & { id: string }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.patchVendor<api.VendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit', 'Vendors In Enablement'],
    }),
    assignVendor: build.mutation<api.VendorResponse | undefined, api.AssignVendorRequest & { id: types.UUID }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.assignVendor<api.VendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit', 'Vendors In Enablement'],
    }),
    updateVendorAssignments: build.mutation<api.VendorResponse | undefined, api.UpdateVendorAssignmentsRequest & { id: types.UUID }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.updateVendorAssignments<api.VendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit', 'Vendors In Enablement'],
    }),
    activateVendor: build.mutation<api.VendorResponse | undefined, api.ActivateVendorRequest & { id: types.UUID }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.activateVendor<api.VendorResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit', 'Vendors In Enablement'],
    }),
    deactivateVendor: build.mutation<api.VendorResponse | undefined, api.DeactivateVendorRequest & { id: api.UUID }>({
      async queryFn({ id, deactivatedReason }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.deactivateVendor<api.VendorResponse>(id, { deactivatedReason });
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit'],
    }),
    markVendorDormant: build.mutation<api.VendorResponse | undefined, api.MarkDormantVendorRequest & { id: api.UUID }>({
      async queryFn({ id, dormancyReason }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.markVendorDormant<api.VendorResponse>(id, { dormancyReason });
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit'],
    }),
    sendPaymentScheduleEmail: build.mutation<api.VendorResponse | undefined, { id: api.UUID }>({
      async queryFn({ id }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.sendPaymentScheduleEmail<api.VendorResponse>(id);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.id }, 'Vendor Audit'],
    }),
    createProgram: build.mutation<api.VendorProgramResponse, api.VendorProgramRequest & { vendorId: api.UUID; productCode: api.ProductCodes }>({
      async queryFn({ vendorId, productCode, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createVendorProgram<api.VendorProgramResponse>(vendorId, productCode, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.vendorId }, 'Vendor Audit'],
    }),
    patchVendorProgram: build.mutation<api.VendorProgramResponse | undefined, api.PatchVendorProgramRequest & { vendorId: api.UUID; productCode: api.ProductCodes; partnerId: api.Partners }>({
      async queryFn({ vendorId, productCode, partnerId, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.patchVendorProgram<api.VendorProgramResponse>(vendorId, productCode, partnerId, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.vendorId }, 'Vendor Audit'],
    }),
    createProgramOverride: build.mutation<api.VendorProgramOverrideResponse | undefined, api.VendorProgramOverrideRequest & { vendorId: api.UUID }>({
      async queryFn({ vendorId, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createVendorProgramOverride<api.VendorProgramOverrideResponse>(vendorId, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.vendorId }, 'Vendor Audit'],
    }),
    patchProgramOverride: build.mutation<api.VendorProgramOverrideResponse | undefined, api.PatchVendorProgramOverrideRequest & { vendorId: api.UUID; id: api.UUID }>({
      async queryFn({ vendorId, id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.patchVendorProgramOverride<api.VendorProgramOverrideResponse>(vendorId, id, body);
        return { data };
      },
      invalidatesTags: (result: any) => ['Vendors', { type: 'Vendor', id: result.vendorId }, 'Vendor Audit'],
    }),
    addVendorAccount: build.mutation<api.VendorAccountAggregateResponse, api.VendorAccountRequest & { id: api.UUID }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createVendorAccount<api.VendorAccountAggregateResponse>(id, body);
        return { data };
      },
      invalidatesTags: (_result: any, _error, parameters) => ['Vendors', { type: 'Vendor', id: parameters.id }, 'Vendor Audit'],
    }),
    verifyVendorAccount: build.mutation<api.VendorAccountAggregateResponse | undefined, api.VendorAccountVerificationRequest & { id: api.UUID, accountId: api.UUID }>({
      async queryFn({ id, accountId, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.verifyVendorAccount<api.VendorAccountAggregateResponse>(id, accountId, body);
        return { data };
      },
      invalidatesTags: (_result: any, _error, parameters) => ['Vendors', { type: 'Vendor', id: parameters.id }, 'Vendor Audit', 'Vendors In Enablement'],
    }),
    makeVendorAccountPrimary: build.mutation<api.VendorAccountAggregateResponse | undefined, { id: api.UUID; accountId: api.UUID }>({
      async queryFn({ id, accountId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.makeVendorAccountPrimary<api.VendorAccountAggregateResponse>(id, accountId);
        return { data };
      },
      invalidatesTags: (_result: any, _error, parameters) => ['Vendors', { type: 'Vendor', id: parameters.id }, 'Vendor Audit'],
    }),
    makeVendorAccountDebitOnly: build.mutation<api.VendorAccountAggregateResponse | undefined, { id: api.UUID; accountId: api.UUID }>({
      async queryFn({ id, accountId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.makeVendorAccountDebitOnly<api.VendorAccountAggregateResponse>(id, accountId);
        return { data };
      },
      invalidatesTags: (_result: any, _error, parameters) => ['Vendors', { type: 'Vendor', id: parameters.id }, 'Vendor Audit'],
    }),
    duplicateVendorAccount: build.mutation<api.VendorAccountAggregateResponse | undefined, api.DuplicateVendorAccountRequest & { id: api.UUID; accountId: api.UUID }>({
      async queryFn({ id, accountId, ...params }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.duplicateVendorAccount<api.VendorAccountAggregateResponse>(id, accountId, params);
        return { data };
      },
      invalidatesTags: (_result: any, _error, parameters) => ['Vendors', { type: 'Vendor', id: parameters.id }, 'Vendor Audit'],
    }),
    hideSecondaryVendorAccount: build.mutation<api.VendorAccountAggregateResponse | undefined, { id: api.UUID; accountId: api.UUID }>({
      async queryFn({ id, accountId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.hideSecondaryVendorAccount<api.VendorAccountAggregateResponse>(id, accountId);
        return { data };
      },
      invalidatesTags: (_result: any, _error, parameters) => ['Vendors', { type: 'Vendor', id: parameters.id }, 'Vendor Audit'],
    }),
    getUserAccounts: build.query<readonly api.UserAccountResponse[], { id: api.UUID }>({
      async queryFn({ id }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllUserAccounts<api.UserAccountResponse>(id);
        return { data };
      },
      providesTags: (result, error, parameters) => (result
        ? [{ type: 'User Accounts', id: parameters.id }]
        : []),
    }),
    getAllAbandonedUserAccounts: build.query<api.IPagedResults<api.UserAccountResponse> | undefined, api.GetAllAbandonedUsersParams>({
      async queryFn(params) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllAbandonedUserAccounts<api.UserAccountResponse>(params);
        return { data };
      },
      providesTags: ['User Accounts'],
    }),
    addUserAccount: build.mutation<api.UserAccountResponse, api.UserAccountRequest & { id: api.UUID; }>({
      async queryFn({ id, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createChildUserAccount<api.UserAccountResponse>(id, body);
        return { data };
      },
      invalidatesTags: (result, _error, parameters) => (result
        ? [{ type: 'User Accounts', id: parameters.id }]
        : []),
    }),
    resendUserAccountInvite: build.mutation<api.UserAccountResponse | undefined, { id: api.UUID; email: string }>({
      async queryFn({ id, email }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.resendChildUserAccountInvite<api.UserAccountResponse>(id, email);
        return { data };
      },
    }),
    promoteUserAccount: build.mutation<api.UserAccountResponse | undefined, { id: api.UUID; email: string }>({
      async queryFn({ id, email }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.promoteChildUserAccount<api.UserAccountResponse>(id, email);
        return { data };
      },
      invalidatesTags: (result, _error, parameters) => (result
        ? [{ type: 'User Accounts', id: parameters.id }]
        : []),
    }),
    deactivateUserAccount: build.mutation<api.UserAccountResponse | undefined, { email: string } & api.DeactivateUserAccountRequest>({
      async queryFn({ email, ...request }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.deactivateUserAccount<api.UserAccountResponse>(email, request);
        return { data };
      },
      invalidatesTags: (result) => (result
        ? [{ type: 'User Accounts', id: result.vendorId }]
        : []),
    }),
    getAllOnboardings: build.query<api.IPagedResults<api.OnboardingResponse>, api.OnboardingFilterParams>({
      async queryFn(params) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllOnboardings<api.OnboardingResponse>(params);
        return { data };
      },
      providesTags: ['Onboardings'],
    }),
    getOnboarding: build.query<api.OnboardingAggregateResponse | undefined, { id: types.UUID }>({
      async queryFn({ id: onboardingId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getOnboarding<api.OnboardingAggregateResponse>(onboardingId);
        return { data };
      },
      providesTags: (result, errors, parameters) => [{ type: 'Onboarding', id: parameters.id }],
    }),
    createOnboarding: build.mutation<api.OnboardingResponse, api.OnboardingRequest>({
      async queryFn(body) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createOnboarding<api.OnboardingResponse>(body);
        return { data };
      },
      invalidatesTags: ['Onboardings'],
    }),
    expireOnboarding: build.mutation<api.OnboardingResponse | undefined, { id: types.UUID }>({
      async queryFn({ id }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.expireOnboarding<api.OnboardingResponse>(id);
        return { data };
      },
      invalidatesTags: (result, errors, parameters) => ['Onboardings', { type: 'Onboarding', id: parameters.id }],
    }),
    getTaskSchedules: build.query<Record<string, string>, any>({
      query() {
        return {
          url: '/operations/tasks/schedules',
          method: 'GET',
        };
      },
      providesTags: ['TaskSchedules'],
    }),
    getAllTaskExecutions: build.query<api.IPagedResults<api.VendorOperationsTaskExecutionResponse>, api.VendorOperationsTaskExecutionFilterParams>({
      async queryFn(params) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await vendorApi.getAllOperationsTaskExecutions(params);
        return { data };
      },
      providesTags: ['TaskExecutions'],
    }),
    createTaskExecution: build.mutation<api.VendorOperationsTaskExecutionResponse, api.VendorOperationsTaskExecutionRequest>({
      async queryFn(params) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await vendorApi.createOperationsTaskExecution(params);
        return { data };
      },
      invalidatesTags: ['TaskExecutions'],
    }),
    getVendorActivationStatistics: build.query<api.VendorActivationsStatisticsResponse, api.VendorActivationStatisticsFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getVendorActivationStatistics<api.VendorActivationsStatisticsResponse>(params);
          return { data };
        });
      },
    }),
    getVendorStatusSummary: build.query<api.VendorStatusSummaryResponse, api.VendorStatusSummaryFilterParams>({
      async queryFn(params) {
        return await StatisticsMutex.runExclusive(async () => {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getVendorStatusSummary<api.VendorStatusSummaryResponse>(params);
          return { data };
        });
      },
    }),
    createVendorServiceAgreement: build.mutation<api.RenderedVendorServiceAgreementResponse | undefined, api.VendorServiceAgreementRequest & { vendorId: types.UUID }>({
      async queryFn({ vendorId, ...request }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createVendorServiceAgreement(vendorId, request);
        return { data };
      },
      invalidatesTags: (result, _error, parameters) => (result
        ? [{ type: 'Vendor Service Agreements', vendorId: parameters.vendorId }]
        : ['Vendor Service Agreements']),
    }),
    getVendorServiceAgreements: build.query<api.IPagedResults<api.RenderedVendorServiceAgreementResponse> | undefined, api.VendorServiceAgreementFilterParams & { vendorId: string; }>({
      async queryFn({ vendorId, ...params }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendorServiceAgreements(vendorId, params);
        return { data };
      },
      providesTags: (result, error, parameters) => (result
        ? [{ type: 'Vendor Service Agreements', vendorId: parameters.vendorId }]
        : ['Vendor Service Agreements']),
    }),
    getVendorServiceAgreement: build.query<api.RenderedVendorServiceAgreementResponse | undefined, { vendorId: string; serviceAgreementId: string }>({
      async queryFn({ vendorId, serviceAgreementId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendorServiceAgreement(vendorId, serviceAgreementId);

        return { data };
      },
      providesTags: (result, error, parameters) => (result
        ? [{ type: 'Vendor Service Agreements', serviceAgreementId: parameters.serviceAgreementId }]
        : ['Vendor Service Agreements']),
    }),
    deleteVendorServiceAgreement: build.mutation<api.RenderedVendorServiceAgreementResponse | undefined, { vendorId: string; serviceAgreementId: string }>({
      async queryFn({ vendorId, serviceAgreementId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.deleteVendorServiceAgreement(vendorId, serviceAgreementId);

        return { data };
      },
      invalidatesTags: (result, error, parameters) => (result
        ? [{ type: 'Vendor Service Agreements', serviceAgreementId: parameters.serviceAgreementId }]
        : ['Vendor Service Agreements']),
    }),
    createVendorPartnerServiceAgreement: build.mutation<api.RenderedVendorPartnerServiceAgreementResponse | undefined, { partnerId: api.Partners, vendorId: types.UUID }>({
      async queryFn({ partnerId, vendorId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.createVendorPartnerServiceAgreement(partnerId, vendorId);
        return { data };
      },
      invalidatesTags: (result, _error, parameters) => (result
        ? [{ type: 'Vendor Partner Service Agreements', partnerId: parameters.partnerId, vendorId: parameters.vendorId }]
        : ['Vendor Partner Service Agreements']),
    }),
    getVendorPartnerServiceAgreements: build.query<api.IPagedResults<api.RenderedVendorPartnerServiceAgreementResponse> | undefined, api.VendorServiceAgreementFilterParams & { partnerId: api.Partners, vendorId: string; }>({
      async queryFn({ partnerId, vendorId, ...params }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendorPartnerServiceAgreements(partnerId, vendorId, params);
        return { data };
      },
      providesTags: (result, error, parameters) => (result
        ? [{ type: 'Vendor Partner Service Agreements', partnerId: parameters.partnerId, vendorId: parameters.vendorId }]
        : ['Vendor Partner Service Agreements']),
    }),
    getVendorPartnerServiceAgreement: build.query<api.RenderedVendorPartnerServiceAgreementResponse | undefined, { partnerId: api.Partners, vendorId: string; serviceAgreementId: string }>({
      async queryFn({ partnerId, vendorId, serviceAgreementId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendorPartnerServiceAgreement(partnerId, vendorId, serviceAgreementId);

        return { data };
      },
      providesTags: (result, error, parameters) => (result
        ? [{ type: 'Vendor Partner Service Agreements', serviceAgreementId: parameters.serviceAgreementId }]
        : ['Vendor Partner Service Agreements']),
    }),
    deleteVendorPartnerServiceAgreement: build.mutation<api.RenderedVendorPartnerServiceAgreementResponse | undefined, { partnerId: api.Partners, vendorId: string; serviceAgreementId: string }>({
      async queryFn({ partnerId, vendorId, serviceAgreementId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.deleteVendorPartnerServiceAgreement(partnerId, vendorId, serviceAgreementId);

        return { data };
      },
      invalidatesTags: (result, _error, parameters) => (result
        ? [{ type: 'Vendor Partner Service Agreements', partnerId: parameters.partnerId, vendorId: parameters.vendorId }]
        : ['Vendor Partner Service Agreements']),
    }),
    getAllVendorDocuments: build.query<api.IPagedResults<api.VendorDocumentResponse>, api.VendorDocumentFilterParams & { vendorId: string }>({
      async queryFn({ vendorId, ...params }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllVendorDocuments<api.VendorDocumentResponse>(vendorId, params);
        return { data };
      },
      providesTags: ['Vendor Documents'],
    }),
    getVendorDocument: build.query<api.VendorDocumentResponse | undefined, { vendorId: string, documentId: string }>({
      async queryFn({ vendorId, documentId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendorDocument<api.VendorDocumentResponse>(vendorId, documentId);
        return { data };
      },
      providesTags: ['Vendor Documents'],
    }),
    markVendorDocumentVerified: build.mutation<api.MaybeRedactedVendorDocumentResponse | undefined, { vendorId: string; documentId: string; verificationNotes: string }>({
      async queryFn({ vendorId, documentId, verificationNotes }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.markDocumentVerified(vendorId, documentId, { verificationNotes });
        return { data };
      },
      invalidatesTags: ['Vendor Documents', 'Vendors', 'Vendor'],
    }),
    markVendorDocumentRejected: build.mutation<api.MaybeRedactedVendorDocumentResponse | undefined, { vendorId: string; documentId: string; rejectedReason: api.VendorDocumentRejectedReason }>({
      async queryFn({ vendorId, documentId, rejectedReason }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.markDocumentRejected(vendorId, documentId, { rejectedReason });
        return { data };
      },
      invalidatesTags: ['Vendor Documents', 'Vendors', 'Vendor'],
    }),
    hideDocument: build.mutation<api.MaybeRedactedVendorDocumentResponse | undefined, { vendorId: string; documentId: string }>({
      async queryFn({ vendorId, documentId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.markDocumentInvisible(vendorId, documentId, {});
        return { data };
      },
      invalidatesTags: ['Vendor Documents', 'Vendors', 'Vendor'],
    }),
    getVendorNotes: build.query<readonly api.VendorNoteResponse[], { vendorId: string }>({
      async queryFn({ vendorId }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllVendorNotes(vendorId);
        return { data };
      },
      providesTags: ['Vendor Notes'],
    }),
    addVendorNote: build.mutation<api.VendorNoteResponse, api.CreateVendorNoteRequest & { vendorId: string }>({
      async queryFn({ vendorId, ...request }) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await vendorApi.createVendorNote(vendorId, request);
        return { data };
      },
      invalidatesTags: ['Vendor Notes'],
    }),
    getVendorAudit: build.query<api.IPagedResults<api.VendorsAuditResponse>, api.VendorAuditFilterParams & { vendorId: string }>({
      async queryFn({ vendorId, ...params }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getVendorAudit(vendorId, params);
        return { data };
      },
      providesTags: ['Vendor Audit'],
    }),
    getThirdPartyBusinessVerifications: build.query<any[], { vendorId: string }>({
      query({ vendorId }) {
        return {
          url: `/vendors/${vendorId}/third-party-business-verifications`,
          method: 'GET',
        };
      },
      providesTags: ['Third Party Business Verifications'],
    }),
    uploadFacilitators: build.mutation<readonly api.FacilitatorResponse[], { body: FormData, partnerId: number }>({
      async queryFn({ body, partnerId }) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await vendorApi.uploadFacilitators(partnerId, body);
        return { data };
      },
      invalidatesTags: ['Facilitators'],
    }),
    getAllFacilitators: build.query<api.IPagedResults<api.FacilitatorResponse>, api.FacilitatorFilterParams>({
      async queryFn(params) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await vendorApi.getAllFacilitators(params);
        return { data };
      },
      providesTags: ['Facilitators'],
    }),
    getFacilitatorTotals: build.query<api.FacilitatorTotalsResponse, unknown>({
      async queryFn() {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await vendorApi.getFacilitatorTotals();
        return { data };
      },
      providesTags: ['FacilitatorTotals'],
    }),
    approveFacilitators: build.mutation<readonly api.FacilitatorResponse[], { ids: readonly api.UUID[] }>({
      async queryFn({ ids }) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await ids.reduce((p, id) => p.then(async (res) => {
          const facilitator = await vendorApi.approveFacilitator(id, {});
          return facilitator ? [...res, facilitator] : res;
        }), Promise.resolve([] as readonly api.FacilitatorResponse[]));

        return { data };
      },
      invalidatesTags: ['Facilitators'],
    }),
    revokeFacilitators: build.mutation<readonly api.FacilitatorResponse[], { ids: readonly api.UUID[] }>({
      async queryFn({ ids }) {
        const vendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await ids.reduce((p, id) => p.then(async (res) => {
          const facilitator = await vendorApi.revokeFacilitator(id, {});
          return facilitator ? [...res, facilitator] : res;
        }), Promise.resolve([] as readonly api.FacilitatorResponse[]));

        return { data };
      },
      invalidatesTags: ['Facilitators'],
    }),
    uploadVendorDocument: build.mutation<api.MaybeRedactedVendorDocumentResponse | undefined, { vendorId: string, body: FormData }>({
      async queryFn({ vendorId, body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.uploadVendorDocument(vendorId, body);
        return { data };
      },
      invalidatesTags: ['Vendor', 'Vendor Documents'],
    }),
    patchUserAccount: build.mutation<api.MaybeRedactedUserAccountResponse | undefined, api.PatchUserAccountRequest & { id: string }>({
      async queryFn({ id, onboardingStatus }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.patchUserAccount(id, { onboardingStatus });
        return { data };
      },
      invalidatesTags: ['User Accounts'],
    }),
    getAllVendorFeedback: build.query<api.IPagedResults<api.VendorFeedbackResponse> | undefined, api.IPageFilterParams>({
      async queryFn(params) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.getAllVendorFeedback(params);

        return { data };
      },
      providesTags: ['Vendor Feedback'],
    }),
    getPrograms:
      build.query<readonly api.ProgramResponse[], api.GetProgramsFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getPrograms<api.ProgramResponse>(params);
          return { data };
        },
        providesTags: ['Programs'],
      }),
    getProgramStatistics:
      build.query<api.ProgramStatisticsBreakdown, api.GetProgramsFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getProgramsStatistics<api.ProgramStatisticsBreakdown>(params);
          return { data };
        },
      }),
    getProgramOverrides:
      build.query<api.IPagedResults<api.VendorProgramOverrideResponse>, api.GetProgramOverridesFilterParams>({
        async queryFn(params) {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getProgramOverrides<api.VendorProgramOverrideResponse>(params);
          return { data };
        },
      }),
    getProgram:
      build.query<api.ProgramResponse, { code: string, partnerId: number }>({
        async queryFn({ code, partnerId }) {
          const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
          const data = await externalVendorApi.getProgram<api.ProgramResponse>(code as ProgramCodes, partnerId);
          return { data };
        },
        providesTags: ['Program']
      }),
    patchProgram: build.mutation<api.ProgramResponse, api.PatchProgramRequest & { code: string, partnerId: number }>({
      async queryFn({ code, partnerId, ...body }) {
        const externalVendorApi = useApi((options) => new VendorApi(CONFIG.api.vendorUrl, options));
        const data = await externalVendorApi.patchProgram(code as ProgramCodes, partnerId, body);
        return { data };
      },
      invalidatesTags: ['Program'],
    }),
  }),
});

export const {
  useGetAllVendorsQuery,
  useLazyGetAllVendorsQuery,
  useGetVendorQuery,
  useGetAllVendorsInEnablementQuery,
  useUpdateVendorMutation,
  usePatchVendorMutation,
  useGetAllOnboardingsQuery,
  useGetOnboardingQuery,
  useCreateOnboardingMutation,
  useExpireOnboardingMutation,
  useUpdateVendorAssignmentsMutation,
  useActivateVendorMutation,
  useDeactivateVendorMutation,
  useMarkVendorDormantMutation,
  useSendPaymentScheduleEmailMutation,
  useCreateProgramMutation,
  usePatchVendorProgramMutation,
  useCreateProgramOverrideMutation,
  usePatchProgramOverrideMutation,
  useAddVendorAccountMutation,
  useVerifyVendorAccountMutation,
  useMakeVendorAccountPrimaryMutation,
  useMakeVendorAccountDebitOnlyMutation,
  useDuplicateVendorAccountMutation,
  useHideSecondaryVendorAccountMutation,
  useGetUserAccountsQuery,
  useGetAllAbandonedUserAccountsQuery,
  useAddUserAccountMutation,
  useResendUserAccountInviteMutation,
  usePromoteUserAccountMutation,
  useDeactivateUserAccountMutation,
  useGetTaskSchedulesQuery,
  useGetAllTaskExecutionsQuery,
  useCreateTaskExecutionMutation,
  useCreateVendorServiceAgreementMutation,
  useGetVendorActivationStatisticsQuery,
  useLazyGetVendorActivationStatisticsQuery,
  useGetVendorStatusSummaryQuery,
  useLazyGetVendorStatusSummaryQuery,
  useGetVendorServiceAgreementsQuery,
  useGetVendorServiceAgreementQuery,
  useDeleteVendorServiceAgreementMutation,
  useCreateVendorPartnerServiceAgreementMutation,
  useGetVendorPartnerServiceAgreementsQuery,
  useGetVendorPartnerServiceAgreementQuery,
  useDeleteVendorPartnerServiceAgreementMutation,
  useImpersonateVendorMutation,
  useEndVendorImpersonationMutation,
  useGetAllVendorDocumentsQuery,
  useGetVendorDocumentQuery,
  useMarkVendorDocumentVerifiedMutation,
  useMarkVendorDocumentRejectedMutation,
  useGetVendorNotesQuery,
  useAddVendorNoteMutation,
  useGetVendorAuditQuery,
  useUploadFacilitatorsMutation,
  useGetAllFacilitatorsQuery,
  useGetFacilitatorTotalsQuery,
  useApproveFacilitatorsMutation,
  useRevokeFacilitatorsMutation,
  useUploadVendorDocumentMutation,
  usePatchUserAccountMutation,
  useGetAllVendorFeedbackQuery,
  useGetProgramsQuery,
  useGetProgramStatisticsQuery,
  useGetProgramOverridesQuery,
  useHideDocumentMutation,
  useGetProgramQuery,
  usePatchProgramMutation
} = vendorsApi;

export const uploadCreateMultipleOnboardings = async (
  body: FormData,
): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.vendorUrl}/operations/onboardings`);

  const response = await fetch(url.href, {
    method: 'POST',
    body,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  if (response.status >= 400) {
    throw new Error(response.statusText);
  }

  return await response.blob();
};

export const exportAllVendors = async (filterParams?: types.ExportVendorFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.vendorUrl}/vendors/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export type ExportAbandonedUserFilterParams = types.IPageFilterParams & types.ISortFilterParams & { readonly columns?: string | readonly string[] };

export const exportAllAbandonedUsers = async (filterParams?: ExportAbandonedUserFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.vendorUrl}/abandoned-user-accounts/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};

export const downloadVendorDocument = async (vendorId: types.UUID, documentId: types.UUID): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.vendorUrl}/vendors/${vendorId}/documents/${documentId}/download`);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  if (response.status !== 200) {
    const error = await response.json();
    throw new Error(error?.message || response.statusText);
  }

  return await response.blob();
};
