type PaginationLimitItem = {
  key: string;
  value: number;
};

export const DefaultPaginationLimits: PaginationLimitItem[] = [
  { key: '10', value: 10 },
  { key: '25', value: 25 },
  { key: '50', value: 50 },
  { key: '100', value: 100 },
];

export default DefaultPaginationLimits;
