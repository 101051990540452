import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Typography } from '@mesa-labs/mesa-ui';
import { useDispatch, useSelector } from '../../redux/hooks';
import { useEndVendorImpersonationMutation, useGetVendorQuery } from '../../redux/api/vendors';
import { getImpersonatedVendorId, setupCognito } from '../../redux/slices/auth';

const ImpersonationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: center;
  gap: 8px;
  color: #2E2C34;
  background-color: #FEF7E7;
  border-top: 1px solid #FEEFCB;
  border-bottom: 1px solid #FEEFCB;
  padding: 0px 32px;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
  z-index: 50;
`;

const Link = styled.a`
  color: blue;
  background-color: transparent;
  text-decoration: underline;
`;

const EndImpersonationIcon = styled.img`
  cursor: pointer;
`;

function Impersonation(): React.ReactElement | null {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const authAttributes = useSelector((state) => state.auth.attributes);

  const vendorId = useMemo(() => isLoggedIn ? getImpersonatedVendorId(authAttributes) : undefined, [isLoggedIn, authAttributes]);

  const [endVendorImpersonation] = useEndVendorImpersonationMutation();

  const {
    data: vendor,
  } = useGetVendorQuery({
    id: vendorId!,
  }, { skip: !vendorId });

  const vendorName = useMemo(() => vendor?.name || vendorId, [vendorId, vendor]);

  const handleEndImpersonation = async () => {
    if (vendorId) {
      await endVendorImpersonation({ id: vendorId });
      await dispatch(setupCognito({ forceRefresh: true }) as any);
    }
  };

  if (!vendorId) {
    return null;
  }

  return (
    <ImpersonationContainer>
      <Typography.BodyMedium>Impersonating Vendor:</Typography.BodyMedium>
      <Link href={`/vendors/${vendorId}`} rel="noreferrer">
        {vendorName}
      </Link>
      <EndImpersonationIcon
        src="/assets/delete.svg"
        onClick={() => handleEndImpersonation()}
      />
    </ImpersonationContainer>
  );
}

export default Impersonation;
