import React, { useEffect, useState } from 'react';
import { types as api } from '@mesa-labs/mesa-api';
import { Partners } from '@mesa-labs/mesa-api/dist/types';

import { Selection, toFilterEnumFromNumberEnum } from '../../utils';
import Select from './Select';

type PartnerSelections = ({ label: string; value: Partners | undefined })[];

const ValidPartnerSelections: PartnerSelections = toFilterEnumFromNumberEnum(api.Partners).filter((s) => s.value > 0).map((s) => ({ label: api.getPartnerName(s.value), value: s.value }));
const AllPartnerSelections: PartnerSelections = [{ label: 'All Partners', value: undefined }, ...ValidPartnerSelections];
const NoPartnerSelections: PartnerSelections = [{ label: '(No Selection)', value: undefined }, ...ValidPartnerSelections];

type PartnerSelectProps = {
  selectedPartner: api.Partners | undefined;
  onSelectedPartnerChange: (partnerId: api.Partners | undefined) => void;
  allowAllPartners?: boolean;
  allowNoPartner?: boolean;
  width?: string;
};

function PartnerSelect({
  selectedPartner,
  onSelectedPartnerChange,
  allowAllPartners,
  allowNoPartner,
  width,
}: PartnerSelectProps): React.ReactElement {
  const [partnerSelections, setPartnerSelections] = useState(ValidPartnerSelections);
  const [partnerSelection, setPartnerSelection] = useState(ValidPartnerSelections[0]);

  useEffect(() => {
    const newPartnerSelections = allowAllPartners
      ? AllPartnerSelections :
      allowNoPartner
        ?
        NoPartnerSelections
        : ValidPartnerSelections;
    setPartnerSelections(newPartnerSelections);
    setPartnerSelection(newPartnerSelections.find((s) => s?.value === selectedPartner) || newPartnerSelections[0]);
  }, [selectedPartner, allowAllPartners]);

  const handleOnChange = (selection: Selection<api.Partners | undefined>) => {
    setPartnerSelection(selection!);
    onSelectedPartnerChange(selection!.value);
  };

  return (
    <Select
      value={partnerSelection}
      onChange={handleOnChange}
      options={partnerSelections}
      width={width}
    />
  );
}

PartnerSelect.defaultProps = {
  allowAllPartners: undefined,
  allowNoPartner: undefined,
  width: undefined,
};

export default PartnerSelect;
