import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ThunkActionDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Select from 'react-select';
import { StatusLabel } from '@mesa-labs/mesa-ui';

import { useDispatch, useSelector } from '../../redux/hooks';
import { signOut } from '../../redux/slices/auth';
import { toFilterEnum, USER_TIMEZONE_LOCAL_STORAGE_KEY, UsTimezone } from '../../utils';
import PageMenu from './PageMenu';
import Impersonation from './Impersonation';

const NavigationContainer = styled.div`
  align-items: center;
  backdrop-filter: blur(15px) saturate(125%);
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 1px 3px rgba(8, 7, 58, 0.03);
  color: #2E2C34;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 51;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 32px;
  width: 100%;
`;

const LogoContainer = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 52px;
`;

const Logo = styled.img`
  padding: 20px 0;
  transform: scale(0.90);
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const BackLink = styled.div`
  align-items: center;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.Cobalt};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-evenly;
  line-height: 130%;
  margin-bottom: 8px;
  max-width: 80px;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
  transition: background 100ms ease-in-out;
  white-space: nowrap;

  &:hover {
    background: rgba(129, 156, 251, 0.1);
  }
`;

export const BackLinkIcon = styled.img`
  margin-right: 8px;
`;

const RightIcons = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  gap: 8px;
`;

const EnvironmentLabel = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  text-transform: uppercase;

  > * {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

const NavDropdownContainer = styled(motion.div)`
  position: relative;
`;

const NavDropdownLabel = styled.div<{ active: boolean }>`
  background: ${(props) => (props.active && 'rgba(129, 156, 251, 0.1)') || 'transparent'};
  border-radius: 4px; 
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px;
  position: relative;
`;

const NavDropdownLabelBridge = styled.div`
  height: 10px;
  position: absolute;
  bottom: -10px;
  left: 0px;
  width: 100%;
`;

const NavDropdownLinks = styled(motion.div) <{ width?: number }>`
  background: ${(props) => props.theme.colors.White};
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
  display: none;
  flex-direction: column;
  font-size: 14px;
  left: 50%;
  overflow:  hidden;
  position: absolute;
  top: 48px;
  transform: translateX(-50%);
  z-index: 52;
  width: ${(props) => props.width || 220}px;
`;

const NavDropdownLink = styled(Link)`
  cursor: pointer;
  padding: 14px 16px;
  transition: background 100ms ease-in-out;

  &:hover {
    background: #FCFCFF;
  }
`;

const SignInLink = styled(Link)`
  border: 1px solid #EEE;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: #FAFAFA;
  }
`;

function Navigation(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch() as ThunkActionDispatch<any>;
  const item = localStorage.getItem(USER_TIMEZONE_LOCAL_STORAGE_KEY);
  const [timezone, setTimezone] = useState<any>(item ? JSON.parse(item) : {});
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const canViewBasicNav = useMemo(() => isLoggedIn, [isLoggedIn]);

  const timezoneSelection = toFilterEnum(UsTimezone);

  const setTz = (tz: any) => {
    localStorage.setItem(USER_TIMEZONE_LOCAL_STORAGE_KEY, JSON.stringify(tz));
    setTimezone(tz);
    window.location.reload();
  };

  return (
    <NavigationContainer>
      <Impersonation />

      <HeaderContainer>
        <Left>
          <LogoContainer to="/">
            <Logo src="/assets/mesa.svg" />
          </LogoContainer>
          <PageMenu initial="hidden" />
        </Left>

        <RightIcons>
          {isLoggedIn && (
            <>
              {canViewBasicNav && (
                <>
                  <div>Timezone</div>
                  <Select
                    menuPortalTarget={document.body}
                    // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    value={timezone}
                    onChange={setTz}
                    isClearable
                    options={timezoneSelection}
                    placeholder="Set Timezone"
                  />
                  <EnvironmentLabel>
                    <StatusLabel
                      status={process.env.NODE_ENV}
                      label={process.env.NODE_ENV}
                    />
                  </EnvironmentLabel>
                </>
              )}

              <AnimatePresence>
                {canViewBasicNav && (
                  <NavDropdownContainer initial="hidden" whileHover="hover">
                    <NavDropdownLabel
                      active={false}
                    >
                      <Icon src="/assets/menu-icon.svg" onClick={() => navigate('/settings')} />
                      <NavDropdownLabelBridge />
                    </NavDropdownLabel>
                    <NavDropdownLinks
                      width={100}
                      variants={{
                        hidden: {
                          display: 'none',
                          opacity: 0,
                        },
                        hover: {
                          display: 'flex',
                          opacity: 1,
                        },
                      }}
                      transition={{
                        type: 'spring',
                        duration: 1,
                      }}
                    >
                      <NavDropdownLink to="/settings">Settings</NavDropdownLink>
                      <NavDropdownLink to="/" onClick={() => dispatch(signOut())}>Logout</NavDropdownLink>
                    </NavDropdownLinks>
                  </NavDropdownContainer>
                )}
              </AnimatePresence>
            </>
          )}
          {!isLoggedIn && (
            <SignInLink to="/sign-in">Sign in</SignInLink>
          )}
        </RightIcons>
      </HeaderContainer>
    </NavigationContainer>
  );
}

export default Navigation;
