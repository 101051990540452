import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaymentApi, types as api } from '@mesa-labs/mesa-api';

import { CollectionFilterParams } from '@mesa-labs/mesa-api/dist/types';
import { getIdToken, prepareHeaders, useApi } from './api';
import { appendFilterParams } from '../../utils';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.paymentUrl,
    prepareHeaders,
  }),
  tagTypes: ['CustomerAccount', 'Collections', 'Collection', 'TaskSchedules', 'TaskExecutions'],
  endpoints: (build) => ({
    getCustomerAccount: build.query<api.CustomerAccountResponse | undefined, { vendorId: api.UUID, vendorAccountId: api.UUID }>({
      async queryFn({ vendorId, vendorAccountId }) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.getCustomerAccount<api.CustomerAccountResponse>(vendorId, vendorAccountId);
        return { data };
      },
      providesTags: (result, _error, parameters) => (result
        ? [{ type: 'CustomerAccount', vendorId: parameters.vendorId, vendorAccountId: parameters.vendorAccountId }]
        : ['CustomerAccount']),
    }),
    getCustomerAccounts: build.query<readonly api.CustomerAccountResponse[], { vendorId: api.UUID, vendorAccountIds: readonly api.UUID[] }>({
      async queryFn({ vendorId, vendorAccountIds }, _queryApi, _extraOptions, fetchWithBQ) {
        const responses = await Promise.all(vendorAccountIds.map((vendorAccountId) => fetchWithBQ({
          url: `/customers/${vendorId}/accounts/${vendorAccountId}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })));
        return {
          data: responses.filter((r) => !!r.data).map((r) => r.data as api.CustomerAccountResponse),
        };
      },
      providesTags: (result, error, parameters) => (result
        ? result.map((ca) => ({ type: 'CustomerAccount', vendorId: parameters.vendorId, vendorAccountId: ca.vendorAccountId }))
        : []),
    }),
    patchCustomerAccount: build.mutation<api.CustomerAccountResponse | undefined, { vendorId: api.UUID, vendorAccountId: api.UUID } & api.PatchCustomerAccountRequest>({
      async queryFn({ vendorId, vendorAccountId, ...request }) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.patchCustomerAccount<api.CustomerAccountResponse>(vendorId, vendorAccountId, request);
        return { data };
      },
      invalidatesTags: (result, _error, parameters) => (result
        ? [{ type: 'CustomerAccount', vendorId: parameters.vendorId, vendorAccountId: parameters.vendorAccountId }]
        : ['CustomerAccount']),
    }),
    getAllCustomerBatchTransfers: build.query<api.IPagedResults<api.CustomerBatchTransferResponse>, api.CustomerBatchTransferFilterParams>({
      async queryFn(params) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.getAllCollatedTransfers<api.CustomerBatchTransferResponse>(params);
        return { data };
      },
    }),
    getAllCollections: build.query<api.IPagedResults<api.CollectionResponse>, api.CollectionFilterParams>({
      async queryFn(params) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.getAllCollections<api.CollectionResponse>(params);
        return { data };
      },
      providesTags: ['Collections'],
    }),
    createCollection: build.mutation<api.CollectionResponse | undefined, api.CollectionRequest>({
      async queryFn(params) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.createCollection<api.CollectionResponse>(params);
        return { data };
      },
      invalidatesTags: ['Collections', 'Collection'],
    }),
    getCollection: build.query<api.CollectionResponse | undefined, { id: string }>({
      async queryFn({ id }) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.getCollection<api.CollectionResponse>(id);
        return { data };
      },
      providesTags: ['Collection'],
    }),
    updateCollection: build.mutation<api.CollectionResponse | undefined, { id: string, collection: Partial<api.PatchCollectionRequest> }>({
      async queryFn({ id, collection }) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.patchCollection<api.CollectionResponse>(id, collection);
        return { data };
      },
      invalidatesTags: ['Collections', 'Collection'],
    }),
    deleteCollection: build.mutation<api.CollectionResponse | undefined, { id: string }>({
      async queryFn({ id }) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.deleteCollection<api.CollectionResponse>(id);
        return { data };
      },
      invalidatesTags: ['Collections'],
    }),
    getFundingSourceBalance: build.query<api.BalanceAggregate, undefined>({
      async queryFn() {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.getFundingSourceBalance<api.BalanceAggregate>();
        return { data };
      },
    }),
    getTaskSchedules: build.query<Record<string, string>, any>({
      async queryFn() {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.getTaskSchedules<Record<string, string>>();
        return { data };
      },
      providesTags: ['TaskSchedules'],
    }),
    getAllTaskExecutions: build.query<api.IPagedResults<api.PaymentOperationsTaskExecutionResponse>, api.PaymentOperationsTaskExecutionFilterParams>({
      async queryFn(params) {
        const paymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await paymentApi.getAllOperationsTaskExecutions(params);
        return { data };
      },
      providesTags: ['TaskExecutions'],
    }),
    createTaskExecution: build.mutation<api.PaymentOperationsTaskExecutionResponse, api.PaymentOperationsTaskExecutionRequest>({
      async queryFn(params) {
        const paymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await paymentApi.createOperationsTaskExecution(params);
        return { data };
      },
      invalidatesTags: ['TaskExecutions'],
    }),
    createFundingTransfer: build.mutation<api.TransferAggregateResponse[], api.FundingTransferRequest>({
      async queryFn(request) {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.createFundingTransfer(request);
        return { data };
      },
    }),
    createDirectDebitAuthorization: build.mutation<api.DirectDebitAuthorizationResponse, void>({
      async queryFn() {
        const externalPaymentApi = useApi((options) => new PaymentApi(CONFIG.api.paymentUrl, options));
        const data = await externalPaymentApi.createDirectDebitAuthorization();
        return { data };
      },
    }),
  }),
});

export const {
  useGetCustomerAccountQuery,
  useGetCustomerAccountsQuery,
  usePatchCustomerAccountMutation,
  useGetAllCollectionsQuery,
  useGetCollectionQuery,
  useUpdateCollectionMutation,
  useGetFundingSourceBalanceQuery,
  useGetTaskSchedulesQuery,
  useGetAllTaskExecutionsQuery,
  useCreateTaskExecutionMutation,
  useCreateFundingTransferMutation,
  useGetAllCustomerBatchTransfersQuery,
  useCreateCollectionMutation,
  useCreateDirectDebitAuthorizationMutation,
  useDeleteCollectionMutation,
} = paymentsApi;

export const exportAllCollections = async (filterParams?: CollectionFilterParams): Promise<BlobPart> => {
  const idToken = await getIdToken();

  const url = new URL(`${CONFIG.api.paymentUrl}/collections/csv/export`);
  appendFilterParams(url, filterParams);

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: idToken ? {
      Authorization: `Bearer ${idToken}`,
    } : undefined,
  });

  return await response.blob();
};
