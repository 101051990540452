import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type InvoiceLineItemsState = {
  searchTerm?: string;
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
  beginningAt?: api.ISODateString;
  endingAt?: api.ISODateString;
  createdBeginningAt?: api.ISODateString;
  createdEndingAt?: api.ISODateString;
  eligibleBeginningAt?: api.ISODateString;
  eligibleEndingAt?: api.ISODateString;
};

const initialState: InvoiceLineItemsState = {
  searchTerm: '',
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  beginningAt: undefined,
  endingAt: undefined,
  createdBeginningAt: undefined,
  createdEndingAt: undefined,
  eligibleBeginningAt: undefined,
  eligibleEndingAt: undefined,
};

const invoiceLineItemsSlice = createSlice({
  name: 'invoiceLineItems',
  initialState,
  reducers: {
    updateSearchTerm(state: InvoiceLineItemsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updatePage(state: InvoiceLineItemsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: InvoiceLineItemsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: InvoiceLineItemsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: InvoiceLineItemsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: InvoiceLineItemsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateBeginningAt(state: InvoiceLineItemsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: InvoiceLineItemsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.endingAt = action.payload;
    },
    updateCreatedBeginningAt(state: InvoiceLineItemsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.createdBeginningAt = action.payload;
    },
    updateCreatedEndingAt(state: InvoiceLineItemsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.createdEndingAt = action.payload;
    },
    updateEligibleBeginningAt(state: InvoiceLineItemsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.eligibleBeginningAt = action.payload;
    },
    updateEligibleEndingAt(state: InvoiceLineItemsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.eligibleEndingAt = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateBeginningAt,
  updateEndingAt,
  updateCreatedBeginningAt,
  updateCreatedEndingAt,
  updateEligibleBeginningAt,
  updateEligibleEndingAt,
} = invoiceLineItemsSlice.actions;
export default invoiceLineItemsSlice.reducer;
