import { DateTime } from 'luxon';
import { StylesConfig } from 'react-select';
import { Dispatch, SetStateAction } from 'react';
import { types as api } from '@mesa-labs/mesa-api';
import { ApexOptions } from 'apexcharts';
import { Decimal } from 'decimal.js';

export const USER_TIMEZONE_LOCAL_STORAGE_KEY = 'userTimezone';

export const asExportFilter = <T>(filter: T): T => ({
  ...filter,
  page: 1,
  limit: Number.MAX_SAFE_INTEGER,
});

export const capitalize = (
  str = ' ',
): string => str.trim().split(' ').map((s: string) => (s
  ? s[0].toUpperCase() + s.slice(1).toLowerCase()
  : ''
)).join(' ');

export const snakeCaseToProperCase = (str: string): string => capitalize(str.replace(/_/g, ' '));
export const kebabCaseToProperCase = (str: string): string => capitalize(str.replace(/-/g, ' '));
export const camelCaseToProperCase = (str: string): string => {
  const [first, ...rest] = str.split(/(?<=[a-z])(?=[A-Z])/);
  return [first[0].toUpperCase() + first.slice(1), ...rest].join(' ');
};

export const cleanPrice = (
  price: string,
): string => price.replace(/[^0-9.]+/g, '');

export const toPrice = (
  price: number,
): string => `$${price.toFixed(2)}`;

export const formatPhoneNumber = (
  value: string,
  previousValue: string,
  keyCode: string,
): string => {
  // only allow 0-9 input
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!value) {
    return currentValue;
  }

  // on backspace, keep the formatting
  if (keyCode === 'Backspace') {
    return currentValue.slice(0, previousValue.length - 1);
  }

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) {
      return currentValue;
    }

    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    }

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }

  return currentValue;
};

export const formatCurrency = (amount: number, currency: api.CurrencyCode, maximumFractionDigits = 2) => Intl.NumberFormat(undefined, currency ? { style: 'currency', currency, maximumFractionDigits } : {}).format(amount);

export const wait = (ms: number): Promise<void> => new Promise(
  (resolve) => { setTimeout(resolve, ms); },
);

export const waitAtLeast = async (fn: () => any, ms: number): Promise<any> => {
  const start = Date.now();
  const result = await fn();
  const end = Date.now();
  const time = end - start;

  if (time < ms) {
    await wait(ms - time);
  }

  return result;
};

export const orderObject = (
  obj: Record<string, any>,
  order: string[],
): Record<string, any> => order.reduce((accum: Record<string, any>, curr: string) => ({
  ...accum,
  [curr]: obj[curr],
}), {});

export const randomNumber = (max = 10000000): number => Math.random() * max;

export const randomInt = (max = 10000000): number => Math.floor(randomNumber(max));

export const paginationPrev = (state: number, dispatch: Dispatch<SetStateAction<number>>): () => void => () => {
  if (state - 1 < 1) {
    return;
  }

  dispatch(state - 1);
};

export const paginationNext = (state: number, total: number, dispatch: Dispatch<SetStateAction<number>>): () => void => () => {
  if (state + 1 > total) {
    return;
  }

  dispatch(state + 1);
};

export const orderBy = (
  order: string[],
  collection: Record<string, any>,
): Record<string, any> => order.reduce((accum, key) => ({
  ...accum,
  [key]: collection[key],
}), {});

export function hashCode(str: string): number {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}

const COLOR_ASSET = 'rgba(138, 243, 255, 1)';
const COLOR_LIABILITY = 'rgba(255, 154, 59, 1)';
const COLOR_REVENUE = 'rgba(53, 245, 32, 1)';
const COLOR_EXPENSE = 'rgba(255, 23, 23, 1)';
const COLOR_EQUITY = 'rgba(255, 215, 0, 1)';

const COLOR_MESA = 'rgba(7, 16, 88, 0.5)';
const COLOR_JLL = 'rgba(255, 134, 102, 0.5)';
export const COLOR_VENDOR = 'rgba(64, 84, 237, 0.5)';
export const COLOR_CLIENT = 'rgba(0, 154, 117, 0.5)';
const COLOR_CREDIT_FACILITY = 'rgba(254, 200, 75, 0.5)';
const COLOR_SOFTWARE_SERVICE_PROVIDER = 'rgba(252, 52, 0, 0.5)';

export const accountCategoryStyling = (accountCategory: string): string | undefined => {
  switch (accountCategory) {
    case 'Asset':
      return COLOR_ASSET;
    case 'Liability':
      return COLOR_LIABILITY;
    case 'Revenue':
      return COLOR_REVENUE;
    case 'Expense':
      return COLOR_EXPENSE;
    case 'Equity':
      return COLOR_EQUITY;
    default:
      return undefined;
  }
};

export const HashToHexColor = (str: string): string => {
  let hash = 0, i, chr;
  if (str.length === 0)`#000000`;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return `#${hash.toString(16).substr(0, 6)}`;
}

export const accountCategoryRowStyling = (row: Record<string, unknown>): string | undefined => accountCategoryStyling(row.accountCategory as string);

export const accountCategoryColorById = (id: number): string | undefined => {
  if (!id) {
    return undefined;
  }

  if (id >= 100000 && id < 200000) {
    return COLOR_ASSET;
  }
  if (id >= 200000 && id < 300000) {
    return COLOR_LIABILITY;
  }

  if (id >= 300000 && id < 400000) {
    return COLOR_REVENUE;
  }

  if (id >= 400000 && id < 500000) {
    return COLOR_EXPENSE;
  }

  if (id >= 500000 && id < 600000) {
    return COLOR_EQUITY;
  }

  return undefined;
};

export const businessTypeRowStyling = (row: Record<string, unknown>): string | undefined => {
  const type = row.businessType;
  switch (type) {
    case 'Mesa':
      return COLOR_MESA;
    case 'JLL':
      return COLOR_JLL;
    case 'Vendor':
      return COLOR_VENDOR;
    case 'Client':
      return COLOR_CLIENT;
    default:
      return undefined;
  }
};

export const businessTypeColorById = (id: number): string | undefined => {
  if (!id) {
    return undefined;
  }

  if (id === 1) {
    return COLOR_MESA;
  }
  if (id === 2) {
    return COLOR_JLL;
  }
  if (id === 3) {
    return COLOR_VENDOR;
  }
  if (id === 4) {
    return COLOR_CLIENT;
  }
  if (id === 5) {
    return COLOR_CREDIT_FACILITY;
  }
  if (id === 6) {
    return COLOR_SOFTWARE_SERVICE_PROVIDER;
  }

  return undefined;
};

export const accountCategoryByAccountIdRowStyling = (row: Record<string, unknown>): string | undefined => accountCategoryColorById(row.accountId as number);

const cssBusinessType = (styles: any, id: number) => {
  const colorRgba = businessTypeColorById(id);
  return {
    ...styles,
    borderRadius: '4px',
    marginBottom: '4px',
    backgroundColor: colorRgba,
  };
};

const cssAccountCategory = (styles: any, id: number) => {
  const colorRgba = accountCategoryColorById(id);
  return {
    ...styles,
    borderRadius: '4px',
    marginBottom: '4px',
    backgroundColor: colorRgba,
  };
};

export enum UsTimezone {
  Pacific = 'America/Los_Angeles',
  Mountain = 'America/Denver',
  Central = 'America/Chicago',
  Eastern = 'America/New_York',
}

export const getTimezoneSelection = (): string => {
  const item = localStorage.getItem(USER_TIMEZONE_LOCAL_STORAGE_KEY);
  return item ? JSON.parse(item).value : undefined;
};

export const useTimezoneSelection = (dateTime: DateTime): DateTime => {
  const item = localStorage.getItem(USER_TIMEZONE_LOCAL_STORAGE_KEY);
  const userTimezone = item ? JSON.parse(item) : undefined;
  return userTimezone ? dateTime.setZone(userTimezone.value) : dateTime.setZone(UsTimezone.Pacific);
};

export const businessDimensionStyle: StylesConfig<any> = {
  singleValue: (styles, { data }) => cssBusinessType(styles, data.value.type as number),
  option: (styles, { data }) => cssBusinessType(styles, data.value.type as number),
};

export const businessTypeStyle: StylesConfig<any> = {
  singleValue: (styles, { data }) => cssBusinessType(styles, data.value as number),
  option: (styles, { data }) => cssBusinessType(styles, data.value as number),
};

export const accountCategoryStyle: StylesConfig<any> = {
  singleValue: (styles, { data }) => cssAccountCategory(styles, data.value),
  option: (styles, { data }) => cssAccountCategory(styles, data.value),
};

export const toFilterDimension = (dimensions: readonly api.DimensionResponse[]): Array<any> => dimensions.map((businessTypeDimension) => ({
  label: businessTypeDimension.name,
  value: businessTypeDimension.id,
}));

export const stringSeriesToFilterDimension = (stringSeries: string[]): Array<any> => stringSeries.map((s) => ({
  label: s,
  value: s,
}));

export type Selection<T = any> = {
  readonly label: string;
  readonly value: T;
};

export const enumEntryToSelection = <T extends string | number = any>(label: string, entry: any): Selection<T> => ({
  label,
  value: entry,
});

export const enumValueToSelection = <T extends string | number | undefined = any>(entry: any): Selection<T> => ({
  label: snakeCaseToProperCase(entry.toString()),
  value: entry,
});

export const findSelectionByValueOrDefault = <T extends string | undefined>(value: T, selections: Array<Selection<T | undefined>>, defaultSelection?: Selection<T | undefined>): Selection<T | undefined> => selections.find((s) => s.value === value) || defaultSelection || selections[0];

export const toFilterEnum = <T extends string>(enumObj: Record<string, T>): Array<Selection<T>> => Object.entries(enumObj)
  .map((x) => enumEntryToSelection(snakeCaseToProperCase(x[0]), x[1]));

export const toFilterEnumFromNumberEnum = <T extends number>(enumObj: Record<string, T | string>): Array<Selection<T>> => Object.entries(enumObj)
  .filter((x) => !Number.isNaN(parseInt(x[1].toString(), 10)))
  .map((x) => enumEntryToSelection<T>(snakeCaseToProperCase(x[0]), x[1] as T));

export const toStartOfDayLocalTime = (jsDate: Date): Date => useTimezoneSelection(DateTime.fromJSDate(jsDate)).startOf('day').toJSDate();

export const toEndOfDayLocalTime = (jsDate: Date): Date => useTimezoneSelection(DateTime.fromJSDate(jsDate)).endOf('day').toJSDate();

export const toEndOfWeekLocalTime = (jsDate: Date): Date => useTimezoneSelection(DateTime.fromJSDate(jsDate)).endOf('week').toJSDate();

export const statisticsSummaryToBoxPlot = (statisticsSummary: api.StatisticsSummary): number[] => [
  statisticsSummary?.min || 0,
  statisticsSummary?.p25 || 0,
  statisticsSummary?.median || 0,
  statisticsSummary?.p75 || 0,
  statisticsSummary?.max || 0,
];

export enum TimeSeriesPointAnchoring  {
  BeginningAt,
  EndingAt
}

export const runningStatisticsSeriesDifference = (x: api.RunningStatistic, y: api.RunningStatistic): api.RunningStatistic => {
  return { timeSeries: x.timeSeries.map((pt, idx) => ({ valueDuringPeriod: pt.valueDuringPeriod - y.timeSeries[idx].valueDuringPeriod, totalAtEndOfPeriod: pt.totalAtEndOfPeriod - y.timeSeries[idx].totalAtEndOfPeriod, beginningAt: pt.beginningAt, endingAt: pt.endingAt })) };
}

export const runningStatisticsSeriesRatio = (x: api.RunningStatistic, y: api.RunningStatistic): api.RunningStatistic => {
  return { timeSeries: x.timeSeries.map((pt, idx) => ({
      valueDuringPeriod: isFinite(pt.valueDuringPeriod / y.timeSeries[idx].valueDuringPeriod) ? pt.valueDuringPeriod / y.timeSeries[idx].valueDuringPeriod: 1,
      totalAtEndOfPeriod: isFinite(pt.totalAtEndOfPeriod / y.timeSeries[idx].totalAtEndOfPeriod) ? pt.totalAtEndOfPeriod / y.timeSeries[idx].totalAtEndOfPeriod: 1,
      beginningAt: pt.beginningAt,
      endingAt: pt.endingAt })) };
}

export const runningStatisticToCumulativeXyData = (runningStatistic: api.RunningStatistic, anchoring: TimeSeriesPointAnchoring = TimeSeriesPointAnchoring.EndingAt): any[] => (runningStatistic?.timeSeries || []).map((pt) => ({
  x: DateTime.fromISO(anchoring === TimeSeriesPointAnchoring.BeginningAt ? pt.beginningAt : pt.endingAt)
    .toJSDate(),
  y: pt.totalAtEndOfPeriod,
}));

export const runningStatisticToInterPeriodGrowthXyData = (runningStatistic: api.RunningStatistic, anchoring: TimeSeriesPointAnchoring = TimeSeriesPointAnchoring.EndingAt): any[] => (runningStatistic?.timeSeries || []).map((pt) => ({
  x: DateTime.fromISO(anchoring === TimeSeriesPointAnchoring.BeginningAt ? pt.beginningAt : pt.endingAt)
    .toJSDate(),
  y: pt.valueDuringPeriod,
}));

export const runningStatisticToBurnup = (series: api.TimeSeriesPoint[], prevTotal: number): any[] => (series)
  // Don't include future datapoints in burnup, since it does not apply to projections
  .filter((pt) => DateTime.now() > DateTime.fromISO(pt.beginningAt))
  .map((pt) => ({
    x: useTimezoneSelection(DateTime.fromISO(pt.endingAt)).day,
    y: pt.totalAtEndOfPeriod - prevTotal,
    markedAsFriday: useTimezoneSelection(DateTime.fromISO(pt.endingAt)).weekday === 5
  }));

export const runningStatisticFromCalculationXyData = (statistics: readonly api.RunningStatistic[], calcFn: (pts: readonly api.TimeSeriesPoint[], i: number, statistics: readonly api.RunningStatistic[]) => any): any[] => (statistics[0]?.timeSeries || []).map((pt, i) => {
  const pts = statistics.map((s) => s.timeSeries[i]);
  return {
    x: DateTime.fromISO(pt.beginningAt)
      .toJSDate(),
    y: calcFn(pts, i, statistics),
  };
});

export const boxPlotOptions: ApexOptions = {
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '50%',
    },
    boxPlot: {
      colors: {
        upper: '#e9ecef',
        lower: '#f8f9fa',
      },
    },
  },
  yaxis: {
    labels: {
      minWidth: 200,
      maxWidth: 300,
      align: 'left',
      style: {
        fontSize: '16px',
      },
    },
  },
  stroke: {
    colors: ['#6c757d'],
  },
};

export const IdentityFormatter = (val: number) => val.toString();
export const CurrencyFormatter = (num: number) => formatCurrency(num, api.CurrencyCode.USD);
export const CurrencyFormatterFromString = (numStr: string) => CurrencyFormatter(parseFloat(numStr)).toString()
export const RoundingFormatter = (places = 2) => (val: number) => val.toFixed(places);

export const PercentageFormatter = (num: number): string => ((num || num === 0) ? Number(num).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) : '');
export const PercentageFormatterFromString = (num: string): string => ((num || num === '0') ? Number(num).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }) : '');

export const lineChartOptions = (formatter: (val: number, opts?: any) => string | string[] = IdentityFormatter, colors?: Array<any>, annotations: ApexAnnotations = { yaxis: [], xaxis: [] }): ApexOptions => ({
  annotations,
  colors,
  chart: {
    animations: { enabled: false },
    height: 350,
    width: '100%',
    type: 'line',
  },
  dataLabels: { enabled: false },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      style: {
        fontSize: '16px',
      },
      format: 'MMM dd yy',
      datetimeFormatter: {
        year: 'yyyy',
        month: 'yy\'MMM',
        day: 'MMM dd',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    labels: {
      formatter,
    },
  },
  grid: {
    borderColor: '#f1f1f1',
  },
});

export const categoryLineChartOptions = (formatter: (val: number, opts?: any) => string | string[] = IdentityFormatter, colors?: Array<any>, annotations: ApexAnnotations = { yaxis: [], xaxis: [] }): ApexOptions => ({
  annotations,
  colors,
  chart: {
    animations: { enabled: false },
    height: 350,
    width: '100%',
    type: 'line',
  },
  dataLabels: { enabled: false },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    type: 'category',
  },
  yaxis: {
    labels: {
      formatter,
    },
  },
  grid: {
    borderColor: '#f1f1f1',
  },
});

export const burnupLineChartOptions = (formatter: (val: number, opts?: any) => string | string[] = IdentityFormatter, discrete?: ApexDiscretePoint[], colors?: Array<any>, annotations: ApexAnnotations = { yaxis: [], xaxis: [] }): ApexOptions => ({
  annotations,
  colors,
  chart: {
    animations: { enabled: false },
    height: 350,
    width: '100%',
    type: 'line',
  },
  dataLabels: { enabled: false },
  markers: {
    size: 5,
    discrete,
  },
  xaxis: {
    type: 'numeric',
    labels: {
      formatter: ((value) => parseInt(value).toString()),
      style: {
        fontSize: '16px',
      }
    },
  },
  yaxis: {
    labels: {
      formatter,
    },
  },
  grid: {
    borderColor: '#f1f1f1',
  },
});

export const heatMapOptions = (formatter: (val: number, opts?: any) => string = CurrencyFormatter): ApexOptions => ({
  yaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },
    },
  },
  dataLabels: {
    style: {
      colors: ['#000000'],
    },
    distributed: false,
    formatter,
  },
  colors: ['#00FF00'],
  stroke: {
    colors: ['#000000'],
  },
  fill: {
    colors: ['#000000'],
  },
  plotOptions: {
    heatmap: {
      distributed: false,
      shadeIntensity: 0.5,
      radius: 0,
    },
  },
});

export const barChartOptions = (formatter: (val: number, opts?: any) => string | string[] = IdentityFormatter, colors?: Array<any>): ApexOptions => ({
  colors,
  chart: {
    animations: { enabled: false },
    width: '100%',
  },
  dataLabels: { enabled: false },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      style: {
        fontSize: '16px',
      },
      format: 'MMM dd yy',
      datetimeFormatter: {
        year: 'yyyy',
        month: 'yy\'MMM',
        day: 'MMM dd',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    labels: {
      formatter,
    },
  },
  grid: {
    borderColor: '#f1f1f1',
  },
});

export const stackedBarChartOptions = (dataLabelsFormatter: (val: string, opts?: any) => string = CurrencyFormatterFromString, yAxisFormatter: (val: number, opts?: any) => string = CurrencyFormatter,  colors?: Array<any>): ApexOptions => ({
  colors,
  chart: {
    type: 'bar',
    stacked: true,
    animations: { enabled: false },
    width: '100%',
  },
  dataLabels: { enabled: false },
  plotOptions: {
    bar: {
      dataLabels: {
        total: {
          formatter: dataLabelsFormatter,
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      style: {
        fontSize: '16px',
      },
      format: 'MMM dd yy',
      datetimeFormatter: {
        year: 'yyyy',
        month: 'yy\'MMM',
        day: 'MMM dd',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    labels: {
      formatter: yAxisFormatter,
    },
  },
  grid: {
    borderColor: '#f1f1f1',
  },
});

export const getVendorAccountVerificationStatus = (verificationStatus: api.VerificationStatus) => {
  switch (verificationStatus) {
    case 'pending': return { label: 'Pending', status: 'neutral' };
    case 'created': return { label: 'Requested', status: 'notification' };
    case 'completed': return { label: 'Requires Verification', status: 'action' };
    case 'attempting': return { label: 'Attempting', status: 'notification' };
    case 'succeeded': return { label: 'Succeeded', status: 'success' };
    case 'failed': return { label: 'Failed', status: 'error' };
    default:
      return { label: 'Missing', status: 'critical' };
  }
};

export const getVerificationDocumentStatus = (uploadedCount: number | undefined, verifiedCount: number | undefined) => {
  if (!uploadedCount) {
    return { label: 'Missing', status: 'error' };
  } if (!verifiedCount) {
    return { label: 'Requires Verification', status: 'action' };
  }

  return { label: 'Verified', status: 'success' };
};

export const getErrorMessage = (error?: any): string | undefined => error?.data?.message || error?.message;

export const isNetProgram = (programCode: api.ProgramCodes): boolean => {
  return programCode.toString().startsWith('NET');
}

export const parseMaybePercentage = (str: string): number => {
  const value = str.trim();
  if (value.endsWith('%')) {
    return new Decimal(value.substring(0, value.length - 1)).dividedBy(100).toNumber();
  } else {
    return new Decimal(value).toNumber();
  }
}

export const toPercentage = (value: number) => `${new Decimal(value).times(100).toString()}%`;

export const getTimeSeriesPeriodName = (period: DateTime, periodization: api.TimeSeriesPeriodization, isMultiYear: boolean): string => {
  switch (periodization) {
    case api.TimeSeriesPeriodization.DAY:
      return isMultiYear ? period.toFormat('dd/MM/yy') : period.toFormat('dd/MM');
    case api.TimeSeriesPeriodization.WEEK:
      return isMultiYear ? period.toFormat('WW yy') : period.toFormat('WW');
    case api.TimeSeriesPeriodization.MONTH:
      return isMultiYear ? period.toFormat('MMMM yy') : period.toFormat('MMMM');
    case api.TimeSeriesPeriodization.QUARTER:
      return isMultiYear ? period.toFormat('Qq yy') : period.toFormat('Qq')
    case api.TimeSeriesPeriodization.YEAR:
    case api.TimeSeriesPeriodization.CENTURY:
      return period.toFormat('yyyy');
  }
}

export const appendFilterParams = <T extends {}>(url: URL, filterParams: T | undefined) => {
  Object.entries(filterParams || {}).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      if (Array.isArray(v)) {
        v.forEach((arrayVal) => {
          if (arrayVal !== null && arrayVal !== undefined) {
            url.searchParams.append(`${k}[]`, arrayVal.toString());
          }
        });
      } else {
        url.searchParams.append(k, v.toString());
      }
    }
  });
}
