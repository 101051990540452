import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type CollectionsState = {
  searchTerm?: string;
  searchNotReconciledOnly: boolean;
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
  currentCollectionId?: api.UUID;
  invoicePage?: number;
  invoiceTotalPages?: number;
  invoiceLimit?: number;
  invoiceSortField?: string;
  invoiceSortDirection?: api.SortDirection;
};

export const initialState: CollectionsState = {
  searchTerm: '',
  searchNotReconciledOnly: false,
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.DESCENDING,
  currentCollectionId: undefined,
  invoicePage: 1,
  invoiceTotalPages: undefined,
  invoiceLimit: 10,
  invoiceSortField: undefined,
  invoiceSortDirection: api.SortDirection.ASCENDING,
};

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    updateSearchTerm(state: CollectionsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updateSearchNotReconciledOnly(state: CollectionsState, action: PayloadAction<boolean>) {
      state.searchNotReconciledOnly = action.payload;
      state.page = 1;
    },
    updatePage(state: CollectionsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: CollectionsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: CollectionsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: CollectionsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: CollectionsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateCurrentCollectionId(state: CollectionsState, action: PayloadAction<api.UUID>) {
      if (state.currentCollectionId !== action.payload) {
        state.invoicePage = 1;
        state.invoiceTotalPages = undefined;
        state.currentCollectionId = action.payload;
      }
    },
    updateInvoicePage(state: CollectionsState, action: PayloadAction<number>) {
      state.invoicePage = action.payload;
    },
    updateInvoiceTotalPages(state: CollectionsState, action: PayloadAction<number>) {
      state.invoiceTotalPages = action.payload;
    },
    updateInvoiceLimit(state: CollectionsState, action: PayloadAction<number>) {
      state.invoiceLimit = action.payload;
    },
    updateInvoiceSortField(state: CollectionsState, action: PayloadAction<string>) {
      state.invoiceSortField = action.payload;
    },
    updateInvoiceSortDirection(state: CollectionsState, action: PayloadAction<api.SortDirection>) {
      state.invoiceSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updateSearchNotReconciledOnly,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateCurrentCollectionId,
  updateInvoicePage,
  updateInvoiceTotalPages,
  updateInvoiceLimit,
  updateInvoiceSortField,
  updateInvoiceSortDirection,
} = collectionsSlice.actions;
export default collectionsSlice.reducer;
