import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type ClientsState = {
  searchTerm?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  externalClientMappingsSearchTerm?: string;
  externalClientMappingsPage?: number;
  externalClientMappingsTotalPages?: number;
  externalClientMappingsLimit?: PaginationDropdownLimit;
  externalClientMappingsSortField?: string;
  externalClientMappingsSortDirection?: api.SortDirection;
};

export const initialState: ClientsState = {
  searchTerm: undefined,
  page: 1,
  totalPages: undefined,
  limit: DefaultPaginationDropdownLimits[0],
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  externalClientMappingsSearchTerm: undefined,
  externalClientMappingsPage: 1,
  externalClientMappingsTotalPages: undefined,
  externalClientMappingsLimit: DefaultPaginationDropdownLimits[0],
  externalClientMappingsSortField: undefined,
  externalClientMappingsSortDirection: api.SortDirection.ASCENDING,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateSearchTerm(state: ClientsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updatePage(state: ClientsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: ClientsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateExternalClientMappingsSearchTerm(state: ClientsState, action: PayloadAction<string>) {
      state.externalClientMappingsSearchTerm = action.payload;
      state.externalClientMappingsPage = 1;
    },
    updateExternalClientMappingsPage(state: ClientsState, action: PayloadAction<number>) {
      state.externalClientMappingsPage = action.payload;
    },
    updateExternalClientMappingsTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.externalClientMappingsTotalPages = action.payload;
    },
    updateExternalClientMappingsLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.externalClientMappingsLimit = action.payload;
    },
    updateExternalClientMappingsSortField(state: ClientsState, action: PayloadAction<string>) {
      state.externalClientMappingsSortField = action.payload;
    },
    updateExternalClientMappingsSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.externalClientMappingsSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateExternalClientMappingsSearchTerm,
  updateExternalClientMappingsPage,
  updateExternalClientMappingsTotalPages,
  updateExternalClientMappingsLimit,
  updateExternalClientMappingsSortField,
  updateExternalClientMappingsSortDirection,
} = clientsSlice.actions;
export default clientsSlice.reducer;
