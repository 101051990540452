import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateTime, DateTimeUnit } from 'luxon';
import { DatePicker } from '@mesa-labs/mesa-ui';

import { FilterCell, FilterTitle } from './Filters';

const DateRangeFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

type DateRangeFilterProps = {
  beginningAt?: DateTime;
  endingAt?: DateTime;
  onBeginningAtChange: (beginningAt: DateTime) => void;
  onEndingAtChange: (endingAt: DateTime) => void;
  periodization?: DateTimeUnit;
};

function DateRangeFilter({
  beginningAt: beginningAtInitialValue,
  endingAt: endingAtInitialValue,
  onBeginningAtChange,
  onEndingAtChange,
  periodization: periodizationInitialValue,
}: DateRangeFilterProps): React.ReactElement {
  const [beginningAt, setBeginningAt] = useState(DateRangeFilter.defaultProps.beginningAt);
  const [endingAt, setEndingAt] = useState(DateRangeFilter.defaultProps.endingAt);
  const [periodization, setPeriodization] = useState(DateRangeFilter.defaultProps.periodization);

  useEffect(() => {
    if (beginningAtInitialValue) {
      setBeginningAt(beginningAtInitialValue);
    }
  }, [beginningAtInitialValue]);

  useEffect(() => {
    if (endingAtInitialValue) {
      setEndingAt(endingAtInitialValue);
    }
  }, [endingAtInitialValue]);

  useEffect(() => {
    if (periodizationInitialValue) {
      setPeriodization(periodizationInitialValue);
      setBeginningAt((beginningAt) => {
        handleBeginningAtChanged(beginningAt, periodizationInitialValue);
        return beginningAt;
      });
      setEndingAt((endingAt) => {
        handleEndingAtChanged(endingAt, periodizationInitialValue);
        return endingAt;
      });
    }
  }, [periodizationInitialValue]);

  const handleBeginningAtChanged = (value: DateTime, periodization: DateTimeUnit) => {
    const d = value.startOf(periodization);
    setBeginningAt(d);
    onBeginningAtChange(d);
  }

  const handleEndingAtChanged = (value: DateTime, periodization: DateTimeUnit) => {
    const d = value.endOf(periodization);
    setEndingAt(d);
    onEndingAtChange(d);
  }

  return (
    <DateRangeFilterContainer>
      <FilterCell>
        <FilterTitle>Beginning At</FilterTitle>
        <DatePicker
          value={beginningAt}
          onChange={(value) => handleBeginningAtChanged(value, periodization)}
          useInputButton
        />
      </FilterCell>
      <FilterCell>
        <FilterTitle>Ending At</FilterTitle>
        <DatePicker
          value={endingAt}
          onChange={(value) => handleEndingAtChanged(value, periodization)}
          useInputButton
        />
      </FilterCell>
    </DateRangeFilterContainer>
  )
}

DateRangeFilter.defaultProps = {
  beginningAt: DateTime.now().startOf('day'),
  endingAt: DateTime.now().endOf('day'),
  periodization: 'day' as DateTimeUnit,
};

export default DateRangeFilter;