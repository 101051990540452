export type UUID = string;

export function notNullish<T>(item?: T | null): item is T {
  return item !== undefined && item !== null;
}

export enum TaskService {
  InvoiceService = 'Invoice Service',
  PaymentService = 'Payment Service',
  VendorService = 'Vendor Service',
}

export enum SummaryTimeSeriesDisplay {
  Actual = 'actual',
  Cumulative = 'cumulative',
  Change = 'change',
  PercentChange = 'percent-change'
}