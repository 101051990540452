import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type VendorsState = {
  searchTerm?: string;
  instantPayoutAlwaysOn?: boolean;
  programCode?: api.ProgramCodes;
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
  currentVendorId?: api.UUID;
  invoicePage: number;
  invoiceTotalPages?: number;
  invoiceLimit: number;
  invoiceSortField?: string;
  invoiceSortDirection: api.SortDirection;
  auditEntityTable?: api.VendorAuditEntityTable;
  auditPage?: number;
  auditTotalPages?: number;
  auditLimit?: number;
};

export const initialState: VendorsState = {
  searchTerm: '',
  instantPayoutAlwaysOn: undefined,
  programCode: undefined,
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  currentVendorId: undefined,
  invoicePage: 1,
  invoiceTotalPages: undefined,
  invoiceLimit: 10,
  invoiceSortField: undefined,
  invoiceSortDirection: api.SortDirection.ASCENDING,
  auditEntityTable: undefined,
  auditPage: 1,
  auditTotalPages: undefined,
  auditLimit: 10
};

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    updateSearchTerm(state: VendorsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updateInstantPayoutAlwaysOn(state: VendorsState, action: PayloadAction<boolean | undefined>) {
      state.instantPayoutAlwaysOn = action.payload;
      state.page = 1;
    },
    updateProgramCode(state: VendorsState, action: PayloadAction<api.ProgramCodes | undefined>) {
      state.programCode = action.payload;
      state.page = 1;
    },
    updatePage(state: VendorsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: VendorsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: VendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: VendorsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: VendorsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateCurrentVendorId(state: VendorsState, action: PayloadAction<api.UUID>) {
      if (state.currentVendorId !== action.payload) {
        state.invoicePage = 1;
        state.invoiceTotalPages = undefined;
        state.currentVendorId = action.payload;
      }
    },
    updateInvoicePage(state: VendorsState, action: PayloadAction<number>) {
      state.invoicePage = action.payload;
    },
    updateInvoiceTotalPages(state: VendorsState, action: PayloadAction<number>) {
      state.invoiceTotalPages = action.payload;
    },
    updateInvoiceLimit(state: VendorsState, action: PayloadAction<number>) {
      state.invoiceLimit = action.payload;
    },
    updateInvoiceSortField(state: VendorsState, action: PayloadAction<string>) {
      state.invoiceSortField = action.payload;
    },
    updateInvoiceSortDirection(state: VendorsState, action: PayloadAction<api.SortDirection>) {
      state.invoiceSortDirection = action.payload;
    },
    updateAuditEntityTable(state: VendorsState, action: PayloadAction<api.VendorAuditEntityTable | undefined>) {
      state.auditEntityTable = action.payload;
    },
    updateAuditPage(state: VendorsState, action: PayloadAction<number>) {
      state.auditPage = action.payload;
    },
    updateAuditTotalPages(state: VendorsState, action: PayloadAction<number>) {
      state.auditTotalPages = action.payload;
    },
    updateAuditLimit(state: VendorsState, action: PayloadAction<number>) {
      state.auditLimit = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updateInstantPayoutAlwaysOn,
  updateProgramCode,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateCurrentVendorId,
  updateInvoicePage,
  updateInvoiceTotalPages,
  updateInvoiceLimit,
  updateInvoiceSortField,
  updateInvoiceSortDirection,
  updateAuditEntityTable,
  updateAuditPage,
  updateAuditTotalPages,
  updateAuditLimit,
} = vendorsSlice.actions;
export default vendorsSlice.reducer;
