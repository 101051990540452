import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  PaginationFooter, Table,
} from '@mesa-labs/mesa-ui';
import { useNavigate } from 'react-router-dom';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';
import { DateTime, Interval } from 'luxon';

import { EligibleInvoiceType, ProgramCodes, SortDirection } from '@mesa-labs/mesa-api/dist/types';
import { useGetEligibleInvoiceTotalsByVendorQuery } from '../../redux/api/invoices';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateEligibleInvoiceType, updateLimit, updatePage, updateTotalPages, initialState as EligibleInvoicesInitialState, updateSortField, updateSortDirection
} from '../../redux/slices/eligibleInvoices';
import { DefaultPaginationLimits } from '../common/PaginationLimits';
import { FilterCell, FilterRow, FilterSection, FilterTitle } from '../common/Filters';
import Select from '../common/Select';
import useSyncQueryStringParamsToRedux from '../../hooks/useSyncQueryStringParamsToRedux';

const EligibleInvoiceTypeOptions = [
  {
    label: '(No Selection)',
    value: undefined
  },
  {
    label: 'In Flights',
    value: EligibleInvoiceType.IN_FLIGHTS
  },
  {
    label: 'On-Demand',
    value: EligibleInvoiceType.ON_DEMAND
  }
];

const EligibleInvoicesColumns: ColumnProp[] = [
  {
    key: 'vendorName',
    label: 'Vendor Name',
    sortField: 'vendorName',
    width: '20%',
  },
  {
    key: 'eligibleInvoiceType',
    label: 'Type',
    width: '10%',
  },
  {
    key: 'eligibleRevenueTotal',
    label: 'Total Amount',
    sortField: 'eligibleRevenueTotal',
    width: '20%',
    type: 'currency'
  },
  {
    key: 'eligibleInvoiceCount',
    label: 'Total Invoices',
    sortField: 'eligibleInvoiceCount',
    width: '10%',
  },
  {
    key: 'eligibleAverageDaysOutstanding',
    label: 'Avg Days Outstanding',
    sortField: 'eligibleAverageDaysOutstanding',
    width: '10%',
  },
  {
    key: 'daysSinceActivation',
    label: 'Days Since Activation',
    sortField: 'activatedAt',
    width: '10%',
  },
  {
    key: 'latestRedirectRequestedAt',
    label: 'Latest Redirect Requested At',
    sortField: 'latestRedirectRequestedAt',
    width: '20%',
    type: 'datetime'
  },
];

const EligibleInvoicesPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function EligibleInvoicesPage(): React.ReactElement | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eligibleInvoiceType = useSelector((state) => state.eligibleInvoices.eligibleInvoiceType);
  const page = useSelector((state) => state.eligibleInvoices.page) || EligibleInvoicesInitialState.page!;
  const totalPages = useSelector((state) => state.eligibleInvoices.totalPages);
  const limit = useSelector((state) => state.eligibleInvoices.limit) || EligibleInvoicesInitialState.limit!;
  const sortField = useSelector((state) => state.eligibleInvoices.sortField);
  const sortDirection = useSelector((state) => state.eligibleInvoices.sortDirection);

  useSyncQueryStringParamsToRedux({ sliceName: 'eligibleInvoices' });

  const appliedFilters = useMemo(() => [eligibleInvoiceType].reduce((acc: number, curr) => acc + (curr !== undefined ? 1 : 0), 0), [eligibleInvoiceType]);

  const {
    data: {
      data: eligibleInvoiceTotals = [],
      total: totalEligibleInvoiceTotals,
    } = {},
  } = useGetEligibleInvoiceTotalsByVendorQuery({
    eligibleInvoiceType,
    sortField,
    sortDirection,
    page,
    limit: limit.value,
  });

  useEffect(() => {
    if (totalEligibleInvoiceTotals !== undefined) {
      dispatch(updateTotalPages(Math.ceil(totalEligibleInvoiceTotals / limit.value)));
    }
  }, [limit, totalEligibleInvoiceTotals]);

  useEffect(() => {
    if (totalPages === undefined && page !== 1) {
      dispatch(updatePage(1));
    }
  }, []);

  const rows = eligibleInvoiceTotals.map((eit) => ({
    id: eit.vendorId,
    ...eit,
    eligibleInvoiceType: eit.effectiveProgramCode ? eit.effectiveProgramCode === ProgramCodes.OD ? 'On-Demand' : 'In Flights' : undefined,
    daysSinceActivation: eit.activatedAt ? Math.round(Interval.fromDateTimes(DateTime.fromISO(eit.activatedAt), DateTime.now()).length('days')) : undefined,
    currency: 'USD'
  }));

  return (
    <EligibleInvoicesPageContainer>
      <FilterSection
        filterHeader="&nbsp;"
        appliedFilters={appliedFilters}
      >
        <FilterRow columns={4}>
          <FilterCell>
            <FilterTitle>Type</FilterTitle>
            <Select
              options={EligibleInvoiceTypeOptions}
              value={EligibleInvoiceTypeOptions.find(o => o.value === eligibleInvoiceType) || EligibleInvoiceTypeOptions[0]}
              onChange={(o) => dispatch(updateEligibleInvoiceType(o.value))}
            />
          </FilterCell>
        </FilterRow>
      </FilterSection>

      <Table
        columns={EligibleInvoicesColumns}
        rows={rows}
        currentSortColumn={sortField}
        currentSortDirection={sortDirection}
        setSortField={(field: string) => dispatch(updateSortField(field))}
        setSortDirection={(direction: SortDirection) => dispatch(updateSortDirection(direction))}
        onRowClick={(row: Record<string, unknown>) => row.effectiveProgramCode === ProgramCodes.OD ? navigate(`/vendors/${row.vendorId}/invoices`) : navigate(`/vendors/${row.vendorId}/in-flights`)}
        paginationComponent={(
          <PaginationFooter
            limits={DefaultPaginationLimits}
            selectedLimit={limit}
            onChange={(item) => {
              dispatch(updateLimit(item));
              dispatch(updatePage(1));
            }}
            currentPage={page}
            totalPages={totalPages || 1}
            onPrev={() => dispatch(updatePage(page - 1))}
            onNext={() => dispatch(updatePage(page + 1))}
          />
        )}
      />
    </EligibleInvoicesPageContainer>
  );
}

export default EligibleInvoicesPage;
