import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './components/App';
import { store } from './redux/store';

const container = document.querySelector('#root');
const root = createRoot(container!);

// force reload the page if vite encounters an error dynamically loading a
// module.
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
