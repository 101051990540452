import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

import * as types from '../../types';

export type CohortsState = {
  grouping: api.InvoiceCohortGrouping;
  beginningAt: api.ISODateString;
  endingAt: api.ISODateString;
  periodization: api.TimeSeriesPeriodization;
  enforcePeriodization: boolean;
  metric: api.InvoiceCohortMetric;
  partnerId?: api.Partners;
  vendorLocale?: api.VendorLocale;
  programCode?: api.ProgramCodes;
  selectedCohort?: api.ISODateString;
  vendorSummaryTimeSeriesDisplay: types.SummaryTimeSeriesDisplay;
  vendorPage: number;
  vendorTotalPages?: number;
  vendorLimit: PaginationDropdownLimit;
  vendorSortField: string;
  vendorSortDirection: api.SortDirection;
};

export const initialState: CohortsState = {
  grouping: api.InvoiceCohortGrouping.Month,
  beginningAt: DateTime.now().startOf('year').toISO(),
  endingAt: DateTime.now().endOf('year').toISO(),
  periodization: api.TimeSeriesPeriodization.MONTH,
  enforcePeriodization: false,
  metric: api.InvoiceCohortMetric.FundedAmount,
  partnerId: undefined,
  vendorLocale: undefined,
  programCode: undefined,
  selectedCohort: undefined,
  vendorSummaryTimeSeriesDisplay: types.SummaryTimeSeriesDisplay.Actual,
  vendorPage: 1,
  vendorTotalPages: undefined,
  vendorLimit: DefaultPaginationDropdownLimits[0],
  vendorSortField: 'totalRevenue',
  vendorSortDirection: api.SortDirection.DESCENDING,
};

const cohortsSlice = createSlice({
  name: 'cohorts',
  initialState,
  reducers: {
    updateGrouping(state: CohortsState, action: PayloadAction<api.InvoiceCohortGrouping>) {
      state.grouping = action.payload;
    },
    updateBeginningAt(state: CohortsState, action: PayloadAction<api.ISODateString>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: CohortsState, action: PayloadAction<api.ISODateString>) {
      state.endingAt = action.payload;
    },
    updatePeriodization(state: CohortsState, action: PayloadAction<api.TimeSeriesPeriodization>) {
      state.periodization = action.payload;
    },
    updateEnforcePeriodization(state: CohortsState, action: PayloadAction<boolean>) {
      state.enforcePeriodization = action.payload;
    },
    updateMetric(state: CohortsState, action: PayloadAction<api.InvoiceCohortMetric>) {
      state.metric = action.payload;
    },
    updatePartnerId(state: CohortsState, action: PayloadAction<api.Partners | undefined>) {
      state.partnerId = action.payload;
    },
    updateVendorLocale(state: CohortsState, action: PayloadAction<api.VendorLocale | undefined>) {
      state.vendorLocale = action.payload;
    },
    updateProgramCode(state: CohortsState, action: PayloadAction<api.ProgramCodes | undefined>) {
      state.programCode = action.payload;
    },
    updateSelectedCohort(state: CohortsState, action: PayloadAction<api.ISODateString | undefined>) {
      state.selectedCohort = action.payload;
    },
    updateVendorSummaryTimeSeriesDisplay(state: CohortsState, action: PayloadAction<types.SummaryTimeSeriesDisplay>) {
      state.vendorSummaryTimeSeriesDisplay = action.payload;
    },
    updateVendorPage(state: CohortsState, action: PayloadAction<number>) {
      state.vendorPage = action.payload;
    },
    updateVendorTotalPages(state: CohortsState, action: PayloadAction<number>) {
      state.vendorTotalPages = action.payload;
    },
    updateVendorLimit(state: CohortsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.vendorLimit = action.payload;
    },
    updateVendorSortField(state: CohortsState, action: PayloadAction<string>) {
      state.vendorSortField = action.payload;
    },
    updateVendorSortDirection(state: CohortsState, action: PayloadAction<api.SortDirection>) {
      state.vendorSortDirection = action.payload;
    },
  },
});

export const {
  updateGrouping,
  updateBeginningAt,
  updateEndingAt,
  updatePeriodization,
  updateEnforcePeriodization,
  updateMetric,
  updatePartnerId,
  updateVendorLocale,
  updateProgramCode,
  updateSelectedCohort,
  updateVendorSummaryTimeSeriesDisplay,
  updateVendorPage,
  updateVendorTotalPages,
  updateVendorLimit,
  updateVendorSortField,
  updateVendorSortDirection,
} = cohortsSlice.actions;
export default cohortsSlice.reducer;
