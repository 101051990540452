import { combineReducers } from 'redux';
import acceleratedPayout from './acceleratedPayout';
import abandonedUsers from './abandonedUsers';
import adjustments from './adjustments';
import anomalies from './anomalies';
import auth from './auth';
import cohorts from './cohorts';
import collections from './collections';
import customerBatchTransfers from './customerBatchTransfers';
import eligibleInvoices from './eligibleInvoices';
import enablement from './enablement';
import invoices from './invoices';
import invoiceLineItems from './invoiceLineItems';
import onboardings from './onboardings';
import reconciliation from './reconciliation';
import statistics from './statistics';
import programs from './programs';
import vendors from './vendors';
import clients from './clients';
import facilitators from './facilitators';
import externalClients from './externalClients';
import externalClientVendors from './externalClientVendors';
import externalVendors from './externalVendors';
import kpis from './kpis';
import refunds from './refunds';
import revenue from './revenue';
import batchSweeps from './batchSweeps';
import repaymentItems from './repaymentItems';
import tasks from './tasks';
import { paymentsApi } from '../api/payments';
import { invoicesApi } from '../api/invoices';
import { vendorsApi } from '../api/vendors';
import { serviceAgreementsApi } from '../api/serviceAgreements';
import { partnerServiceAgreementsApi } from '../api/partnerServiceAgreements';
import { externalVendorsApi } from '../api/externalVendors';

const reducer = combineReducers({
  acceleratedPayout,
  abandonedUsers,
  adjustments,
  anomalies,
  auth,
  cohorts,
  collections,
  eligibleInvoices,
  enablement,
  programs,
  invoices,
  invoiceLineItems,
  customerBatchTransfers,
  onboardings,
  reconciliation,
  statistics,
  vendors,
  clients,
  facilitators,
  externalClients,
  externalVendors,
  externalClientVendors,
  kpis,
  refunds,
  revenue,
  tasks,
  batchSweeps,
  repaymentItems,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [vendorsApi.reducerPath]: vendorsApi.reducer,
  [externalVendorsApi.reducerPath]: externalVendorsApi.reducer,
  [serviceAgreementsApi.reducerPath]: serviceAgreementsApi.reducer,
  [partnerServiceAgreementsApi.reducerPath]: partnerServiceAgreementsApi.reducer,
});

export default reducer;
