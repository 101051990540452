import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type InvoicesState = {
  searchTerm?: string;
  page: number;
  totalPages?: number;
  limit: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
  partner?: api.Partners;
  includeState?: api.InvoiceState;
  beginningAt?: api.ISODateString;
  endingAt?: api.ISODateString;
  createdBeginningAt?: api.ISODateString;
  createdEndingAt?: api.ISODateString;
  eligibleBeginningAt?: api.ISODateString;
  eligibleEndingAt?: api.ISODateString;
};

const initialState: InvoicesState = {
  searchTerm: '',
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  partner: undefined,
  includeState: undefined,
  beginningAt: undefined,
  endingAt: undefined,
  createdBeginningAt: undefined,
  createdEndingAt: undefined,
  eligibleBeginningAt: undefined,
  eligibleEndingAt: undefined,
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    updateSearchTerm(state: InvoicesState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updatePage(state: InvoicesState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: InvoicesState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: InvoicesState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: InvoicesState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: InvoicesState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updatePartner(state: InvoicesState, action: PayloadAction<api.Partners | undefined>) {
      state.partner = action.payload;
    },
    updateIncludeState(state: InvoicesState, action: PayloadAction<api.InvoiceState | undefined>) {
      state.includeState = action.payload;
    },
    updateBeginningAt(state: InvoicesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.beginningAt = action.payload;
    },
    updateEndingAt(state: InvoicesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.endingAt = action.payload;
    },
    updateCreatedBeginningAt(state: InvoicesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.createdBeginningAt = action.payload;
    },
    updateCreatedEndingAt(state: InvoicesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.createdEndingAt = action.payload;
    },
    updateEligibleBeginningAt(state: InvoicesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.eligibleBeginningAt = action.payload;
    },
    updateEligibleEndingAt(state: InvoicesState, action: PayloadAction<api.ISODateString | undefined>) {
      state.eligibleEndingAt = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updatePartner,
  updateIncludeState,
  updateBeginningAt,
  updateEndingAt,
  updateCreatedBeginningAt,
  updateCreatedEndingAt,
  updateEligibleBeginningAt,
  updateEligibleEndingAt,
} = invoicesSlice.actions;
export default invoicesSlice.reducer;
