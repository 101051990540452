import { useLocation } from 'react-router-dom';
import { useDispatch } from '../redux/hooks';
import { useEffect } from 'react';

function toUpperCamelCase(str: string) {
  if (!str) {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
}

type useSyncQueryStringParamsToReduxProps = {
  sliceName: string;
};

function useSyncQueryStringParamsToRedux({ 
  sliceName, 
}: useSyncQueryStringParamsToReduxProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);

  // run only on page mount
  useEffect(() => {
    try {
      (async () => {
        const reduxSlice = await import(`../redux/slices/${sliceName}.ts`);

        params.forEach((value, key) => {
          const updateFn = reduxSlice[`update${toUpperCamelCase(key)}`];

          if (value === null || value === undefined || typeof updateFn !== 'function') {
            return;
          }

          dispatch(updateFn(value));
        });
      })();
    } catch (err) {
      console.error(`There was an error during useSyncQueryStringParamsToRedux: ${(err as Error).toString()}`);
    }
  }, []);
}

export default useSyncQueryStringParamsToRedux;
