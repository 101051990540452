import { PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { types as api } from '@mesa-labs/mesa-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RefundsState = {
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection: api.SortDirection;
};

export const initialState: RefundsState = {
  page: 1,
  totalPages: undefined,
  limit: { key: '10', value: 10 },
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
};

const refundsSlice = createSlice({
  name: 'refunds',
  initialState,
  reducers: {
    updatePage(state: RefundsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: RefundsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: RefundsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: RefundsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: RefundsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
  },
});

export const {
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
} = refundsSlice.actions;
export default refundsSlice.reducer;
