import React from 'react';
import ReactSelect from 'react-select';

import { Selection } from '../../utils';
import styled from 'styled-components';

const SelectContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  ${(props) => props.width ? `width: ${props.width};` : ''} 

  > div { width: 100% }
`;

type SelectProps<T extends string | number> = {
  value: Selection<T | undefined>;
  onChange: (s: Selection<T | undefined>) => void;
  options: readonly Selection<T | undefined>[];
  width?: string;
};

function Select<T extends string | number>({
  value,
  onChange,
  options,
  width,
}: SelectProps<T>): React.ReactElement {
  return (
    <SelectContainer width={width}>
      <ReactSelect
        value={value}
        onChange={(v) => onChange(v!)}
        isClearable={false}
        options={options}
      />
    </SelectContainer>
  );
}

Select.defaultProps = {
  width: undefined
};

export default Select;
